.educational-program__main-block.techbes
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
.educational-program__main-block.techbes
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__text,
.educational-program__main-block.techbes
  .individual-achivments__text-block:nth-child(3) {
  max-width: 190px;
  width: 100%;
}