.educational-program.teacher.ies .teacher__info-block-frame-large .wrap img:first-child {
    margin-left: -28px;
    margin-top: -37px;
    border-radius: 20px 0 0 0 ;
  }
  
  
  .educational-program.teacher.ies .teacher__info-block-frame-large .wrap img:last-child {
    margin-right: -28px;
    margin-top: -5px;
    right: 0;
    border-radius: 0 0 20px 0;
  }
  
  .educational-program.teacher.ies .teacher_exams {
    background-image: url(../../../../assets/images/New/IESTeacher/bg_image.svg);
    background-position: 67px 48px;
  }