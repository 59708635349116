@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.olimpiad-first-steps {
  padding-top: 96px;
}

.olimpiad-first-steps,
.olimpiad-first-steps__img-block-text,
.olimpiad-first-steps__main-block-column {
  display: flex;
  flex-direction: column;
}

.olimpiad-first-steps__main-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.olimpiad-first-steps__main-block
  .olimpiad-first-steps__main-block-column:nth-child(1) {
  max-width: 700px;
  width: 100%;
}

.olimpiad-first-steps__main-block {
  margin-bottom: 40px;
}

.olimpiad-first-steps__img-block-text {
  position: absolute;
  margin-top: 135px;
  padding: 0 0 0 64px;
  margin-bottom: 80px;
}

.olimpiad-first-steps__img-block {
  border-radius: 30px;
  height: 480px;
  background: url(../../../assets/images/Olimpiads/firstSteps.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 80px;
}

.olimpiad-first-steps__block-teg {
  background: #1282e9;
  border-radius: 30px;
  padding: 7px 14px;

  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.02em;
  color: #fcfcfc;

  display: block;
  margin-bottom: 24px;
  width: 72px;
  height: 24px;
}

.olimpiad-first-steps__block-title {
  font-family: "Gotham ProMedium";
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  letter-spacing: 0.02em;
  color: #ffffff;

  display: block;
  padding-bottom: 32px;
}

.olimpiad-first-steps__block-text {
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  max-width: 723px;
  width: 100%;
}

.olimpiad-first-steps__main-block
  .olimpiad-first-steps__main-block-column:last-child {
  justify-content: space-between;
}

.olimpiad-first-steps__main-block-img {
  display: flex;
  justify-content: end;
}

.olimpiad-first-steps__main-block
  .olimpiad-first-steps__main-block-column:last-child
  .olimpiad-first-steps__main-block-img
  img {
  height: 200px;
  width: 200px;
}

.olimpiad-first-steps__main-block-column-title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;

  display: block;
  padding-bottom: 32px;
}

.olimpiad-first-steps__main-block-column-title.one {
  display: block;
  padding-bottom: 24px;
}

.olimpiad-first-steps__main-block-column-text {
  display: block;
  padding-bottom: 32px;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.olimpiad-first-steps__main-block-column
  .question-content__item-link-block.one
  .question-content__link-button:nth-child(3) {
  width: 321px;
}

.olimpiad-first-steps
  .olimpiad-first-steps__main-block-column:nth-child(2)
  .question-content__item-link-block.one {
  max-width: 336px;
  width: 100%;
}

.olimpiad-first-steps__main-block-column
  .question-content__item-link-block.one {
  margin-bottom: 20px;
}

@media (max-width: 1095px) {
  .olimpiad-first-steps__main-block {
    flex-direction: column;
  }
  .olimpiad-first-steps__main-block
    .olimpiad-first-steps__main-block-column:nth-child(1) {
    max-width: 100%;
  }
  .olimpiad-first-steps
    .olimpiad-first-steps__main-block-column:nth-child(2)
    .question-content__item-link-block.one,
  .olimpiad-first-steps
    .olimpiad-first-steps__main-block-column:nth-child(2)
    .question-content__item-link-block {
    max-width: 100%;
    flex-wrap: wrap;
    display: flex;
  }
  .olimpiad-first-steps__main-block-img {
    display: none;
  }
}

@media (max-width: 1013px) {
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block.one
    .question-content__link-button:nth-child(1) {
    height: 32px;
    width: 147px;
  }
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block
    .question-content__link-button:nth-child(1) {
    height: 32px;
    width: 291px;
  }
}

@media (max-width: 1005px) {
  .olimpiad-first-steps {
    padding-top: 117px;
  }
}

@media (max-width: 880px) {
  .olimpiad-first-steps__img-block-text {
    margin-top: 10%;
    max-width: 500px;
  }
}

@media (max-width: 744px) {
  .olimpiad-first-steps__img-block-text {
    margin-top: 15%;
  }
  .olimpiad-first-steps__img-block {
    margin-bottom: 40px;
  }
}

@media (max-width: 570px) {
  .olimpiad-first-steps__img-block-text {
    padding: 0 0 0 24px;
    margin: 10% 0 0 0;
    max-width: 400px;
  }
  .olimpiad-first-steps__img-block {
    height: 400px;
  }
  .olimpiad-first-steps__block-title {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (max-width: 430px) {
  .olimpiad-first-steps__img-block {
    display: none;
  }
}

@media (max-width: 394px) {
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block.one,
  .olimpiad-first-steps__main-block-column .question-content__item-link-block {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block.one
    .question-content__link-button:nth-child(1),
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block.one
    .question-content__link-button:nth-child(3),
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block
    .question-content__link-button:nth-child(1),
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block
    .question-content__link-button {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 350px) {
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block.one
    .question-content__link-button:nth-child(3){
    height: 52px;
    text-align: center;
  }
}

@media (max-width: 320px) {
  .olimpiad-first-steps__main-block-column
    .question-content__item-link-block
    .question-content__link-button:nth-child(1){
    height: 52px;
    text-align: center;
  }
}
