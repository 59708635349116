@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.paid-basis .question-content {
  margin-bottom: 55px;
}

.paid-basis .question-content {
  max-width: 920px;
  width: 100%;
}

.paid-basis .payment-methods__subtitle.end {
  max-width: 687px;
  width: 100%;
}

.paid-basis .payment-methods__subtitle.link {
  font-family: "Gotham ProMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1282e9;

  text-decoration: none;
}

.paid-basis .payment-methods__subtitle.end .payment-methods__subtitle.link {
  margin-bottom: 0;
  padding-left: 5px;
  display: contents;
}
