.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  top: 0;
}

.modal-wrapper--hide {
  animation: 0.3s ease-in-out hideModal forwards;
}

.modal .top-bar__modal-navbar li a {
  text-decoration: none;
}


.top-bar__modal-topmenu .lk {
  border: 1px solid #1282e9;
  border-radius: 10px;
  padding: 5px 10px;
  color: #1282e9;
}

@keyframes hideModal {
  from {
    opacity: 1;
    visibility: show;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.modal-wrapper--show {
  animation: 0.5s 0.1s ease-in-out showModal forwards;
}

@keyframes showModal {
  from {
    opacity: 0;
    visibility: show;
  }
  to {
    opacity: 1;
  }
}

.modal-wrapper .modal {
  padding: 110px 15px 15px;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.modal-wrapper .burger {
  position: absolute;
  top: 50px;
  right: 50px;
}

/* ---------------------------------------------------------- */

.top-bar__modal-menu--has-children {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.top-bar__modal-submenu {
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.top-bar__modal-menu--has-children--active .top-bar__modal-submenu {
  visibility: visible;
  height: fit-content;
  animation: 0.3s linear sub-menu forwards;
}

.top-bar__modal-menu--has-children.top-bar__modal-menu--has-children--active.one {
  margin-bottom: 140px;
}

.top-bar__modal-menu--has-children.top-bar__modal-menu--has-children--active.two,
.top-bar__modal-menu--has-children.top-bar__modal-menu--has-children--active.three {
  margin-bottom: 115px;
}

@keyframes sub-menu {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ---------------------------------------------------------- */

.top-bar__modal-navbar .top-bar__modal-topmenu {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  justify-content: center;
}

.top-bar__modal-submenu {
  display: flex;
  flex-direction: column;
}

.top-bar__modal-topmenu > .top-bar__modal-menu {
  position: relative;
}

.top-bar__modal-navbar .top-bar__modal-submenu .top-bar__modal-submenu-link {
  padding-bottom: 10px;
  list-style: none;
}

.top-bar__modal-navbar
  .top-bar__modal-submenu
  .top-bar__modal-submenu-link:last-child {
  padding-bottom: 0;
}

.top-bar__modal-menu {
  margin-right: 40px;
}

.top-bar__modal-menu:last-child {
  margin-right: 0;
}

.top-bar__modal-menu--has-children,
.top-bar__modal-menu a,
.top-bar__modal-submenu a,
.top-bar__modal-menu {
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  padding-bottom: 15px;
}

.top-bar__modal-submenu {
  text-decoration: none;
  display: block;
  border-radius: 10px;
  padding: 16px;
  width: 246px;
  position: absolute;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8);
}

.top-bar__modal-submenu a {
  color: #3e3e3e;
}

.top-bar__modal-topmenu > .top-bar__modal-menu:after,
.top-bar__modal-topmenu > .top-bar__modal-submenu-link:after {
  position: absolute;
  transform: rotate(30deg);
}

.top-bar__modal-topmenu > .top-bar__modal-menu:hover .top-bar__modal-submenu {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

@media (max-width: 1005px) {
  .top-bar__modal-menu--has-children,
  .top-bar__modal-menu a,
  .top-bar__modal-submenu a,
  .top-bar__modal-menu {
    font-size: 16px;
    line-height: 28px;
  }
  .top-bar__modal-submenu {
    width: 286px;
  }
  .top-bar__modal-menu--has-children.top-bar__modal-menu--has-children--active.two,
  .top-bar__modal-menu--has-children.top-bar__modal-menu--has-children--active.three {
    margin-bottom: 125px;
  }
}

@media (max-width: 640px) {
  .modal-wrapper .modal {
    padding: 110px 0 0 0;
  }
}
