@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

@font-face {
  font-family: "Gotham Probolt";
  src: url(/src/assets/fonts/GothamPro/gothampro_bold.ttf);
}

.teacher__title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
  display: block;

  margin-bottom: 40px;
  padding-top: 170px;
  width: 100%;
  font-family: "Gotham Probolt";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
  display: block;
  margin-bottom: 40px;
  padding-top: 170px;
  max-width: 650px;
  width: 100%;
}

.teacher__main-block {
  display: flex;
  flex-direction: row;
  margin-bottom: 56px;
  justify-content: space-between;
  width: 100%;
}

.teacher__block-title {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  width: 100%;
}

.teacher__main-block-text-row {
  display: flex;
  flex-direction: row;
}

.teacher__main-block-img {
  margin-right: 25px;
  max-width: 285px;
  width: 100%;
  max-height: 428px;
  height: 100%;
}

.teacher__main-block-img img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.teacher__main-block-text {
  /* margin-left: -150px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.teacher__main-block-text .teacher__text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  max-width: 410px;
  display: block;
  margin-bottom: 16px;
}

.teacher__main-block-text .teacher__title {
  display: block;
  margin-bottom: 24px;
  padding-top: 0;
}

.teacher__main-block-text-row .teacher__text {
  display: block;
  margin-right: 16px;
}

.teacher__main-block-text-row .teacher__text:last-child {
  color: #1282e9;
}

.teacher__main-block
  .teacher__main-block-text-row:first-of-type
  .teacher__text:last-child {
  text-decoration: underline;
}
.teacher__main-block .teacher__main-block-text-row:last-child {
  margin-top: 22px;
}

.teacher__main-block-text-row .teacher__button {
  border-radius: 16px;
  padding: 15px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-decoration: none;

  display: block;
  margin-right: 32px;
}

.teacher__main-block-text-row .teacher__button:first-child {
  background: #1282e9;
  color: #ffffff;
}

.teacher__main-block-text-row .teacher__button:last-child {
  background: transparent;
  border: 2px solid #1282e9;
  color: #1282e9;

  display: block;
  margin-right: 0;
}

.teacher__sub-block {
  background: #ededed;
  border-radius: 20px;
  padding: 16px 40px;
  max-width: 548px;
  width: 100%;

  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #282828;

  display: block;
  margin-bottom: 56px;
}

.teacher_exams {
  height: 428px;
  max-width: 284px;
  width: 100%;

  border: 3px solid #1282e9;
  border-radius: 20px;

  padding: 15px 25px 25px 25px;

  background-image: url(../../../../assets/images/New/PMTeacher/bg_image.svg);
  background-position: 45px 55px;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  text-align: center;

  justify-content: center;
  align-items: center;
}

.teacher_exams-column,
.teacher_exams-row {
  display: flex;
  flex-direction: column;
  text-align: center;

  justify-content: center;
  align-items: center;
}

.teacher_exams .teacher__title,
.teacher_exams .teacher__text {
  margin-bottom: 5px;
  padding-top: 0px;
  line-height: 27px;
}

.passing_score {
  font-family: "Gotham Probolt";
  color: #1282e9;
  font-size: 85px;
  line-height: 70px;
}

.subjects_group {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.subject {
  margin-right: 15px;
  color: white;
}

.subject span {
  color: white;
  font-family: "Gotham Probolt";
  font-size: 16px;
  line-height: 20px;
}

.Math {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #1282e9;
}

.Rus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #e912ba;
}

.Phys {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #8d20fb;
}

.Chem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #e95f12;
}

.Eng {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #00cfb6;
}

.IT {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #13c54f;
}

.His {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #e91212;
}

.Soc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #0b4295;
}

.Geo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #6b8e23;
}

.Bio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  background-color: #fbbe20;
}

.teacher__info-block {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 56px;
}

.info-block-frame {
  max-width: 540px;
  width: 100%;
  height: 417px;
  padding: 35px 41px;

  border-radius: 24px;

  background-image: url(../../../../assets/images/New/info_block_bg.png);
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info_block-title {
  margin-bottom: 25px;
  text-align: center;
  font-family: "Gotham Pro";
  font-weight: 600;
  font-size: 22px;
  color: white;
}

.info_block-text_area {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info_block-text-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;
}

.info_block-text-row .info_block-text-row_img {
  margin-right: 29px;
  width: 50px;
  height: 50px;
  align-items: center;
}

.number {
  max-width: 57px;
  width: 100%;

  font-family: "Gotham Pro";
  font-weight: 600;
  font-size: 22px;
  color: white;
}

.text-line {
  font-family: "Gotham Pro";
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: white;
  max-width: 100%;
}

.teacher__info-block-frame-large {
  width: 100%;
  height: 327px;

  margin-bottom: 56px;
  padding: 35px 25px;

  border: 3px solid #1282e9;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
}

.teacher__info-block-frame-large .info_block-title {
  margin-bottom: 15px;
}

.teacher__info-block-frame-large .wrap {
  width: 100%;
  height: 100%;
  position: relative;
}
.teacher__info-block-frame-large .wrap img {
  position: absolute;
}
.teacher__info-block-frame-large .wrap img:first-child {
  margin-top: -35px;
  margin-left: -25px;
}

.teacher__info-block-frame-large .wrap img:last-child {
  margin-right: -25px;
  margin-top: -35px;
  right: 0;
}
.frame-large-text-area {
  margin-bottom: 18px;
  margin-top: 35px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
}

.frame-large-text-row {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin-bottom: 24px;

  justify-content: flex-start;
  flex-wrap: wrap;
}

.frame-large-text-row .info_block-text-row {
  align-items: start;
  max-width: 350px;
  width: 100%;
  margin-right: 10px;
}

.frame-large-text-row .info_block-text-row.last {
  max-width: 700px;
  width: 100%;
}

.frame-large-text-row .info_block-text-row .number,
.frame-large-text-row .info_block-text-row .text-line,
.teacher__info-block-frame-large .info_block-title {
  color: black;
}

@media (max-width: 1145px) {
  .frame-large-text-row .info_block-text-row {
    max-width: 320px;
    width: 100%;
  }
  .info-block-frame:first-child {
    margin-right: 15px;
  }
  .info_block-text-row .info_block-text-row_img {
    margin-right: 20px;
  }
}

@media (max-width: 1122px) {
  .info-block-frame {
    max-width: 500px;
  }
}

@media (max-width: 1115px) {
  .frame-large-text-row .info_block-text-row.last {
    max-width: 500px;
  }
}

@media (max-width: 1095px) {
  .frame-large-text-row .info_block-text-row {
    max-width: 300px;
  }
  .frame-large-text-row .info_block-text-row.last {
    max-width: 600px !important;
  }
}

@media (max-width: 1055px) {
  .frame-large-text-row .info_block-text-row {
    max-width: 400px !important;
    margin-right: 25px;
  }
  .frame-large-text-row .info_block-text-row.last {
    max-width: 700px !important;
  }
  .teacher__info-block-frame-large {
    height: 370px;
  }
  .frame-large-text-area {
    margin-bottom: 0;
  }
  .frame-large-text-row {
    margin-bottom: 0;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 10px;
  }
  .teacher_exams {
    max-width: 235px;
    padding: 15px 25px;
    background-image: none;
  }
  .subject {
    margin-right: 10px;
  }
  .subject:last-child {
    margin-right: 0;
  }
}

@media (max-width: 1005px) {
  .teacher__title {
    padding-top: 120px;
  }
  .teacher__main-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
    justify-content: space-between;
    width: 100%;
  }
  .teacher__block-title {
    margin-right: 0;
    margin-bottom: 56px;
    width: 100%;
  }
  .teacher__main-block-img {
    margin-right: 56px;
  }
  .teacher_exams {
    max-width: 100%;
    flex-direction: row;
    height: 155px;
  }
  .teacher_exams-column {
    margin-right: 55px;
    height: 100%;
    justify-content: space-between;
  }
  .teacher_exams-column:last-child {
    margin-right: 0;
  }
  .teacher_exams-row {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 985px) {
  .frame-large-text-row .info_block-text-row {
    max-width: 340px !important;
  }
  .teacher__info-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
  .info-block-frame {
    max-width: 100% !important;
    margin: 0 0 56px 0;

    background-size: cover;
  }
}

@media (max-width: 905px) {
  .teacher_exams-column {
    margin-right: 25px;
  }
}

@media (max-width: 855px) {
  .frame-large-text-row .info_block-text-row {
    max-width: 100% !important;
  }
}

@media (max-width: 845px) {
  .teacher_exams {
    max-width: 100%;
    flex-direction: column;
    height: 306px;
    background-image: url(../../../../assets/images/New/PMTeacher/bg_image.svg);
    background-position: right;
  }
}

@media (max-width: 835px) {
  .teacher__main-block-img img {
    max-width: 248px;
    max-height: 372px;
    margin-right: 30px;
  }

  .teacher__main-block-text .teacher__text {
    margin-bottom: 10px;
  }

  .teacher__main-block-text .teacher__title {
    margin-bottom: 10px;
  }

  .teacher__main-block-text-row .teacher__button {
    margin-right: 16px;
    padding: 10px 15px;
  }

  .teacher__main-block .teacher__main-block-text-row:last-child {
    margin-top: 10px;
  }

  .teacher__main-block-text {
    max-width: 352px;
    width: 100%;
  }
}
@media (max-width: 750px) {
  .teacher__info-block-frame-large {
    height: 425px;
  }
  .teacher__main-block-img {
    margin-right: 0;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 65px;
  }
}

@media (max-width: 701px) {
  .teacher__main-block {
    flex-direction: column;
  }

  .teacher__main-block-img img {
    margin: 0 0 20px 0;
  }

  .teacher__main-block-text {
    max-width: 100%;
    align-items: center;
  }
  .teacher__main-block-img {
    max-width: 100%;
  }
  .teacher__block-title {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .teacher__title {
    text-align: center;
    max-width: 100%;
  }
}

@media (max-width: 570px) {
  .teacher__info-block-frame-large {
    height: 450px;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 90px;
  }
  .teacher__info-block-frame-large .wrap {
    height: 0;
  }
}

@media (max-width: 558px) {
  .teacher_exams-column {
    margin-right: 10px;
  }
  .teacher_exams-column:last-child {
    margin-right: 0;
  }
}

@media (max-width: 470px) {
  .info-block-frame {
    height: 480px;
  }
}

@media (max-width: 456px) {
  .teacher__info-block-frame-large {
    height: 515px;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 155px;
  }
}

@media (max-width: 420px) {
  .info-block-frame {
    height: 500px;
  }
  .teacher_exams .teacher__text {
    max-width: 130px;
  }
  .teacher_exams-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 411px) {
  .teacher__main-block-img img {
    max-width: 248px;
    max-height: 372px;
  }
  .teacher__info-block-frame-large {
    height: 465px;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 100px;
  }
  .teacher_exams {
    height: 340px;
  }
  .teacher_exams .teacher__title {
    max-width: 250px;
  }
}

@media (max-width: 388px) {
  .teacher__main-block-text-row:last-child {
    flex-direction: column;
  }

  .teacher__main-block-text-row .teacher__button {
    margin-right: 0;
    margin-bottom: 16px;
    text-align: center;
    padding: 15px 24px;
  }
  .teacher__info-block-frame-large {
    height: 480px;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 120px;
  }
  .teacher_exams {
    height: 350px;
  }
  .info-block-frame {
    height: 550px;
  }
  .teacher_exams-row {
    display: flex;
    flex-direction: column;
  }
  .teacher_exams .teacher__text {
    max-width: 100%;
  }
  .teacher_exams {
    height: 390px;
  }
}

@media (max-width: 370px) {
  .text-line {
    font-size: 14px;
  }
  .info_block-text-row {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-bottom: 10px;
  }
  .number {
    max-width: 35px;
    font-size: 18px;
  }
}

@media (max-width: 350px) {
  .teacher__info-block-frame-large {
    height: 520px;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 160px;
  }
}

@media (max-width: 337px) {
  .teacher__info-block-frame-large {
    height: 560px;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 200px;
  }
}

@media (max-width: 330px) {
  .teacher__info-block-frame-large {
    height: 580px;
  }
  .teacher__info-block-frame-large .wrap img:last-child {
    margin-top: 220px;
  }
}
