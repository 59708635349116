:root {
    background: #ffffff;
}

.container {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
}

@media(max-width: 1280px){
    .container{
        padding: 0 85px;
    }
}
@media(max-width: 744px){
    .container{
        padding: 0 24px;
    }
}
@media(max-width: 375px){
    .container{
        padding: 0 20px;
    }
}