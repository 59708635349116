@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.dormitory .question-content__item-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dormitory .tuition-fees__text-block-title {
  max-width: 800px;
  width: 100%;
  margin-right: 40px;
}

.dormitory .tuition-fees__text-block-img {
  margin-right: 40px;
}

.question-content__link-button:hover {
  background: #3e3e3e;
  color: #fff;
}

@media (max-width: 900px) {
  .dormitory .tuition-fees__text-block-img {
    display: none;
  }
}

@media (max-width: 508px) {
  .dormitory .question-content__item-text .question-content__link-button:nth-child(2) {
    height: 52px;
  }
}

@media (max-width: 503px) {
  .dormitory .question-content__item-text .question-content__link-button:nth-child(1) {
    height: 52px;
  }
}
