.magistracy.teacher.vodorod .magpage_about_frame{
    background-image: url(../../../../assets/images/New/MagVodorodTeacher/bg_image.svg);
    background-position: 71px 43px;
}
.magistracy.teacher.vodorod .info-block-frame2{
    background-image: url(../../../../assets/images/New/MagVodorodTeacher/info_bg_image.svg);
    background-position: 0px 47px;
    background-repeat: no-repeat;
}   
.magistracy.teacher.vodorod .info-block-frame3 .info_block-text-row{
    margin-bottom: 0px;
}

