.educational-program.teacher.po
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-top: -35px;
  border-radius: 20px 0px 0px 0px;
  margin-left: -26px;
}
.educational-program.teacher.po
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -25px;
  margin-top: -5px;
  right: 0;
}
.educational-program.teacher.po .teacher_exams {
  background-image: url(../../../../assets/images/New/POTeacher/bg_image.svg);
  background-position: 77px 4px;
}

@media (max-width: 1175px) {
  .educational-program.teacher.po .frame-large-text-row .info_block-text-row {
    max-width: 320px;
    width: 100%;
  }
  .educational-program.teacher.po
    .frame-large-text-row
    .info_block-text-row.last {
    max-width: 500px;
  }
}

@media (max-width: 1095px) {
  .educational-program.teacher.po .frame-large-text-row .info_block-text-row {
    max-width: 300px;
    width: 100%;
    margin-right: 0;
  }
  .educational-program.teacher.po
    .frame-large-text-row
    .info_block-text-row.last {
    max-width: 450px;
  }
  .educational-program.teacher.po .teacher__info-block-frame-large {
    height: 420px;
  }
  .educational-program.teacher.po
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 86px;
  }
}

@media (max-width: 1055px) {
  .educational-program.teacher.po
    .frame-large-text-row
    .info_block-text-row.last {
    max-width: 400px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.po .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.po .teacher_exams {
    background-image: url(../../../../assets/images/New/POTeacher/bg_image.svg);
    background-position: right;
  }
}

@media (max-width: 835px) {
  .educational-program.teacher.po .teacher__main-block-img {
    margin-right: 0;
  }
  .educational-program.teacher.po .teacher__main-block-text {
    max-width: 100%;
  }
}

@media (max-width: 635px) {
  .educational-program.teacher.po .teacher__info-block-frame-large {
    height: 480px;
  }
  .educational-program.teacher.po
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 147px;
  }
}

@media (max-width: 570px) {
  .educational-program.teacher.po .teacher__info-block-frame-large {
    height: 420px;
  }
  .educational-program.teacher.po
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 85px;
  }
}

@media (max-width: 511px) {
  .educational-program.teacher.po .teacher__info-block-frame-large {
    height: 440px;
  }
  .educational-program.teacher.po
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 105px;
  }
}

@media (max-width: 553px) {
  .educational-program.teacher.po .teacher__info-block-frame-large {
    height: 480px;
  }
  .educational-program.teacher.po
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 145px;
  }
}

@media (max-width: 428px) {
  .educational-program.teacher.po .teacher__info-block-frame-large .wrap img:first-child {
    opacity: 0;
}
}

@media (max-width: 411px) {
  .educational-program.teacher.po .number {
    max-width: 35px;
    font-size: 18px;
  }
  .number {
    max-width: 35px;
    font-size: 18px;
  }
  .text-line {
    font-size: 14px;
  }
  .info_block-text-row {
    display: flex;
    flex-direction: row;
    align-items: start;
    margin-bottom: 10px;
  }
}

@media (max-width: 333px) {
  .educational-program.teacher.po .teacher__info-block-frame-large {
    height: 520px;
  }
  .educational-program.teacher.po
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 185px;
  }
}
