.educational-program__main-block.vie
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
.educational-program__main-block.vie
  .individual-achivments__text-block:nth-child(3) {
  max-width: 380px;
  width: 100%;
}

.educational-program__main-block.vie
  .individual-achivments__text-block:nth-child(1) {
  max-width: 50%;
  width: 100%;
}

.educational-program__main-block.vie
  .individual-achivments__text-block:nth-child(2) {
  max-width: 160px;
  width: 100%;
}

.educational-program__main-block.vie
  .individual-achivments__text-block:last-child {
      margin-top: 0;
}
