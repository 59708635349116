.educational-program.teacher.ie
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.ie
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.ie .teacher_exams {
  background-image: url(../../../../assets/images/New/IETeacher/bg_image.svg);
  background-position: 115px 53px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.ie
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 35px;
  }
}
@media (max-width: 1005px) {
  .educational-program.teacher.ie .teacher_exams {
    background-image: none;
  }
  .educational-program.teacher.ie .teacher__main-block-text .teacher__title br {
    display: none;
  }
  .educational-program.teacher.ie .teacher__main-block-text .teacher__title {
    margin-bottom: 15px;
  }
  .educational-program.teacher.ie
    .teacher__main-block
    .teacher__main-block-text-row:last-child {
    margin-top: 10px;
  }
  .educational-program.teacher.ie .teacher__main-block-text .teacher__text {
    margin-bottom: 0;
  }
}
@media (max-width: 855px) {
  .educational-program.teacher.ie .frame-large-text-row .info_block-text-row {
    width: 100%;
  }
}
@media (max-width: 845px) {
  .educational-program.teacher.ie .teacher_exams {
    background-image: url(../../../../assets/images/New/IETeacher/bg_image.svg);
    background-position: 100% 0px;
  }
  .educational-program.teacher.ie .teacher__main-block-text .teacher__title {
    margin-bottom: 5px;
  }
  .educational-program.teacher.ie .teacher__main-block-text {
    max-width: 100%;
  }
  .teacher__main-block-img {
    margin-right: 5px;
  }
}
@media (max-width: 750px) {
  .educational-program.teacher.ie
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 95px;
  }
}
@media (max-width: 570px) {
  .educational-program.teacher.ie .teacher__info-block-frame-large {
    height: 425px;
  }
}
@media (max-width: 457px) {
  .educational-program.teacher.ie .teacher__info-block-frame-large {
    height: 455px;
  }
  .educational-program.teacher.ie
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 125px;
  }
  .educational-program.teacher.ie
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
}
@media (max-width: 430px) {
  .educational-program.teacher.ie .teacher__info-block-frame-large {
    height: 495px;
  }
  .educational-program.teacher.ie
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 165px;
  }
}
@media (max-width: 411px) {
  .educational-program.teacher.ie .teacher__info-block-frame-large {
    height: 435px;
  }
  .educational-program.teacher.ie
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 105px;
  }
  .educational-program.teacher.ie .teacher_exams {
    background-position: 100% 50%;
  }
}
@media (max-width: 365px) {
  .educational-program.teacher.ie .teacher__info-block-frame-large {
    height: 465px;
  }
  .educational-program.teacher.ie
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 135px;
  }
}
