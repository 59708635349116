@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.question-content {
  max-width: 665px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 90px;
}

.question-content .question-content__menu {
  list-style: none;
}

.question-content__item-title-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.question-content .question-content__menu .question-content__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  background: #ededed;
  border-radius: 20px;
  padding: 16px 32px;
  background-image: url(../../../assets/images/ProspectiveStudent/btn.png);
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.question-content .question-content__menu .question-content__item--active {
  background-image: url(../../../assets/images/ProspectiveStudent/btn-top.png);
  background-repeat: no-repeat;
  background-position: 95% 33px;
}

.question-content
  .question-content__menu
  .question-content__item
  .question-content__item-title-number {
  background: #1282e9;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  margin-right: 32px;

  font-family: Gotham ProMedium;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.question-content
  .question-content__menu
  .question-content__item
  .question-content__item-title {
  font-family: Gotham ProMedium;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #282828;
}

.question-content .question-content__menu .question-content__item-text {
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: all 0.3s;
  font-family: Gotham Pro;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #282828;
}

.question-content__item-text:last-child,
.question-content__item-link-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.question-content__item-link-block {
  max-width: 330px;
  width: 100%;
}

.question-content__link-button {
  display: flex;
  align-items: center;
  padding: 11px 26px;
  gap: 10px;
  height: 32px;
  border: 1px solid #3e3e3e;
  border-radius: 30px;
  margin: 0 15px 15px 0;
  text-decoration: none;
}

.question-content
  .question-content__menu
  .question-content__item--active
  .question-content__item-text,
.question-content
  .question-content__menu
  .question-content__item--active
  .question-content__item-text.block {
  visibility: visible;
  height: fit-content;
  animation: 0.3s linear animateQuestionItem forwards;
  padding: 32px 0 0 0;
}

@keyframes animateQuestionItem {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1117px) {
  .question-content {
    margin-right: 50px;
  }
}

@media (max-width: 1095px) {
  .question-content {
    max-width: 570px;
  }
}

@media (max-width: 1013px) {
  .question-content__item-link-block
    .question-content__link-button:nth-child(1) {
    height: 55px;
    width: 240px;
  }
}

@media (max-width: 980px) {
  .step-procpective-student__row .step-procpective-student__column:last-child {
    display: none;
  }
  .question-content {
    max-width: 100%;
  }
}

@media (max-width: 529px) {
  .question-content
    .question-content__menu:first-child
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(1) {
    height: 54px;
  }
}

@media (max-width: 397px) {
  .question-content
    .question-content__menu:nth-child(3)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(3),
  .question-content
    .question-content__menu:nth-child(5)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(3),
  .question-content
    .question-content__menu:nth-child(4)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(3) {
    height: 54px;
  }
}

@media (max-width: 383px) {
  .question-content
    .question-content__menu:nth-child(2)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(3),
  .question-content
    .question-content__menu:nth-child(2)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(4) {
    height: 54px;
  }
}

@media (max-width: 370px) {
  .question-content
    .question-content__menu:nth-child(2)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(5) {
    height: 54px;
  }
}

@media (max-width: 360px) {
  .question-content
    .question-content__menu:nth-child(2)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(2) {
    height: 54px;
  }
}

@media (max-width: 357px) {
  .question-content
    .question-content__menu:nth-child(1)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(2),
  .question-content
    .question-content__menu:nth-child(3)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(1) {
    height: 54px;
  }
}

@media (max-width: 349px) {
  .question-content
    .question-content__menu:nth-child(1)
    .question-content__item--active
    div.question-content__item-text
    .question-content__link-button:nth-child(1){
    height: 74px;
  }
}
