@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.individual-achivments {
  display: flex;
  flex-direction: column;
}

.individual-achivments__title {
  font-family: "Gotham ProMedium";
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;

  display: block;
  padding-bottom: 40px;
}

.individual-achivments__main-block {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
}

.individual-achivments__subtitle {
  font-family: "Gotham ProMedium";
  font-weight: 700;
  font-size: 70px;
  line-height: 115px;
  max-width: 160px;
  width: 100%;
  color: #1282e9;
  display: block;
  border-bottom: 1px solid #1282e9;
}

.individual-achivments__text-block {
  max-width: 190px;
  width: 100%;
  margin-right: 46px;
}

.individual-achivments__text {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
  display: block;
  padding-top: 24px;
  width: 225px;
}

.individual-achivments__button {
  background: #1282e9;
  border-radius: 30px;
  padding: 13px 24px;
  width: 185px;
  text-decoration: none;
}

.individual-achivments__text-button {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

@media (max-width: 1095px) {
  .individual-achivments__main-block {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .individual-achivments__text-block {
    margin-right: 24px;
    max-width: 215px;
  }
}

@media (max-width: 1025px) {
  .individual-achivments__text-block {
    max-width: 260px;
  }
}

@media (max-width: 637px) {
  .individual-achivments__text-block {
    max-width: 230px;
  }
}

@media (max-width: 537px) {
  .individual-achivments__subtitle {
    font-size: 70px;
    line-height: 110px;
    max-width: 130px;
  }
  .individual-achivments__text {
    width: 160px;
  }
  .individual-achivments__text-block {
    max-width: 190px;
  }
}

@media (max-width: 457px) {
  .individual-achivments__text-block {
    max-width: 140px;
  }
  .individual-achivments__title,
  .etap-postupleniya__title {
    text-align: center;
    padding-right: 0;
  }
}

@media (max-width: 436px) {
  .individual-achivments {
    align-items: center;
  }
}

@media (max-width: 357px) {
  .individual-achivments__main-block {
    justify-content: center;
  }
  .individual-achivments__text,
  .individual-achivments__subtitle,
  .individual-achivments__text-block {
    max-width: 147px;
    width: 100%;
    text-align: center;
    margin-right: 0;
    padding-right: 0;
  }
  .individual-achivments__subtitle {
    font-size: 50px;
    line-height: 90px;
    max-width: 130px;
  }
  .individual-achivments__text-block .individual-achivments__text{
    margin-right: 0;
    padding-right: 0;
  }
}
