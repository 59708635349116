.entrance-tests.magistratura .undegraduate-ICTE-info,
.entrance-tests .acceptance-checksum__table-title-block:nth-child(3),
.entrance-tests .acceptance-checksum__table-title-block.two {
  justify-content: space-between;
  align-items: center;
}

.entrance-tests.magistratura
  .entrance-tests__column:last-of-type
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects {
  color: #3e3e3e;
  font-size: 14px;
  line-height: 13px;
}


.entrance-tests.magistratura
  .undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:nth-child(2),
.entrance-tests.magistratura .acceptance-checksum__table-title:not(:nth-of-type(3)) {
  /* max-width: 109px;
  width: 100%; */
  margin-right: 35px;
}

.entrance-tests.magistratura
.entrance-tests__column:last-of-type .acceptance-checksum__table-title{
  margin-right: 0;
}

.entrance-tests.magistratura
  .acceptance-checksum__table-title-block
  .entrance-tests__column:last-of-type
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects {
  padding: 0;
  text-align: center;
}

.entrance-tests.magistratura .acceptance-checksum__table-title:first-child {
  justify-content: start;
}

#mag_tb_fc_title{
  max-width: 335px;
}
