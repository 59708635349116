.top-bar-prospective-student {
  height: 43px;
  padding: 96px 0 31px 0;
  background: #ededed;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.top-bar-prospective-student__navbar,
.top-bar-prospective-student__topmenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

ul.top-bar-prospective-student__topmenu {
  list-style: none;
}

.top-bar-prospective-student__menu a {
  text-decoration: none;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.02em;
}

@media (max-width: 1005px) {
  .top-bar-prospective-student {
    display: none;
  }
}
