@font-face {
  font-family: "GothamProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}
@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro_light.ttf);
}
@font-face {
  font-family: "Grandis Extended";
  src: url(/src/assets/fonts/GrandisExtended/GrandisExtended-Light.ttf);
}
@font-face {
  font-family: "Grandis Extended Medium";
  src: url(/src/assets/fonts/GrandisExtended/GrandisExtended-Medium.ttf);
}
@font-face {
  font-family: "Onest";
  src: url(/src/assets/fonts/Onest/OnestExtraBold1602-hint.ttf);
}
@font-face {
  font-family: "Onest Light";
  src: url(/src/assets/fonts/Onest/OnestMedium1602-hint.ttf);
}
@font-face {
  font-family: "Gilroy";
  src: url(/src/assets/fonts/Gilroy/Gilroy-Semibold.ttf);
}
@font-face {
  font-family: "GilroyHeavy";
  src: url(/src/assets/fonts/Gilroy/Gilroy-Heavy.ttf);
}
@font-face {
  font-family: "GilroyMedium";
  src: url(/src/assets/fonts/Gilroy/Gilroy-Medium.ttf);
}
@font-face {
  font-family: "RalewayBold";
  src: url(/src/assets/fonts/Raleway/Raleway-Bold.ttf);
}
@font-face {
  font-family: "Raleway";
  src: url(/src/assets/fonts/Raleway/Raleway-Light.ttf);
}

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.slider-wrapper.one {
  display: none;
  background-color: #fff;
}

.slider-wrapper.three {
  display: none;
}

.slider-wrapper {
  padding-top: 96px;
  border-radius: 30px;
}

.slick-slider,
.slick-list,
.slick-track,
.slick-initialized .slick-slide,
.slick-slide img {
  height: 480px;
  width: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.slick-slide img.image-two {
  display: none;
}

.slick-dots {
  bottom: 20px;
  z-index: 12;
  left: -15%;
}

.slick-dots li.slick-active button:before {
  color: #1282e9;
}

button.slick-arrow.slick-next {
  border-style: solid;
  border-color: #7d7d7d;
  border-image: initial;
  border-width: 0px 4px 4px 0px;
  display: inline-block;
  padding: 3px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  transform: rotate(-45deg);
  margin-right: 1em;

  border-radius: 3px;
}

button.slick-arrow.slick-prev {
  border-style: solid;
  border-color: #7d7d7d;
  border-image: initial;
  border-width: 0px 4px 4px 0px;
  display: inline-block;
  padding: 3px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  transform: rotate(135deg);
  margin-right: 1em;
  z-index: 1;

  border-radius: 3px;
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}

.slick-dots li {
  background: #fff;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.slick-dots li.slick-active {
  background: #1282e9;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.slick-dots li button:before,
.slick-prev:before,
.slick-next:before {
  content: "";
}

.slider-wrapper__block {
  position: relative;
  display: flex;
}

.slider-wrapper__block .slider-wrapper__block-text {
  position: absolute;
  left: 65px;
  margin-top: -385px;
}

.slider-wrapper__block .slider-wrapper__block-text.open-day {
  margin-top: -385px;
  left: 275px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.slider-wrapper__block .slider-wrapper__block-text.open-day img {
  height: 100%;
  width: 170px;
  margin: -65px 15px 0 25%;
  border-radius: 0;
}

.slider-wrapper__text-block {
  background-color: rgba(225, 225, 225, 0.7);
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  justify-content: space-between;
  padding: 20px 30px;
  border-radius: 0 30px 30px 0;
}

.slider-wrapper__text-block.one {
  display: flex;
  flex-direction: row;
  background: transparent;
  height: 60px;
  width: 100%;
}

.slider-wrapper__block-text {
  display: flex;
  flex-direction: column;
}

.slider-wrapper__block-title {
  font-family: "GothamProMedium";
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
  color: #ffffff;
  padding-bottom: 25px;
  max-width: 500px;
  width: 100%;
  text-decoration: none;

  letter-spacing: 0.02em;
}

.slider-wrapper__block-text.open-day .slider-wrapper__block-title {
  font-family: "Onest";
  font-weight: 900;
  font-size: 40px;
  line-height: 43px;
  z-index: 9;
  max-width: 100%;
}

.slider-wrapper__block-subtitle {
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  padding-bottom: 38px;

  letter-spacing: 0.02em;
}

.slider-wrapper__block-text.open-day__footer {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.slider-wrapper__block-text.open-day__footer .open-day__snake {
  height: 216px;
  width: 100%;
  margin: 140px 0 0 0;
  border-radius: 0;
}

.slider-wrapper__block-text.open-day__footer .open-day__qr {
  height: 450px;
  width: 100%;
  margin: -90px 0 0 -365px;
  border-radius: 0;
  position: absolute;
  object-fit: contain;
  /* transform: rotate(-60deg); */
}

.slider-wrapper__block-text.open-day__footer .open-day__cursor {
  height: 30px;
  width: 30px;
  border-radius: 0;
  margin: 90px 0 0 23%;
  position: absolute;
  object-fit: contain;
  transform: scale(-1, 1);
}

.slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
  margin: 155px 0 0 25%;
  position: absolute;
}

.slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
  margin: 230px 0 0 26%;
  position: absolute;
}

.open-day__footer.text-block {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 65px 0 0 0;
  width: 380px;
  text-align: start;
  z-index: 99;
}

.open-day__footer.text-block.two {
  margin: 197px 0 0 73%;
  width: 100%;
}

.open-day__footer.text-block.two .slider-wrapper__block-title {
  width: 100%;
}

.open-day__footer.text-block.two .slider-wrapper__block-button {
  font-family: "Onest";
  font-size: 18px;
  line-height: 20px;
  margin-top: 20px;
  margin-left: 57%;
  background-color: #f26e18;
  max-width: 180px;
  width: 100%;
  color: #fff;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  border-radius: 30px;
}

.open-day__footer.text-block .slider-wrapper__block-title {
  font-family: "Grandis Extended";
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #000;
  padding-bottom: 35px;
  max-width: 380px;
  width: 100%;
}

.slider-wrapper__block-text.open-day__block-text {
  display: flex;
  flex-direction: row;
  position: absolute;
}

.slider-wrapper__block-text.open-day__block-text img {
  height: 128px;
  width: 100%;
  margin: -85px 0 0 130px;
}

.slider-wrapper__block-link {
  width: 184px;
  height: 48px;
  background: #1282e9;
  border-radius: 30px;
  padding: 15px 24px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  letter-spacing: 0.02em;
}

.slider-wrapper__text-block-column,
.slider-wrapper__number-block {
  display: flex;
  flex-direction: row;
}
.slider-wrapper__number,
.slider-wrapper__text-block-text {
  padding-right: 20px;
  font-weight: 500;
  font-family: GothamProMedium;
}

.slider-wrapper__text-block-link .footer__link {
  background: transparent;
}

.slider-wrapper__text-block-link {
  display: flex;
  flex-direction: row;
}

.slider-wrapper__block-text-row {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.slider .slider-wrapper__block.two .slider-wrapper__block-link,
.slider .slider-wrapper__block.three .slider-wrapper__block-link,
.slider .slider-wrapper__block.four .slider-wrapper__block-link {
  background: #fff;
  text-align: center;
  color: #1282e9;
}

.slider .slider-wrapper__block.two .slider-wrapper__block-subtitle {
  margin-left: 30px;
  color: #fff;
}

.slider .slider-wrapper__block.three .slider-wrapper__block-title {
  font-size: 60px;
  line-height: 53px;
}

.slider .slider-wrapper__block.three .slider-wrapper__block-text {
  top: 20px;
}

.slider .slider-wrapper__block.four .slider-wrapper__block-text {
  top: 18%;
}

.slider .slider-wrapper__block.four .slider-wrapper__block-title {
  font-size: 35px;
  line-height: 45px;
}

.slider .slider-wrapper__block.four .slider-wrapper__block-subtitle {
  font-size: 26px;
  line-height: 36px;
}

.slider
  .slider-wrapper__block.four
  .slider-wrapper__block-subtitle:nth-child(1) {
  display: block;
  padding-bottom: 20px;
}

.slider .slider-wrapper__block.four .slider-wrapper__block-subtitle.sub {
  max-width: 580px;
  width: 100%;
}

.slider .slider-wrapper__block.four .slider-wrapper__block-title {
  font-size: 60px;
  line-height: 53px;
  max-width: 522px;
  width: 100%;
}

.slider-wrapper__block-text-button {
  display: flex;
  flex-direction: row;
}

.slider-wrapper__block-text-button .slider-wrapper__block-link {
  margin-right: 20px;
}

.slider-wrapper__block-text-button .slider-wrapper__block-link:nth-child(2) {
  width: 322px;
}

.slider-wrapper__block-text-button .slider-wrapper__block-link:last-child {
  width: 210px;
}

.footer__link.five {
  padding-top: 3px;
}

.slider-wrapper__block.soglasie
  .slider-wrapper__block-text
  .slider-wrapper__block-title {
  font-family: "GothamProMedium";
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  padding-bottom: 25px;
  max-width: 675px;
  width: 100%;
  letter-spacing: 0.02em;
}

.slider-wrapper__block.soglasie .billy {
  position: absolute;
  margin: -450px 0 0 70%;
  height: 100%;
  width: 356px;
}

.slider-wrapper__block-text.student .student.text-block {
  display: flex;
  flex-direction: column;
}

.slider-wrapper__block-text.student .student.text-block:first-child {
  padding: 40px 0 0 0;
}

.slider-wrapper__block-text.student .student.text-block:last-child {
  padding-right: 10px;
  padding-left: 10px;
  margin: -70px 0 0 0;
  text-align: end;
}

.slider-wrapper__block-text.student {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1030px;
}

.slider-wrapper__block-text.student .slider-wrapper__block-title {
  font-family: "Gilroy";
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: 0.03em;

  color: #000000;
  width: 100%;
}

.slider-wrapper__block-text.student .slider-wrapper__block-title.one {
  padding-bottom: 70px;
}

.slider-wrapper__block-text.student .slider-wrapper__block-title.sub {
  font-size: 30px;
  line-height: 34px;
  padding-bottom: 10px;
}

.slider-wrapper__block-text.student .slider-wrapper__student-text {
  font-family: "GilroyMedium";
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.03em;

  color: #000000;
  max-width: 345px;
  width: 100%;

  padding-bottom: 15px;
}

.slider-wrapper__block-text.student .slider-wrapper__student-button {
  margin-top: 10px;
  border: 1px solid #000;
  border-radius: 30px;
  padding: 10px 15px;
  text-align: center;
  max-width: 300px;
  width: 100%;

  font-family: "GilroyMedium";
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.03em;
  color: #000000;
  text-decoration: none;
}

.slider-wrapper__block-text.student .student__img {
  height: 100%;
  width: 200px;

  margin: 70px 0 0 50px;
}

.slider-wrapper__block.march-open-day .slider-wrapper__block-text.student {
  display: flex;
  flex-direction: column;
}

.slider-wrapper__block.march-open-day .slider-wrapper__block-title,
.slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
  font-family: RalewayBold;
  font-weight: 700;
  font-size: 75px;
  line-height: 65px;
  letter-spacing: 0.03em;
  color: #fff;
  padding-bottom: 0;
  max-width: 100%;
}

.slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
  padding: 0 0 0 65px;
}

.slider-wrapper__block.march-open-day .slider-wrapper__student-button {
  border-radius: 65px;
  border: 5px solid #fff;
  padding: 5px 25px;

  font-family: "Gilroy";
  font-weight: 600;
  font-size: 66px;
  line-height: 72px;
  letter-spacing: 0.03em;
  color: #eec581;
  transform: rotate(-6.34deg);

  max-width: 460px;
  margin: 40px 0 0 140px;
}

.slider-wrapper__text-block .slider-wrapper__text-block-link .footer__link {
  margin: 0px 8px;
}

.slider-wrapper__block.magi .slider-wrapper__block-text .logo {
  height: 108px;
  width: 248px;
  position: absolute;
  margin: -60px 0 0 0;
}

.slider-wrapper__block.magi .open-day__qr {
  height: 384px;
  width: 391px;
  position: absolute;
  margin: 0 0 0 0;
}

.slider-wrapper__block.magi .open-day__qr {
  height: 384px;
  width: 391px;
  position: absolute;
  margin: -50px 0 0 35%;
}

.slider-wrapper__block.magi .open-day__footer.text-block {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 100px 0 0 0;
  width: 380px;
  text-align: center;
  z-index: 99;
}

.slider-wrapper__block.magi
  .open-day__footer.text-block
  .slider-wrapper__block-title:nth-child(1) {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.02em;
  padding-bottom: 15px;

  color: #fe7f76;
}

.slider-wrapper__block.magi
  .open-day__footer.text-block
  .slider-wrapper__block-title:nth-child(2) {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 69px;
  letter-spacing: 0.02em;

  color: #4042e2;
}

.slider-wrapper__block.magi .slider-wrapper__block-button {
  display: flex;
  flex-direction: row;
}

.slider-wrapper__block.magi
  .slider-wrapper__block-button
  .slider-wrapper__block-subtitle {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-decoration: none;

  color: #fff;

  padding: 15px 30px;
  background-color: #fe7f76;
  border-radius: 30px;
}

.slider-wrapper__block.magi
  .slider-wrapper__block-button
  .slider-wrapper__block-subtitle:nth-child(1) {
  margin-right: 15px;
}

.slider-wrapper__block.start .open-day__footer.text-block {
  margin: 0;
}

.slider-wrapper__block.start .slider-wrapper__block-title {
  font-family: "Gilroy";
  font-weight: 800;
  font-size: 60px;
  line-height: 69px;
  letter-spacing: 0.02em;
  text-align: center;

  color: #ffffff;

  margin: -30px 0 0 -30px;
}
.slider-wrapper__block.start .slider-wrapper__block-title-column {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 -30px;
}

.slider-wrapper__block.start
  .slider-wrapper__block-title-column
  .slider-wrapper__block-title {
  font-family: "Gilroy";
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;

  color: #3e3e3e;
  padding-bottom: 5px;
  margin: 0;
  width: 280px;
}

.slider-wrapper__block.start
  .slider-wrapper__block-title-column
  .slider-wrapper__block-subtitle {
  font-family: "GilroyMedium";
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #3e3e3e;
  text-align: center;
  text-decoration: none;

  padding: 12px 20px;
  background-color: #fff;
  border-radius: 30px;
  width: 280px;
}

.slider-wrapper__block.start
  .slider-wrapper__block-text.open-day__footer
  .img-one-slider {
  width: 364px;
  height: 100%;
  margin: 100px 0 0 30%;
}

.slider-wrapper__block.start .start-two,
.slider-wrapper__block.start .start-three {
  display: none;
}

.slider-wrapper__block.dod .logo-dod {
  position: absolute;
  left: 20px;
  top: 20px;
  height: 50px;
  width: 117px;
  border-radius: 0;
}

.slider-wrapper__block.dod .slider-wrapper__block-title {
  font-family: "Grandis Extended Medium";
  font-size: 70px;
  line-height: 70px;
  letter-spacing: 2px;
  color: #ffffff;
  padding-bottom: 5px;
}

.slider-wrapper__block.dod .slider-wrapper__block-subtitle {
  font-family: "Grandis Extended Medium";
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 2px;
  color: #ffffff;
  padding-bottom: 5px;
}

.slider-wrapper__block.dod .slider-wrapper__block-title.red {
  font-family: "GilroyHeavy";
  font-size: 100px;
  line-height: 100px;
  letter-spacing: 18px;
  color: transparent;
  -webkit-text-stroke: 8px #ffffff;
  margin: -360px 0 0 235px;
}

.spo .open-day__footer.text-block {
  margin: 25px 0 0 0;
}

.spo .spo-logo {
  position: absolute;
  margin: -435px 0 0 70px;
  height: 40px;
  width: 240px;
  border-radius: 0;
}

.spo .spo-girls {
  position: absolute;
  height: 538px;
  width: 600px;
  border-radius: 0;
  right: 10%;
  top: 0;
}

.spo
  .slider-wrapper__block-text.open-day__footer
  .slider-wrapper__block-subtitle {
  font-family: "RalewayBold";
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  padding-bottom: 15px;
  color: #262626;
}

.spo
  .slider-wrapper__block-text.open-day__footer
  .slider-wrapper__block-title.spo-fill {
  background-color: #e17642;
  border-radius: 30px;
  height: 5px;
  width: 250px;
  padding: 0;
  margin-bottom: 15px;
}

.spo .slider-wrapper__block-text.open-day__footer .slider-wrapper__block-title {
  font-family: "Raleway";
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  padding-bottom: 15px;
  color: #262626;
}

.spo
  .slider-wrapper__block-text.open-day__footer
  .slider-wrapper__block-title.spo-button {
  text-decoration: none;
  text-align: center;
  background-color: #e17642;
  color: #fff;
  border-radius: 80px;
  padding: 13px;
  margin-top: 30px;
}

.manager .spo-logo {
  position: absolute;
  margin: -435px 0 0 70px;
  height: 39px;
  width: 320px;
  border-radius: 0;
}

.manager
  .slider-wrapper__block-text.open-day__footer
  .slider-wrapper__block-subtitle {
  font-family: "RalewayBold";
  font-size: 50px;
  line-height: 60px;
  color: #fff;
  padding-bottom: 15px;
}

.manager
  .slider-wrapper__block-text.open-day__footer
  .slider-wrapper__block-title {
  font-family: "Raleway";
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  font-weight: 200;
}
.manager .open-day__footer.text-block {
  width: 450px;
  margin: 45px 0 0 0;
}

/* <--------------------------------------------------------------------------------------> */

@media (max-width: 1175px) {
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: -10px 0 0 448px;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-subtitle.qr {
    margin: -50px 0 0 895px;
  }

  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 90px 0 0 20%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 155px 0 0 22%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 230px 0 0 24%;
  }
}

@media (max-width: 1135px) {
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    height: 200px;
  }
  .open-day__footer.text-block.two {
    margin: 197px 0 0 65%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -70px 0 0 -35%;
    height: 400px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 90px 0 0 17%;
    z-index: 2;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 155px 0 0 19%;
    z-index: 2;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 230px 0 0 21%;
    z-index: 2;
  }
  .slider .slider-wrapper__block.two .slider-wrapper__block-subtitle {
    max-width: 500px;
    width: 100%;
  }
  .slider-wrapper__block.magi .open-day__qr {
    height: 384px;
    width: 391px;
    margin: -50px 0 0 35%;
  }
  .spo .spo-girls {
    height: 488px;
    width: 550px;
    right: 8%;
  }
}

@media (max-width: 1095px) {
  .slider-wrapper__block .slider-wrapper__block-text {
    top: 15%;
  }
  .slider .slider-wrapper__block.three .slider-wrapper__block-title,
  .slider .slider-wrapper__block.four .slider-wrapper__block-title {
    font-size: 50px;
    line-height: 45px;
  }
  .slider .slider-wrapper__block.four .slider-wrapper__block-subtitle {
    font-size: 22px;
    line-height: 32px;
  }
  .slider .slider-wrapper__block.four .slider-wrapper__block-subtitle.sub {
    max-width: 490px;
    width: 100%;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: -10px 0 0 420px;
  }
  .open-day__footer.text-block.two {
    margin: 205px 0 0 55%;
  }
  .open-day__footer.text-block.two .slider-wrapper__block-button {
    margin-top: 10px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    left: 210px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__block-title {
    font-size: 30px;
    line-height: 35px;
    max-width: 290px;
    width: 100%;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    font-size: 18px;
    line-height: 20px;
    max-width: 290px;
    width: 100%;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-button {
    max-width: 290px;
    font-size: 18px;
    line-height: 20px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__block-title.sub {
    font-size: 25px;
    line-height: 30px;
  }

  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    font-size: 70px;
    line-height: 60px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 60px;
    line-height: 65px;
    margin: 40px 0 0 90px;
    max-width: 430px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 115px 0 0 15%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 185px 0 0 17%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 260px 0 0 18%;
  }
  .slider-wrapper__block-text-row {
    flex-direction: column-reverse;
    margin-top: 0px;
  }
  .slider .slider-wrapper__block.two .slider-wrapper__block-subtitle {
    margin-left: 0;
    padding-bottom: 20px;
  }
  .slider-wrapper__block.magi .open-day__qr {
    margin: -50px 0 0 40%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    font-size: 50px;
    line-height: 59px;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-subtitle {
    font-size: 22px;
    line-height: 26px;
    width: 295px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-subtitle {
    font-size: 50px;
    line-height: 50px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title {
    font-size: 60px;
    line-height: 60px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    font-size: 90px;
    line-height: 90px;
    letter-spacing: 15px;
    -webkit-text-stroke: 7px #ffffff;
    margin: -315px 0 0 205px;
  }
  .spo .spo-girls {
    height: 479px;
    width: 534px;
    right: 5%;
  }
}

@media (max-width: 1060px) {
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-subtitle,
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-title {
    width: 275px;
  }
}

@media (max-width: 1040px) {
  .slider-wrapper {
    width: 100%;
    border-radius: 0;
  }
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-initialized .slick-slide,
  .slick-slide img {
    border-radius: 0;
  }
  .slider-wrapper.one {
    display: flex;
  }
  .slider-wrapper.two,
  .slider-wrapper.three {
    display: none;
  }
  .slider-wrapper.one .slick-dots {
    left: 0;
  }
  .slider-wrapper__block-text-button .slider-wrapper__block-link:first-of-type {
    margin-bottom: 10px;
  }
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-subtitle {
    max-width: 605px;
    width: 100%;
  }
  .slider-wrapper__block-text.open-day,
  .slider-wrapper__block.open-day {
    display: flex;
    flex-direction: column;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    left: 35px;
  }
  .open-day__footer.text-block.two {
    margin: 205px 0 0 55%;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day img {
    margin: -65px 15px 0 -175px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -65px 0 0 20%;
  }
  .open-day__footer.text-block .slider-wrapper__block-title {
    padding-bottom: 50px;
    max-width: 400px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 110px 0 0 35%;
    transform: inherit;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 195px 0 0 31%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 280px 0 0 36%;
  }
  img.holiday-two,
  img.holiday-three,
  img.holiday-four {
    display: none;
  }
  .slider-wrapper__block-text.student {
    max-width: 970px;
    width: 100%;
  }
  .slider-wrapper__block-text.student .slider-wrapper__block-title.one {
    padding-bottom: 75px;
  }
  .slider-wrapper__block-text.student .student__img {
    margin: 100px 0 0 65px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    padding-left: 20px;
  }
  .slick-slide img.image-two {
    display: flex;
  }
  .slick-slide img.image-one {
    display: none;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    margin: 40px 0 0 280px;
  }
  .open-day__footer.text-block {
    text-align: start;
    margin: 0;
    width: 380px;
  }

  .slider-wrapper__block.magi .open-day__qr {
    height: 384px;
    width: 391px;
    position: absolute;
    margin: -35px 0 0 50%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    font-size: 60px;
    line-height: 69px;
    margin: 0 0 0 0;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -80px 0 0 160%;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-text.open-day__footer
    .img-one-slider {
    width: 420px;
    height: 100%;
    margin: -30px 0 0 57%;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title {
    font-size: 70px;
    line-height: 70px;
    margin: 0;
    padding-bottom: 5px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    margin: -315px 0 0 305px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-subtitle {
    font-size: 60px;
    line-height: 60px;
  }
  .spo .spo-logo,
  .manager .spo-logo {
    margin: -435px 0 0 35px;
  }
}

@media (max-width: 1005px) {
  .slider-wrapper {
    padding-top: 117px;
  }
  .slider-wrapper__block-text.student {
    max-width: 930px;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -80px 0 0 155%;
  }
}

@media (max-width: 950px) {
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    font-size: 105px;
    line-height: 110px;
    margin: 10px 0 0 420px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -65px 0 0 18%;
  }
  .slider-wrapper__block-text.student {
    max-width: 875px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__block-title,
  .slider-wrapper__block-text.student .slider-wrapper__block-title.sub {
    font-size: 25px;
    line-height: 30px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    font-size: 16px;
    line-height: 18px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-button {
    max-width: 245px;
    font-size: 16px;
    line-height: 18px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    font-size: 70px;
    line-height: 60px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 60px;
    line-height: 65px;
    margin: 15px 0 0 265px;
    max-width: 430px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 110px 0 0 38%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 195px 0 0 33%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 280px 0 0 38%;
  }
  .slider-wrapper__block.magi .open-day__qr {
    height: 384px;
    width: 391px;
    position: absolute;
    margin: -35px 0 0 50%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -80px 0 0 145%;
  }
  .spo .spo-girls {
    height: 479px;
    width: 534px;
    right: -5%;
  }
}

@media (max-width: 925px) {
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-initialized .slick-slide,
  .slick-slide img {
    height: 400px;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    margin-top: -355px;
    max-width: 100%;
  }
  .slider .slider-wrapper__block.three .slider-wrapper__block-text {
    top: 45px;
  }
  .slider-wrapper__block-text-row {
    margin-top: 10px;
  }
  .slider-wrapper__block.soglasie .billy {
    margin: -400px 0 0 70%;
    width: 306px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -380px;
  }
  .slider-wrapper__block-text.open-day .slider-wrapper__block-title {
    font-size: 50px;
    line-height: 54px;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: 25px 0 0 370px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    height: 180px;
    margin: 160px 0 0 0;
  }
  .slider-wrapper__block-text.open-day .slider-wrapper__block-title {
    font-size: 40px;
    line-height: 44px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -325px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    height: 360px;
    width: 100%;
    margin: -20px 0 0 15%;
  }
  .open-day__footer.text-block.two .slider-wrapper__block-button {
    margin-top: 15px;
    margin-left: 51%;
  }
  .open-day__footer.text-block.two {
    margin: 190px 0 0 65%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 115px 0 0 40%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 180px 0 0 35%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 243px 0 0 40%;
  }
  .slider-wrapper__block-text.student .student.text-block:first-child {
    padding: 70px 0 0 0;
  }
  .slider-wrapper__block-text.student .student.text-block:last-child {
    margin: -15px 70px 0 0;
  }
  .slider-wrapper__block-text.student .student__img {
    width: 170px;
    margin: 110px 0 0 65px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    font-size: 60px;
    line-height: 50px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day
    .slider-wrapper__block-title:first-child {
    padding-top: 30px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    padding-left: 70px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 60px;
    line-height: 65px;
    margin: 15px 0 0 190px;
    max-width: 430px;
  }

  .slider-wrapper__block.magi .open-day__qr {
    height: 354px;
    width: 361px;
    position: absolute;
    margin: -30px 0 0 50%;
  }
  .slider-wrapper__block.magi .slider-wrapper__block-text .logo {
    height: 78px;
    width: 185px;
    position: absolute;
    margin: -20px 0 0 0;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-subtitle {
    font-size: 20px;
    line-height: 22px;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-title {
    font-size: 16px;
    line-height: 18px;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-subtitle,
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-title {
    width: 255px;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -50px 0 0 145%;
  }
  .open-day__footer.text-block {
    margin: 4% 0 0 0;
  }
  .spo .spo-logo,
  .manager .spo-logo {
    margin: -370px 0 0 35px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-family: "RalewayBold";
    font-size: 30px;
    line-height: 40px;
    margin-top: 20px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title.spo-fill {
    width: 195px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 10px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title.spo-button {
    width: 345px;
  }
  .manager
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-size: 40px;
    line-height: 50px;
    margin-top: 20px;
  }
  .manager
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title {
    font-size: 20px;
    line-height: 25px;
    max-width: 215px;
    width: 100%;
  }
  .spo .spo-girls {
    height: 400px;
    width: 460px;
    right: -1%;
  }
}

@media (max-width: 875px) {
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -54px 0 0 125%;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-text.open-day__footer
    .img-one-slider {
    width: 350px;
    height: 100%;
    margin: 0 0 0 55%;
  }
}

@media (max-width: 850px) {
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-initialized .slick-slide,
  .slick-slide img {
    height: 350px;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    margin-top: -305px;
  }
  .slider-wrapper__block-title {
    font-size: 60px;
    line-height: 70px;
  }
  .slider-wrapper__block-subtitle {
    padding-bottom: 28px;
  }
  .slider .slider-wrapper__block.three .slider-wrapper__block-title,
  .slider .slider-wrapper__block.four .slider-wrapper__block-title {
    font-size: 48px;
    line-height: 40px;
  }
  .slider .slider-wrapper__block.three .slider-wrapper__block-title,
  .slider .slider-wrapper__block.four .slider-wrapper__block-title {
    font-size: 45px;
    line-height: 35px;
  }
  .slider .slider-wrapper__block.four .slider-wrapper__block-subtitle {
    font-size: 20px;
    line-height: 30px;
  }
  .slider .slider-wrapper__block.four .slider-wrapper__block-subtitle.sub {
    max-width: 435px;
    width: 100%;
  }
  .slider-wrapper__block-text-button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-title {
    font-size: 34px;
    line-height: 48px;
  }
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-subtitle {
    font-size: 16px;
    line-height: 23px;
    max-width: 505px;
    width: 100%;
  }
  .slider-wrapper__block.soglasie .billy {
    margin: -350px 0 0 70%;
    width: 256px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -335px;
  }
  .slider-wrapper__block-text.open-day .slider-wrapper__block-title {
    font-size: 45px;
    line-height: 49px;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    font-size: 88px;
    line-height: 80px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    height: 165px;
    margin: 132px 0 0 0;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -290px;
  }
  .slider-wrapper__block-text.open-day .slider-wrapper__block-title {
    font-size: 36px;
    line-height: 40px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    height: 300px;
    width: 100%;
    margin: -20px 0 0 18%;
  }
  .open-day__footer.text-block .slider-wrapper__block-title {
    font-size: 16px;
    line-height: 18px;
    max-width: 365px;
  }
  .open-day__footer.text-block.two {
    margin: 125px 0 0 75%;
  }
  .open-day__footer.text-block.two .slider-wrapper__block-button {
    margin-top: 15px;
    margin-left: 43%;
    height: 35px;
    font-size: 14px;
    line-height: 16px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day img {
    height: 100%;
    width: 250px;
    margin: -40px 15px 0 -175px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 90px 0 0 45%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 155px 0 0 40%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 205px 0 0 47%;
  }
  .slider-wrapper__block-text.student .student.text-block:first-child {
    padding: 45px 0 0 0;
  }
  .slider-wrapper__block-text.student .student__img {
    width: 140px;
    margin: 95px 0 0 40px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    font-size: 55px;
    line-height: 45px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day
    .slider-wrapper__block-title:first-child {
    padding-top: 20px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 55px;
    line-height: 60px;
    margin: 15px 0 0 160px;
    max-width: 390px;
  }
  .slider-wrapper__block-text-row {
    flex-direction: row;
  }
  .slider .slider-wrapper__block.two .slider-wrapper__block-subtitle {
    margin-left: 30px;
  }
  .slider-wrapper__block.magi .slider-wrapper__block-text .logo {
    height: 47px;
    width: 110px;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    margin: 50px 0 0 0;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(1) {
    font-size: 22px;
    line-height: 26px;
  }
  .slider-wrapper__block.magi
    .slider-wrapper__block-button
    .slider-wrapper__block-subtitle {
    font-size: 16px;
    line-height: 18px;
    padding: 10px 30px;
  }
  .slider-wrapper__block.magi .slider-wrapper__block-button {
    justify-content: space-between;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-subtitle {
    font-size: 16px;
    line-height: 18px;
    padding: 10px 15px;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-title {
    font-size: 12px;
    line-height: 14px;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-subtitle,
  .slider-wrapper__block.start
    .slider-wrapper__block-title-column
    .slider-wrapper__block-title {
    width: 225px;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-text.open-day__footer
    .img-one-slider {
    width: 330px;
    height: 100%;
    margin: -10px 0 0 55%;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title {
    font-size: 60px;
    line-height: 60px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    margin: -290px 0 0 305px;
  }
  .open-day__footer.text-block {
    margin: 3% 0 0 0;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-subtitle {
    font-size: 50px;
    line-height: 50px;
  }
  .spo .spo-logo,
  .manager .spo-logo {
    margin: -325px 0 0 35px;
  }
  .spo .spo-girls {
    height: 350px;
    width: 393px;
    right: 0%;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title.spo-button {
    margin-top: 5px;
  }
}

@media (max-width: 840px) {
  .slider-wrapper__block-link {
    width: 145px;
    height: 40px;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 18px;
    margin-right: 15px;
  }
  .slider-wrapper__block-text-button .slider-wrapper__block-link:nth-child(2) {
    width: 265px;
    margin-right: 15px;
  }
  .slider-wrapper__block-text-button .slider-wrapper__block-link:last-child {
    width: 169px;
  }
}

@media (max-width: 815px) {
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -54px 0 0 120%;
  }
}

@media (max-width: 795px) {
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    height: 310px;
    width: 100%;
    margin: -30px 0 0 18%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 90px 0 0 48%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 155px 0 0 42%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 205px 0 0 49%;
  }
}

@media (max-width: 770px) {
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    height: 155px;
    margin: 135px 0 0 0;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    font-size: 80px;
    line-height: 80px;
    margin: 25px 0 0 300px;
  }
  .slider-wrapper__block-text.open-day .slider-wrapper__block-title {
    font-size: 30px;
    line-height: 34px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -283px;
  }
  .open-day__footer.text-block.two {
    margin: 125px 0 0 60%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -25px 0 0 20%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -54px 0 0 114%;
  }
}

@media (max-width: 753px) {
  .slider-wrapper__block-text.student .slider-wrapper__block-title,
  .slider-wrapper__block-text.student .slider-wrapper__block-title.sub {
    font-size: 22px;
    line-height: 26px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    font-size: 14px;
    line-height: 16px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-button {
    max-width: 215px;
    font-size: 14px;
    line-height: 16px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    font-size: 55px;
    line-height: 45px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day
    .slider-wrapper__block-title:first-child {
    padding-top: 0px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 55px;
    line-height: 60px;
    margin: 20px 0 0 115px;
    max-width: 390px;
  }
}

@media (max-width: 744px) {
  .slider-wrapper__block-title {
    font-size: 44px;
    line-height: 50px;
  }
  .slider .slider-wrapper__block.three .slider-wrapper__block-title,
  .slider .slider-wrapper__block.four .slider-wrapper__block-title {
    font-size: 38px;
    line-height: 28px;
    max-width: 331px;
    width: 100%;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    margin-top: -296px;
    margin-left: -24px;
  }
  .slider-wrapper__block-text-row {
    display: flex;
    flex-direction: column-reverse;
  }
  .slider .slider-wrapper__block.two .slider-wrapper__block-subtitle {
    margin-left: 0;
  }
  .slider .slider-wrapper__block.one .slider-wrapper__block-title {
    max-width: 300px;
    width: 100%;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: 15px 0 0 300px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    left: 230px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 100px 0 0 48%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 155px 0 0 44%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 210px 0 0 42%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -30px 0 0 23%;
  }
  .slider-wrapper__block-text.student .student.text-block:first-child {
    padding: 50px 0 0 15px;
  }
  .slider-wrapper__block-text.student .student__img {
    margin: 80px 0 0 40px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title {
    padding-left: 40px;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    margin: 50px 0 0 15px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(2) {
    font-size: 46px;
    line-height: 56px;
    padding-bottom: 15px;
  }
  .slider-wrapper__block.magi .slider-wrapper__block-button {
    max-width: 300px;
  }
  .slider-wrapper__block.magi
    .slider-wrapper__block-text.open-day__footer
    .open-day__qr {
    height: 265px;
    width: 100%;
    margin: -15px 0 0 23%;
  }
  .slider-wrapper__block.start
    .slider-wrapper__block-text.open-day__footer
    .img-one-slider {
    width: 330px;
    height: 100%;
    margin: -30px 0 0 55%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -64px 0 0 110%;
  }
  .spo .open-day__footer.text-block {
    margin: 0 0 0 20px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title {
    max-width: 231px;
  }
  .manager .open-day__footer.text-block {
    margin: 25px 0 0 20px;
  }
}

@media (max-width: 710px) {
  .slider-wrapper__block-text.student .slider-wrapper__block-title,
  .slider-wrapper__block-text.student .slider-wrapper__block-title.sub {
    font-size: 18px;
    line-height: 22px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-button {
    max-width: 170px;
  }
  .slider-wrapper__block-text.student .student.text-block:last-child {
    margin: -15px 30px 0 0;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub,
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 50px;
    line-height: 45px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    max-width: 350px;
    padding: 10px 0;
  }
}

@media (max-width: 680px) {
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    height: 165px;
    margin: 123px 0 0 0;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: 15px 0 0 300px;
  }
  .slider-wrapper__block-text.open-day .slider-wrapper__block-title {
    font-size: 28px;
    line-height: 32px;
    max-width: 200px;
    width: 100%;
  }
  .open-day__footer.text-block.two {
    margin: 175px 0 0 90%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    height: 300px;
    width: 100%;
    margin: -35px 0 0 18%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    height: 20px;
    width: 100%;
    margin: 100px 0 0 -10%;
  }
  .open-day__footer.text-block .slider-wrapper__block-title {
    font-size: 13px;
    line-height: 18px;
    max-width: 250px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 150px 0 0 -5%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 210px 0 0 -6%;
  }
  img.holiday {
    display: none;
  }
  img.holiday-two {
    display: flex;
  }
  .slider-wrapper__block-text.student .student__img {
    margin: 80px 0 0 6px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(2) {
    font-size: 44px;
    line-height: 54px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title {
    max-width: 300px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(1) {
    font-size: 20px;
    line-height: 24px;
  }
  .slider-wrapper.three {
    display: flex;
  }
  .slider-wrapper.one {
    display: none;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title {
    font-size: 50px;
    line-height: 50px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-subtitle {
    font-size: 40px;
    line-height: 40px;
  }
  .open-day__footer.text-block {
    margin: 7% 0 0 0;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    margin: -270px 0 0 170px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-size: 20px;
    line-height: 30px;
    margin-top: 30px;
    max-width: 200px;
    padding-bottom: 5px;
  }
  .spo .spo-girls {
    right: -7%;
  }
}

@media (max-width: 650px) {
  .slider-wrapper__block.soglasie .billy {
    display: none;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -20px 0 0 50px;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 0 0 0 80%;
  }
}

@media (max-width: 635px) {
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    font-size: 13px;
    line-height: 14px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-button {
    font-size: 13px;
    line-height: 14px;
  }
  .slider-wrapper__block-text.student .student__img {
    margin: 105px 0 0 6px;
    width: 125px;
  }
  .slider-wrapper__block-text.student .student.text-block:last-child {
    padding-left: 0;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub,
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 50px;
    line-height: 45px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    max-width: 350px;
    padding: 7px 0;
    margin: 25px 0 0 120px;
  }
}

@media (max-width: 620px) {
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -330px;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: 10px 0 0 275px;
    font-size: 70px;
    line-height: 80px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    height: 150px;
    margin: 125px 0 0 0;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -283px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    height: 280px;
    width: 100%;
    margin: -30px 0 0 18%;
  }
  .open-day__footer.text-block.two {
    margin: 160px 0 0 78%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    display: none;
  }
  .slider-wrapper__block.magi
    .slider-wrapper__block-button
    .slider-wrapper__block-subtitle {
    font-size: 14px;
    line-height: 16px;
    padding: 10px 25px;
  }
  .slider-wrapper__block.magi .slider-wrapper__block-button,
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title {
    max-width: 250px;
  }
  .slider-wrapper__block.magi
    .slider-wrapper__block-text.open-day__footer
    .open-day__qr {
    height: 240px;
    width: 100%;
    margin: 5px 0 0 25%;
  }
  .slider-wrapper__block.start .start-img {
    display: none;
  }
  .slider-wrapper__block.start .start-two {
    display: flex;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -28px 0 0 30px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    margin: -250px 0 0 170px;
  }
  .spo .spo-girls {
    height: 346px;
    width: 385px;
    right: -10%;
  }
}

@media (max-width: 605px) {
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    height: 250px;
    width: 100%;
    margin: -15px 0 0 18%;
  }
}

@media (max-width: 590px) {
  .slider-wrapper__block-title {
    font-size: 30px;
    line-height: 33px;
  }
  .slider .slider-wrapper__block.one .slider-wrapper__block-title {
    max-width: 330px;
    width: 100%;
  }
  .slider .slider-wrapper__block.two .slider-wrapper__block-title {
    max-width: 330px;
    width: 100%;
  }
  .slider-wrapper__block-subtitle {
    font-size: 14px;
    line-height: 18px;
  }
  .slider .slider-wrapper__block.three .slider-wrapper__block-title,
  .slider .slider-wrapper__block.four .slider-wrapper__block-title {
    font-size: 30px;
    line-height: 29px;
  }
  .slider-wrapper__block-text-button .slider-wrapper__block-link:nth-child(2) {
    margin-bottom: 10px;
  }
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-title {
    font-size: 30px;
    line-height: 40px;
  }
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-subtitle {
    font-size: 14px;
    line-height: 18px;
    max-width: 373px;
    width: 100%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    transform: rotate(0deg);
    margin: -10px 0 0 18%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 0 0 0 74%;
  }
  .spo .spo-girls {
    right: -23%;
  }
  .spo .spo-logo,
  .manager .spo-logo {
    z-index: 2;
  }
}

@media (max-width: 565px) {
  .open-day__footer.text-block {
    margin: 55px 0 0 4%;
  }
  .open-day__footer.text-block.two {
    margin: 175px 0 0 69%;
  }
  img.holiday-two {
    display: none;
  }
  img.holiday-three {
    display: flex;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    padding-bottom: 5px;
    max-width: 200px;
    width: 100%;
  }
  .slider-wrapper__block-text.student .student__img {
    margin: 105px 0 0 210px;
    width: 125px;
    position: absolute;
    height: 149px;
    opacity: 80%;
  }
  .slider-wrapper__block-text.student .student.text-block {
    z-index: 999;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    margin: 30px 0 0 90px;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 0 0 0 70%;
  }
}

@media (max-width: 525px) {
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: 15px 0 0 275px;
    font-size: 65px;
    line-height: 75px;
  }
  .open-day__footer.text-block.two {
    margin: 120px 0 0 70%;
  }
  .open-day__footer.text-block.two .slider-wrapper__block-button {
    width: 120px;
    margin-top: 10px;
    margin-left: 43%;
    height: 35px;
    font-size: 10px;
    line-height: 12px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -30px 0 0 22%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    margin: 30px 0 0 80px;
  }
  .slider-wrapper__block.magi
    .slider-wrapper__block-text.open-day__footer
    .open-day__qr {
    display: none;
  }
  .slider-wrapper__block.magi .slider-wrapper__block-button,
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title {
    max-width: 100%;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    align-items: center;
    margin: 45px 0 0 11%;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(1) {
    font-size: 26px;
    line-height: 31px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(2) {
    font-size: 60px;
    line-height: 69px;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    font-size: 36px;
    line-height: 40px;
    margin: 0 0 0 65%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -10px 0 0 0;
  }
  .slider-wrapper__block.dod .logo-dod {
    display: none;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-subtitle {
    font-size: 30px;
    line-height: 30px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title {
    font-size: 40px;
    line-height: 40px;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    font-size: 70px;
    line-height: 70px;
    margin: -200px 0 0 140px;
  }
}

@media (max-width: 500px) {
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-initialized .slick-slide,
  .slick-slide img {
    height: 310px;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    margin-top: -270px;
    margin-left: -24px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -295px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    margin: 115px 0 0 0;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: 25px 0 0 265px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -245px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day img {
    height: 100%;
    width: 200px;
  }
  .slider-wrapper__block-text.open-day .slider-wrapper__block-title {
    font-size: 24px;
    line-height: 26px;
  }
  .open-day__footer.text-block.two {
    margin: 192px 0 0 -9%;
    flex-direction: row;
    width: 500px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -10px 0 0 22%;
    height: 230px;
  }
  .open-day__footer.text-block.two .slider-wrapper__block-button {
    margin-left: 25px;
    margin-top: 0;
  }
  .slider-wrapper__block-text.student .student__img {
    display: none;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    max-width: 260px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub,
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 40px;
    line-height: 35px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    max-width: 280px;
    padding: 7px 0;
    margin: 35px 0 0 80px;
  }
  .slider-wrapper__block.march-open-day
    .slider-wrapper__block-title:first-child {
    padding-top: 15px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(1) {
    font-size: 22px;
    line-height: 26px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(2) {
    font-size: 46px;
    line-height: 56px;
    padding-bottom: 15px;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    margin: 45px 0 0 8%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    font-size: 30px;
    line-height: 34px;
    margin: 20px 0 0 60%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -4px 0 0 15px;
  }
  .spo .spo-logo,
  .manager .spo-logo {
    margin: -290px 0 0 35px;
  }
  .spo .spo-girls {
    display: none;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-size: 26px;
    line-height: 36px;
    max-width: 100%;
    width: 400px;
    margin-top: 40px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title {
    max-width: 100%;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title.spo-button {
    padding: 10px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title.spo-fill {
    width: 350px;
  }
  .spo .open-day__footer.text-block {
    align-items: center;
    text-align: center;
    max-width: 87%;
    width: 100%;
  }
  .manager
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-size: 30px;
    line-height: 40px;
    max-width: 300px;
  }
}

@media (max-width: 460px) {
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-title {
    font-size: 26px;
    line-height: 30px;
  }
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-subtitle {
    max-width: 294px;
    width: 100%;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    font-size: 55px;
    line-height: 65px;
    margin: 25px 0 0 230px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -290px;
  }
  .slider-wrapper__block .slider-wrapper__block-text.open-day {
    margin-top: -245px;
  }
  .open-day__footer.text-block.two .slider-wrapper__block-button {
    margin: 0 0 0 5px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -5px 0 0 22%;
    height: 220px;
  }
  .slider-wrapper__block-text.student .slider-wrapper__block-title.sub {
    font-size: 14px;
    line-height: 16px;
  }
  .slider-wrapper__block-text.student
    .student.text-block:last-child
    .slider-wrapper__block-title.sub:nth-child(4),
  .slider-wrapper__block-text.student
    .student.text-block:last-child
    .slider-wrapper__student-text:last-child {
    display: none;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub,
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 40px;
    line-height: 35px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    max-width: 280px;
    padding: 7px 0;
    margin: 25px 0 0 70px;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 20px 0 0 55%;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    font-size: 60px;
    line-height: 60px;
    margin: -190px 0 0 140px;
    -webkit-text-stroke: 5px #ffffff;
    letter-spacing: 9px;
  }
}

@media (max-width: 440px) {
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -15px 0 0 25%;
    height: 185px;
    transform: rotate(-15deg);
  }
  img.holiday-three {
    display: none;
  }
  img.holiday-four {
    display: flex;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    margin: 30px 0 0 60px;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    margin: 45px 0 0 2%;
  }
  .slider-wrapper__block.start .start-two {
    display: none;
  }
  .slider-wrapper__block.start .start-three {
    display: flex;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 20px 0 0 50%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: -10px 0 0 15px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-size: 24px;
    line-height: 36px;
    margin-top: 45px;
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .slider-wrapper__block-subtitle {
    padding-bottom: 20px;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    margin-left: -45px;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    left: 55px;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    font-size: 50px;
    line-height: 60px;
    margin: 30px 0 0 220px;
  }
  .slider-wrapper__block-text.student .student.text-block:first-child {
    padding: 50px 0 0 0;
    position: absolute;
    max-width: 340px;
    width: 100%;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    max-width: 380px;
  }
  .slider-wrapper__block-text.student .student.text-block:last-child {
    margin: -14px 0 0 220px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title,
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub,
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    font-size: 35px;
    line-height: 35px;
    max-width: 100%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    max-width: 240px;
    margin: 30px 0 0 60px;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 20px 0 0 44%;
  }
  .slider-wrapper__block.dod .open-day__footer.text-block {
    margin-left: 3%;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 10px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-size: 21px;
    line-height: 26px;
    margin-top: 50px;
  }
}

@media (max-width: 383px) {
  .slider-wrapper__block.soglasie
    .slider-wrapper__block-text
    .slider-wrapper__block-title {
    padding-bottom: 15px;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    margin: 45px 0 0 -3%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 20px 0 0 40%;
  }
}

@media (max-width: 377px) {
  .slider .slider-wrapper__block.one .slider-wrapper__block-title {
    max-width: 325px;
    width: 100%;
  }
  .slider-wrapper__block .slider-wrapper__block-text {
    margin-top: -285px;
  }
  .slider-wrapper.one .slick-dots {
    bottom: 10px;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    font-size: 40px;
    line-height: 50px;
    margin: 48px 0 0 210px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__snake {
    height: 130px;
    margin: 125px 0 0 0;
  }
  .slider-wrapper__block-text.open-day__block-text
    .slider-wrapper__block-title {
    margin: 48px 0 0 195px;
  }
  .open-day__footer.text-block.two {
    margin: 201px 0 0 -9%;
  }
  .open-day__footer.text-block {
    margin: 90px 0 0 0px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -10px 0 0 25%;
  }
  .slider-wrapper__block-text.student .student.text-block:first-child {
    padding: 85px 0 0 0;
  }
  .slider-wrapper__block-text.student .student.text-block:last-child {
    margin: 20px 0 0 55%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 30px 0 0 40%;
  }
  .slider-wrapper__block.dod .slider-wrapper__block-title.red {
    margin: -235px 0 0 60px;
  }
  .spo
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    margin-top: 20%;
  }
  .manager
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-subtitle {
    font-size: 26px;
    line-height: 36px;
    margin-top: 10%;
  }
  .manager
    .slider-wrapper__block-text.open-day__footer
    .slider-wrapper__block-title {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 362px) {
  .open-day__footer.text-block .slider-wrapper__block-title {
    padding-bottom: 7px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -10px 0 0 23%;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    margin: 30px 0 0 50px;
  }
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title:nth-child(1) {
    font-size: 20px;
    line-height: 24px;
  }
  .slider-wrapper__block.magi .slider-wrapper__block-button,
  .slider-wrapper__block.magi
    .open-day__footer.text-block
    .slider-wrapper__block-title {
    max-width: 278px;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    margin: 45px 0 0 -7%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 30px 0 0 36%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title-column {
    margin: 5px 0 0 15px;
  }
}

@media (max-width: 345px) {
  .slider-wrapper__block-text.open-day__footer .open-day__qr {
    margin: -15px 0 0 15%;
    transform: rotate(-70deg);
  }
  .slider-wrapper__block-text.student .student.text-block:last-child {
    margin: -3px 0 0 50%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 30px 0 0 33%;
  }
}

@media (max-width: 330px) {
  .open-day__footer.text-block .slider-wrapper__block-title {
    max-width: 255px;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor {
    margin: 172px 0 0 117%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.two {
    margin: 205px 0 0 -18%;
  }
  .slider-wrapper__block-text.open-day__footer .open-day__cursor.three {
    margin: 240px 0 0 -13%;
  }
  .slider-wrapper__block-text.student .slider-wrapper__student-text {
    max-width: 300px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title {
    padding-left: 20px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__block-title.sub {
    padding-left: 50px;
  }
  .slider-wrapper__block.march-open-day .slider-wrapper__student-button {
    max-width: 240px;
    padding: 7px 0;
    margin: 30px 0 0 30px;
  }
  .slider-wrapper__block.magi .open-day__footer.text-block {
    margin: 45px 0 0 -12%;
  }
  .slider-wrapper__block.start .slider-wrapper__block-title {
    margin: 45px 0 0 23%;
  }
}
