@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.entrance-tests {
  display: flex;
  flex-direction: column;
}

.entrance-tests__column {
  width: 50%;
  display: flex;
}

.entrance-tests__table-title-text {
  font-family: "Gotham Pro";
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.entrance-tests .acceptance-checksum__table-title-block:nth-child(3),
.entrance-tests .acceptance-checksum__table-title-block.two {
  background: transparent;
}

.entrance-tests__column:last-child
  .entrance-tests__table-title-text:first-of-type {
  display: block;
  margin-right: 52px;
  width: 156px;
}

.entrance-tests .acceptance-checksum__table-title-block.one {
  background: #f6f6f6;
  border-radius: 20px;
}

.entrance-tests .acceptance-checksum__table-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.entrance-tests
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects,
.entrance-tests .acceptance-checksum__table-title {
  max-width: 100px;
  width: 100%;
}

.entrance-tests
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects,
.entrance-tests .acceptance-checksum__table-title.two {
  max-width: 200px;
  width: 100%;
}

.entrance-tests
  .acceptance-checksum__table-title-block.one
  .entrance-tests__column:first-of-type
  .acceptance-checksum__table-title,
.entrance-tests
  .acceptance-checksum__table-title-block.two
  .entrance-tests__column:first-of-type
  .acceptance-checksum__table-title {
  max-width: 430px;
  width: 100%;
}

.entrance-tests
  .entrance-tests__column:last-of-type
  .acceptance-checksum__table-title {
  text-align: center;
}

.entrance-tests
  .entrance-tests__column:not(:first-child)
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects {
  margin-right: 8px;
}
.entrance-tests
  .entrance-tests__column:last-of-type
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects:last-of-type {
  margin-bottom: 0;
}

.entrance-tests
  .entrance-tests__column:not(:first-child)
  .acceptance-checksum__table-title
  span.acceptance-checksum__subjects {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 0 !important;
  margin-right: 0;
}
.entrance-tests .acceptance-checksum__subjects {
  height: 40px;
  width: 40px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.acceptance-checksum__table-option {
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
  align-items: center;
  width: 100%;
  /* justify-content: space-between; */
}
.acceptance-checksum__table-option .acceptance-checksum__table-option-block {
  display: flex;
  flex-direction: row;
  max-width: 240px;
  width: 100%;
  align-items: center;
}
.acceptance-checksum__table-option
  .acceptance-checksum__table-option-block.two {
  max-width: 290px;
}
.acceptance-checksum__table-option div.acceptance-checksum__subjects {
  margin-right: 10px;
}
.acceptance-checksum__table-option span.acceptance-checksum__subjects {
  font-size: 10px;
  line-height: 9px;
  color: #ffffff;
}
.acceptance-checksum__table-option .acceptance-checksum__subjects {
  height: 30px;
  width: 30px !important;
}

.scroll-table.vi
  .acceptance-checksum__table-title-block
  .entrance-tests__column
  .acceptance-checksum__table-title.vi {
  max-width: 160px;
  width: 100%;
  justify-content: center;
}

/* Математика */
.entrance-tests .acceptance-checksum__subjects.math {
  background: #1282e9;
}
/* Русский язык */
.entrance-tests .acceptance-checksum__subjects.rus {
  background: #e912ba;
}
/* Физика */
.entrance-tests .acceptance-checksum__subjects.fiz {
  background: #8d20fb;
}
/* Информатика */
.entrance-tests .acceptance-checksum__subjects.inf {
  background: #13c54f;
}
/* Химия */
.entrance-tests .acceptance-checksum__subjects.him {
  background: #e95f12;
}
/* Биология */
.entrance-tests .acceptance-checksum__subjects.biol {
  background: #fbbe20;
}
/* Обществознание */
.entrance-tests .acceptance-checksum__subjects.obsh {
  background: #0b4295;
}
/* История */
.entrance-tests .acceptance-checksum__subjects.his {
  background: #e91212;
}
/* Иностранный язык */
.entrance-tests .acceptance-checksum__subjects.inos {
  background: #12e9cf;
}

/* География */
.entrance-tests .acceptance-checksum__subjects.geo {
  background: #6b8e23;
}
