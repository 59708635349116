@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.admission-after-school .question-content {
  max-width: 920px;
  width: 100%;
}

.admission-after-school__text.blue {
  font-family: "Gotham Pro";
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #1282e9;
}

.admission-after-school .question-content__item-text.title-blue {
  font-family: "Gotham ProMedium";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1282e9;
  text-decoration: none;
}

.admission-after-school a.question-content__item-text.title-blue {
  display: contents;
}

.admission-after-school
  .question-content__item-text
  .question-content__item-text.title-blue {
  font-family: "Gotham ProMedium";
  color: #1282e9;
  text-decoration: none;
}
