@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.acceptance-checksum {
  display: flex;
  flex-direction: column;
}

.acceptance-checksum__table-text-block,
.acceptance-checksum__table-title-block {
  display: flex;
  flex-direction: row;
}

.acceptance-checksum__table-title-block {
  background: #ededed;
  border-radius: 20px;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
}

.acceptance-checksum__table-text-block {
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.acceptance-checksum__table-text-block.two {
  background: #f6f6f6;
  border-radius: 20px;
  padding: 16px 32px;
}

.acceptance-checksum__table-title:first-of-type {
  display: block;
  max-width: 242px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.acceptance-checksum__table-title:not(:first-of-type) {
  display: block;
  max-width: 126px;
  width: 100%;
  text-align: center;
}

.acceptance-checksum__table-title:not(:last-of-type) {
  margin-right: 45px;
}

.acceptance-checksum__table-title-text {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.acceptance-checksum__table-text {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
  margin-right: 15px;
}

.acceptance-checksum__table-title a {
  font-family: "Gotham Pro";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1282e9;
  text-decoration: none;
}

.acceptance-checksum
  .acceptance-checksum__table-text-block:last-child
  .entrance-tests__column {
  /* width: 50%; */
  display: flex;
  max-width: 242px;
  width: 100%;
  margin-right: 45px;
}

@media (max-width: 1161px) {
  .acceptance-checksum__table-title:not(:last-of-type) {
    margin-right: 37px;
  }
}

@media (max-width: 1121px) {
  .acceptance-checksum__table-title:not(:last-of-type) {
    margin-right: 29px;
  }
}

@media (max-width: 1095px) {
  .acceptance-checksum__table-title:not(:last-of-type) {
    margin-right: 18px;
  }
}

@media (max-width: 1056px) {
  .acceptance-checksum__table-title:not(:last-of-type) {
    margin-right: 10px;
  }
}

@media (max-width: 1016px) {
  .scroll-table {
    max-width: 946px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
    display: block;
  }
  .scroll-table tr {
    width: 946px;
  }
}
