.step-prospective-student-menu.question
  .question-content
  .question-content__menu
  .question-content__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  background: #ededed;
  border-radius: 20px;
  padding: 24px 32px;
}
