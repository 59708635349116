@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.step-prospective-student {
  padding-top: 35px;
}

.step-prospective-student__top {
  display: flex;
  flex-direction: row;
}

.etap-postupleniya__title {
  font-family: Gotham ProMedium;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #1282e9;
  text-decoration: none;
  margin-bottom: 32px;
  padding-right: 40px;
}

.etap-postupleniya__title.two {
  opacity: 0.5;
}

.etap-postupleniya__title.two:hover {
  opacity: 1;
}

.etap-postupleniya__content-col {
  display: flex;
  flex-direction: column;
}

.etap-postupleniya__col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.etap-postupleniya__col:nth-child(1) {
  padding: 0 0 16px 0;
  border-bottom: 1px solid #ededed;
  height: 320px;
  max-width: 1173px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.etap-postupleniya__content-col .progress__item {
  cursor: pointer;
  background: #ededed;
  padding: 10px 25px;
  border-radius: 30px;
  margin: 0px 16px 10px 0;
}
.etap-postupleniya__content-col .progress__item.last:hover {
  color: #fff;
  background: #1282e9;
}
.etap-postupleniya__col .progress__item-title:hover {
  color: #fff;
}

.progress__item--active {
  color: #fff;
  background: #1282e9;
  padding: 10px 25px;
  border-radius: 30px;
  margin: 0px 16px 10px 0;
}

.progress__item--active .progress__item-title {
  color: #fff;
}

.etap-postupleniya__col .progress__item-title {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  /* margin: 0px 16px 16px 0;
  padding: 15px 25px;
  background: #ededed;
  border-radius: 30px; */
  text-decoration: none;
}

.etap-postupleniya__col .progress__item-title a {
  text-decoration: none;
}

.etap-postupleniya__col .etap-postupleniya__content-tab {
  display: none;
  flex-direction: column;
}

.etap-postupleniya__col .etap-postupleniya__content-tab--active {
  display: flex;
  width: 100%;
}

.br__prospective-student {
  display: none;
}
.abiturient-magistratura .etap-postupleniya__col.magistracy {
  height: 130px;
}

/* ---------- */
/* .step-procpective-student__padding {
  padding: 55px 0;
} */
.step-procpective-student__row {
  display: flex;
  flex-direction: row;
}
.step-procpective-student__column {
  display: flex;
  flex-direction: column;
}
.step-procpective-student__column-title {
  font-family: Gotham ProMedium;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
  padding-bottom: 40px;
}

.etap-postupleniya__text {
  width: 100%;
}
/* ---------- */

@media (max-width: 1081px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 380px;
  }
}

@media (max-width: 963px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 440px;
  }
}

@media (max-width: 901px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 480px;
  }
}

@media (max-width: 821px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 540px;
  }
}

@media (max-width: 785px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 480px;
  }
}

@media (max-width: 760px) {
  .etap-postupleniya__col .progress__item-title {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 752px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 520px;
  }
}

@media (max-width: 654px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 580px;
  }
}

@media (max-width: 630px) {
  .etap-postupleniya__col .progress__item-title {
    padding: 10px 5px;
  }
}

@media (max-width: 575px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 620px;
  }
}

@media (max-width: 535px) {
  .etap-postupleniya__col .progress__item-title {
    padding: 0;
  }
  .etap-postupleniya__content-col .progress__item {
    padding: 5px 10px;
  }
  .etap-postupleniya__col:nth-child(1) {
    height: 520px;
  }
}

@media (max-width: 457px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 680px;
  }
}

@media (max-width: 414px) {
  .etap-postupleniya__col .progress__item-title {
    font-size: 12px;
    line-height: 28px;
  }
  .etap-postupleniya__col:nth-child(1) {
    height: 680px;
  }
}

@media (max-width: 395px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 740px;
  }
}

@media (max-width: 383px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 740px;
  }
  .etap-postupleniya__content-col .progress__item {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 372px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 780px;
  }
}

@media (max-width: 335px) {
  .etap-postupleniya__col:nth-child(1) {
    height: 800px;
  }
}
