@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.list-of-documents__block {
  display: flex;
  flex-direction: column;
}

.list-of-documents__block-row {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  padding: 16px 32px;
}

.list-of-documents__block-row.one {
  background: rgb(237, 237, 237, 0.4);
}

.list-of-documents__block-row.two {
  background: transparent;
}

.list-of-documents__block-row .list-of-documents__block-title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  display: block;
  margin-right: 16px;
  max-width: 400px;
  width: 100%;
}

.list-of-documents__block-row .list-of-documents__block-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  display: flex;
  flex-direction: column;
}

.list-of-documents__block-row
  .list-of-documents__block-text
  .list-of-documents__block-subtext {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;

  display: block;
  padding-left: 19px;
}

@media (max-width: 967px) {
  .list-of-documents__block-row .list-of-documents__block-title {
    max-width: 300px;
    width: 100%;
  }
}

@media (max-width: 827px) {
  .list-of-documents__block-row .list-of-documents__block-title {
    max-width: 100%;
  }
  .list-of-documents__block-row .list-of-documents__block-title:nth-child(1) {
    padding-bottom: 20px;
  }
  .list-of-documents__block-row {
    display: flex;
    flex-direction: column;
  }
}

/* concurs-portfolio */
.concurs-portfolio .list-of-documents__block {
  display: flex;
  flex-direction: column;
}

.concurs-portfolio .list-of-documents__block.two {
  background: rgb(237, 237, 237, 0.4);
  border-radius: 30px;
  padding: 25px 25px 0 25px;
  margin: 30px 0 50px 0;
}

.concurs-portfolio .list-of-documents__block .list-of-documents__block-text {
  padding-bottom: 25px;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.concurs-portfolio .list-of-documents__block .list-of-documents__block-title {
  font-family: "Gotham ProMedium";
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  padding-bottom: 15px;
}

.concurs-portfolio .progress__item-title {
  color: #fff;
  background: #1282e9;
  display: inline-block; /*collision fix*/
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin: 0px 16px 16px 0;
  padding: 15px 25px;
  border-radius: 30px;
  text-decoration: none;
  cursor: pointer;
}

.concurs-portfolio .progress__item-title:hover {
  color: #fff;
  background: #2195FF;
}
