@media (max-width: 1095px) {
  .step-prospective-student__top.undergraduate-ICTE-page.educational-program.abiturient {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 15px;
  }
  .step-prospective-student__top.undergraduate-ICTE-page.educational-program.abiturient
    .etap-postupleniya__title {
    padding-right: 0;
    padding-bottom: 15px;
    margin-bottom: 0;
  }
}
