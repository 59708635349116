.entrance-tests.magistracy
  .entrance-tests__column:not(:first-child)
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects {
  color: #3e3e3e;
}

.entrance-tests.magistracy .acceptance-checksum__table-title-block.one {
  align-items: center;
}
