.magistracy.teacher.mcm .magpage_about_frame{
    background-image: url(../../../../assets/images/New/MagMCMTeacher/bg_image.svg);
    background-position: 71px 43px;
    padding: 0px 14px;
  }

.magistracy.teacher.mcm .info-block-frame2{
    background-image: url(../../../../assets/images/New/MagMCMTeacher/info_bg_image.svg);
    background-position: 0px 45px;
    background-repeat: no-repeat;
}   
.magistracy.teacher.mcm .info-block-frame3 .info_block-text-row{
    margin-bottom: 0px;
}
.magistracy.teacher.mcm  .info_block-title2{
    margin-top: 30px;
}