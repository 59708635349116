@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.step-prospective-student__top.undergraduate-ICTE-page {
  margin-top: 40px;
}

.educational-program__block {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.educational-program__block-title {
  font-family: "Gotham ProMedium";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #282828;
}

.educational-program__block-title.sub {
  font-family: "Gotham Pro";
}

.educational-program__block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1282e9;
  text-decoration: none;
}

.educational-program__main-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 80px;
  /* max-width: 1013px;
  width: 100%; */
}

.educational-program__main-block
  .individual-achivments__text-block:nth-child(4),
.educational-program__main-block
  .individual-achivments__text-block:nth-child(5) {
  max-width: 346px;
  width: 100%;
  /* margin-top: 56px; */
}

.educational-program__main-block
  .individual-achivments__text-block:nth-child(4)
  .individual-achivments__subtitle,
.educational-program__main-block
  .individual-achivments__text-block:nth-child(4)
  .individual-achivments__text,
.educational-program__main-block
  .individual-achivments__text-block:nth-child(5)
  .individual-achivments__subtitle,
.educational-program__main-block
  .individual-achivments__text-block:nth-child(5)
  .individual-achivments__text {
  max-width: 407px;
  width: 100%;
}

.educational-program__scroll-table {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.educational-program__scroll-table .educational-program__scroll-table-row {
  display: flex;
  flex-direction: row;
  padding: 16px 40px;
  justify-content: space-between;
  align-items: center;
}

.educational-program__scroll-table .educational-program__scroll-table-row.one {
  background: rgb(237, 237, 237, 0.4);
  border-radius: 20px;
}

.educational-program__scroll-table
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.one {
  max-width: 430px;
  width: 100%;
  margin-right: 15px;
}

.educational-program__scroll-table
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column:not(:first-child) {
  display: flex;
  text-align: center;
  justify-content: center;
  vertical-align: middle;
}

.educational-program__scroll-table
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.two {
  max-width: 123px;
  width: 100%;
}

.educational-program__scroll-table
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.three {
  max-width: 141px;
  width: 100%;
}

.educational-program__scroll-table
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.four {
  max-width: 127px;
  width: 100%;
}

.educational-program__scroll-table
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.five {
  max-width: 131px;
  width: 100%;
}

.educational-program__scroll-table.economy
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.two {
  max-width: 70px;
  width: 100%;
}

.educational-program__scroll-table.economy
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.three {
  max-width: 130px;
  width: 100%;
}

.educational-program__scroll-table.economy
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.four {
  max-width: 130px;
  width: 100%;
}

.educational-program__scroll-table.economy
  .educational-program__scroll-table-row
  .educational-program__scroll-table-column.five {
  max-width: 180px;
  width: 100%;
}

@media (max-width: 1095px) {
  .educational-program__scroll-table {
    max-width: 1021px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
    display: block;
  }
  .educational-program__scroll-table-row {
    width: 1021px;
  }
}

@media (max-width: 357px) {
  .educational-program__main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .educational-program__main-block
    .individual-achivments__text-block:nth-child(4) {
    max-width: 230px;
    width: 100%;
  }
}
