@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Gotham Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #3E3E3E;
}

.container {
  max-width: 1200px;
  width: 100%;
  position: relative;
  margin: 0 auto;
}

@media(max-width: 1440px){
  .container{
      padding: 0 20px;
  }
}
@media(max-width: 1095px){
  .container{
      padding: 0 35px;
  }
}
@media(max-width: 616px){
  .container{
      padding: 0 15px;
  }
}
