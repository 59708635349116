.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(3),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 260px;
  width: 100%;
  margin-right: 46px;
}
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(2),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__subtitle,
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__text {
  max-width: 140px;
  width: 100%;
}
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(4),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(4)
  .individual-achivments__subtitle {
  max-width: 360px;
  width: 100%;
  margin-right: 0;
}
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(1),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(1)
  .individual-achivments__subtitle {
  max-width: 160px;
  width: 100%;
  margin-right: 46px;
}

@media(max-width:735px){
    .educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(3),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 240px;
  width: 100%;
}
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(2),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__subtitle,
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__text {
  max-width: 110px;
  width: 100%;
}
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(4),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(4)
  .individual-achivments__subtitle {
  max-width: 330px;
  width: 100%;
}
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(5),
.educational-program__main-block.ivt.two
  .individual-achivments__text-block:nth-child(5)
  .individual-achivments__subtitle {
  max-width: 310px;
  width: 100%;
}
}