/* .educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
.educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__text,
.educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(3) {
  max-width: 185px !important;
  width: 100%;
} */

.educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(4) {
  margin-top: 0;
}
.educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(1) {
  max-width: 50%;
  width: 100%;
}

.educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(2) {
  max-width: 155px;
  width: 100%;
}

.educational-program__main-block.atom.two
  .individual-achivments__text-block:nth-child(1)
  .individual-achivments__subtitle,
.educational-program__main-block.atom.two
  .individual-achivments__text-block:nth-child(1)
  .individual-achivments__text,
.educational-program__main-block.atom.two
  .individual-achivments__text-block:nth-child(1), .educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
.educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__text,
.educational-program__main-block.atom
  .individual-achivments__text-block:nth-child(3) {
  /* max-width: 155px !important; */
  width: 155px !important;
}