@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.space {
  padding-left: 15px;
}

.grant-rectora__info-block,
.grant-rectora__steps-row {
  display: flex;
  flex-direction: row;
}

.grant-rectora__text-block {
  display: flex;
  flex-direction: column;
  margin-right: 140px;
  max-width: 661px;
  width: 100%;
}

.grant-rectora__text {
  display: block;
  max-width: 577px;
  width: 100%;
  margin-bottom: 32px;
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.grant-rectora__text.background {
  background: #1282e9;
  border-radius: 20px;
  padding: 16px 40px;
  margin-bottom: 56px;
  color: #ffffff;
  max-width: 661px;
  width: 100%;
}

.grant-rectora__steps {
  display: flex;
  flex-direction: column;
}

.grant-rectora__text.border {
  border: 1px solid #1282e9;
  border-radius: 30px;
  padding: 11px 26px;
  display: block;
  margin-right: 40px;
  max-width: 97px;
  width: 100%;

  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #1282e9;
}

.grant-rectora__steps-row {
  align-items: center;
}

.grant-rectora__steps-row:nth-child(2),
.grant-rectora__steps-row:nth-child(3) {
  align-items: flex-start;
}

.grant-rectora__image {
  display: block;
  position: absolute;
}

@media (max-width: 1095px) {
  .grant-rectora__image {
    display: none;
  }
  .grant-rectora__text-block {
    margin-right: 0;
    max-width: 100%;
  }
}

@media (max-width: 744px) {
  .grant-rectora__text {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 440px) {
  .grant-rectora__steps-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .grant-rectora__text.border {
    margin-bottom: 24px;
  }
}
