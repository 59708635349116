@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.special-admission-conditional__link.title {
  font-size: 22px;
  line-height: 33px;
}

.podrobnee{
  font-family: "Gotham ProMedium";
    font-weight: 400;
    font-size: 16px;
    color: #1282e9;
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid #1282e9;
    padding: 10px 20px;
}

.special-admission-conditional__text-block {
  margin-bottom: 35px;
}