.magistracy.teacher.iemc .teacher__main-block .teacher__main-block-text .teacher__title{
    max-width: 275px !important;
}

.magistracy.teacher.iemc .magpage_about_frame{
    padding: 0px 14px;
}

.magistracy.teacher.iemc .magpage_about_frame{
    background-image: url(../../../../assets/images/New/MagIEMCTeacher/bg_image.svg);
    background-position: 115px 53px;
}
.magistracy.teacher.iemc .info-block-frame2{
    background-image: url(../../../../assets/images/New/MagIEMCTeacher/info_bg_image.svg);
    background-position: 0px 65px;
    background-repeat: no-repeat;
}   
.magistracy.teacher.iemc .info-block-frame3 .info_block-text-row{
    margin-bottom: 0px;
}
