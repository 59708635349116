.burger {
  width: 60px;
  height: 50px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  display: none;
}

.burger__line {
  background: #3e3e3e;
  display: block;
  position: relative;
  transition: 0.3s all;
  transform-origin: top;
}

.burger__line:nth-child(1) {
  width: 40px;
  height: 2px;
  margin-bottom: 6px;
}

.burger__line:nth-child(2) {
  width: 32px;
  height: 2px;
}

.burger--active .burger__line:nth-child(1) {
  transform: rotateZ(45deg) translate(0px, 0px);
  width: 32px;
}

.burger--active .burger__line:nth-child(2) {
  transform: rotateZ(-47deg) translate(5px, -5px);
}

@media (max-width: 880px) {
  
}

@media (max-width: 650px) {
  
}
