.educational-program.teacher.ehcs
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.ehcs
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.ehcs .teacher_exams {
  background-image: url(../../../../assets/images/New/EHCSTeacher/bg_image.svg);
  background-position: 71px 43px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 35px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.ehcs .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.ehcs .teacher_exams {
    background-image: url(../../../../assets/images/New/EHCSTeacher/bg_image.svg);
    background-position: 100% 50%;
  }
}

@media (max-width: 750px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 95px;
  }
}

@media (max-width: 570px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 120px;
  }
}

@media (max-width: 472px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 160px;
  }
  .educational-program.teacher.ehcs .teacher__info-block-frame-large {
    height: 490px;
  }
}

@media (max-width: 441px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 200px;
  }
  .educational-program.teacher.ehcs .teacher__info-block-frame-large {
    height: 530px;
  }
}

@media (max-width: 411px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 150px;
  }
  .educational-program.teacher.ehcs .teacher__info-block-frame-large {
    height: 480px;
  }
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
}

@media (max-width: 383px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 190px;
  }
  .educational-program.teacher.ehcs .teacher__info-block-frame-large {
    height: 520px;
  }
}

@media (max-width: 330px) {
  .educational-program.teacher.ehcs
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 220px;
  }
  .educational-program.teacher.ehcs .teacher__info-block-frame-large {
    height: 550px;
  }
}
