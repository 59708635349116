@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.step-prospective-student__top:first-of-type {
  padding-top: 170px;
}

.step-prospective-student__top:first-of-type .etap-postupleniya__title {
  margin-bottom: 0;
  font-family: "Gotham ProMedium";
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  letter-spacing: 0.02em;
  color: #282828;
}

.step-prospective-student__top:last-child {
  padding-top: 40px;
}

.step-prospective-student__top.abiturient:last-child {
  padding-top: 170px;
}

.step-prospective-student__top:last-child .etap-postupleniya__title {
  margin-bottom: 0;
}

@media (max-width: 1005px) {
  .step-prospective-student__top.abiturient:last-child {
    padding-top: 120px;
  }
}

@media (max-width: 744px) {
  .step-prospective-student__top:first-of-type .etap-postupleniya__title {
    font-size: 44px;
    line-height: 48px;
  }
  .step-prospective-student__top:first-of-type {
    padding-top: 140px;
  }
  .etap-postupleniya__title {
    font-size: 20px;
    line-height: 30px;
  }
}
