.educational-program.teacher.bade
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.bade
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: 15px;
  right: 0;
}
.educational-program.teacher.bade
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: 15px;
  right: 0;
}

.educational-program.teacher.bade .teacher_exams {
  background-image: url(../../../../assets/images/New/BADETeacher/bg_image.svg);
  background-position: 85px 40px;
}
