.teacher__main-block.chich .teacher__main-block-img {
  margin-right: 65px;
}
.educational-program.teacher.tec
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.tec
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.tec .teacher_exams {
  background-image: url(../../../../assets/images/New/TECTeacher/bg_image.svg);
  background-position: 71px 43px;
}

@media (max-width: 1093px) {
  .teacher__main-block.chich .teacher__main-block-img {
    margin-right: 25px;
  }
}

@media (max-width: 1055px) {
  .educational-program.teacher.tec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 35px;
  }
}

@media (max-width: 1005px) {
  .teacher__main-block.chich .teacher__main-block-img {
    margin-right: 56px;
  }
  .educational-program.teacher.tec .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.tec .teacher_exams {
    background-image: url(../../../../assets/images/New/TECTeacher/bg_image.svg);
    background-position: 100% 50%;
  }
}

@media (max-width: 835px) {
  .teacher__main-block.chich .teacher__main-block-img {
    margin-right: 5px;
  }
  .teacher__main-block-text {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 750px) {
  .educational-program.teacher.tec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 90px;
  }
}

@media (max-width: 570px) {
  .educational-program.teacher.tec .teacher__info-block-frame-large {
    height: 420px;
  }
  .educational-program.teacher.tec
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
}

@media (max-width: 340px) {
  .educational-program.teacher.tec .teacher__info-block-frame-large {
    height: 460px;
  }
  .educational-program.teacher.tec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 130px;
  }
}
