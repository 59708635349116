@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.admission-after-college .question-content {
  max-width: 920px;
  width: 100%;
}

.admission-after-college .entrance-tests {
  margin-top: 60px;
}

.admission-after-college
  .entrance-tests
  .acceptance-checksum__table-title-block:nth-child(3) {
  display: flex;
  justify-content: center;
}

.admission-after-college
  .entrance-tests
  .acceptance-checksum__table-title-block:nth-child(3)
  .acceptance-checksum__table-title-text,
.admission-after-college
  .acceptance-checksum__table-title-block.three
  .acceptance-checksum__table-title-text {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.admission-after-college
  .entrance-tests
  .entrance-tests__column:not(:last-child) {
  margin-right: 35px;
}

.admission-after-college .entrance-tests .entrance-tests__column:nth-child(1) {
  max-width: 385px;
  width: 100%;
}

.admission-after-college .entrance-tests .entrance-tests__column:nth-child(2) {
  max-width: 190px;
  width: 100%;
}

.admission-after-college
  .entrance-tests
  .entrance-tests__column:nth-child(2)
  .acceptance-checksum__table-title {
  max-width: 100%;
}

.admission-after-college .entrance-tests .entrance-tests__column:nth-child(3) {
  max-width: 107px;
  width: 100%;
}

.admission-after-college .entrance-tests .entrance-tests__column:nth-child(4) {
  max-width: 183px;
  width: 100%;
  align-items: center;
}

.admission-after-college
  .entrance-tests
  .entrance-tests__column:nth-child(4)
  .acceptance-checksum__table-text {
  text-align: left;
}

.admission-after-college .acceptance-checksum__table-title-block.three {
  background: #f6f6f6;
  border-radius: 20px;

  display: flex;
  justify-content: center;
}

.admission-after-college .entrance-tests__column.finish {
  margin-top: 60px;
}

.admission-after-college
  .question-content__item-text
  .question-content__item-text.title-blue {
  font-family: "Gotham ProMedium";
  display: initial;
  color: #1282e9;
  text-decoration: none;
}

.admission-after-college
  .question-content__menu:nth-child(3)
  .question-content__item-text
  .question-content__item-text.title-blue,
.admission-after-college
  .question-content__menu:nth-child(4)
  .question-content__item-text
  .question-content__item-text.title-blue {
  padding-top: 0;
}

.acceptance-checksum__table-title-block.title {
  background: transparent;
  display: flex;
  justify-content: center;
}

.acceptance-checksum__table-title-block.title
  .acceptance-checksum__table-title-text {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}
