.scroll-table.facultets
  .acceptance-checksum__table-title-block.undegraduate-ICTE-info-two {
  background: #f6f6f6;
  border-radius: 20px;
  padding: 16px 32px;
}

@media (max-width: 1167px) {
  .scroll-table.facultets .entrance-tests__column:last-child {
    display: flex;
    flex-direction: column;
  }
  .scroll-table.facultets
    .entrance-tests__column:last-child
    .acceptance-checksum__table-title:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .undegraduate-ICTE-info
    .scroll-table.facultets
    .entrance-tests__column:last-child,
  .undegraduate-ICTE-info
    .scroll-table.facultets
    .acceptance-checksum__table-title-block
    .entrance-tests__column:last-child {
    max-width: 185px;
    width: 100%;
  }
}

@media (max-width: 1095px) {
  .undegraduate-ICTE-info
    .scroll-table.facultets
    .entrance-tests__column:first-of-type,
  .undegraduate-ICTE-info
    .scroll-table.facultets
    .acceptance-checksum__table-title-block
    .entrance-tests__column:first-of-type,
  .undegraduate-ICTE-info
    .scroll-table.facultets
    .acceptance-checksum__table-title-block
    .entrance-tests__column:first-of-type
    .acceptance-checksum__table-title {
    max-width: 180px;
    width: 100%;
    margin-right: 12px;
  }
  .scroll-table.facultets {
    max-width: 1021px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
    display: block;
  }
  .scroll-table tr {
    width: 1021px;
  }
}
