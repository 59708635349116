@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.undergraduate .step-prospective-student__top.undergraduate {
  padding-top: 170px;
}

.undergraduate .etap-postupleniya__col{
    padding: 0 0 16px 0;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
