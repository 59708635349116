.educational-program.teacher.design .teacher__info-block-frame-large .wrap img:first-child {
    margin-left: -28px;
    margin-top: -37px;
    border-radius: 20px 0 0 0 ;
  }


.educational-program.teacher.design .teacher__info-block-frame-large .wrap img:last-child {
    margin-right: -28px;
    margin-top: 15px;
    right: 0;
    border: 1px ;
    border-radius: 0 0 20px 0;
  }

.educational-program.teacher.design .teacher_exams {
    background-image: url(../../../../assets/images/New/DesignTeacher/bg_image.svg);
    background-position: 86px 56px;
    
}
