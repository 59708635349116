.educational-program.teacher.soc .teacher__info-block-frame-large .wrap img:first-child {
    margin-left: -28px;
    margin-top: -37px;
    border-radius: 20px 0 0 0 ;
  }


.educational-program.teacher.soc .teacher__info-block-frame-large .wrap img:last-child {
    margin-right: -28px;
    margin-top: 15px;
    right: 0;
    border: 1px ;
    border-radius: 0 0 20px 0;
  }

.educational-program.teacher.soc .teacher_exams {
    background-image: url(../../../../assets/images/New/SocTeacher/bg_image.svg);
    background-position: 90px 40px;
}

.educational-program.teacher.soc .teacher__info-block-frame-large .frame-large-text-row.last .info_block-text-row:first-child {
    max-width: 300px;
    width: 100%;
    margin-right: 8px;
}

.educational-program.teacher.soc .teacher__info-block-frame-large .frame-large-text-row.last {
    justify-content: unset;
}