@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.passing-points .list-of-documents__block-row {
  display: flex;
  justify-content: space-between;
}

.passing-points .list-of-documents__block-row:nth-child(1) {
  background: #ededed;
  border-radius: 20px;
  padding: 20px 32px;
}

.passing-points
  .list-of-documents__block-row:nth-child(1)
  .list-of-documents__block-text {
  font-family: "Gotham ProMedium";
}

.passing-points .list-of-documents__block-row .list-of-documents__block-text {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 100px;
  width: 100%;
}

.passing-points .list-of-documents__block-row .list-of-documents__block-title {
  max-width: 600px;
  width: 100%;
}
