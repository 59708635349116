.calendar.magistracy .calendar__col:nth-child(1) {
  height: 110px;
}

@media (max-width: 1190px) {
  .calendar.magistracy .calendar__data {
    max-width: 330px;
  }
}

@media (max-width: 1160px) {
  .calendar.magistracy .calendar__data {
    max-width: 325px;
  }
}

@media (max-width: 1144px) {
  .calendar.magistracy .calendar__data {
    max-width: 310px;
  }
}

@media (max-width: 1099px) {
  .calendar.magistracy .calendar__data {
    max-width: 300px;
  }
}

@media (max-width: 1059px) {
  .calendar.magistracy .calendar__data {
    max-width: 280px;
  }
}

@media (max-width: 999px) {
  .calendar.magistracy .calendar__data {
    max-width: 390px;
    height: 310px;
  }
  .calendar.magistracy .calendar__data-block {
    justify-content: space-between;
  }
}

@media (max-width: 910px) {
  .calendar.magistracy .calendar__data {
    max-width: 350px;
  }
}

/* @media (max-width: 899px) {
  .calendar__data {
    max-width: 320px;
  }
} */

@media (max-width: 878px) {
  .calendar.magistracy .calendar__content-row .calendar__title {
    display: none;
  }
}

@media (max-width: 839px) {
  .calendar.magistracy .calendar__data {
    max-width: 336px;
  }
  .calendar.magistracy .calendar__data {
    margin: 0 24px 24px 0;
  }
  .calendar.magistracy .calendar__data:nth-child(2),
  .calendar.magistracy .calendar__data:nth-child(4),
  .calendar.magistracy .calendar__data:nth-child(6),
  .calendar.magistracy .calendar__data:nth-child(8) {
    margin-right: 0;
  }
}

@media (max-width: 801px) {
  .calendar.magistracy .calendar__data {
    height: 283px;
    height: 320px;
  }
}

@media (max-width: 765px) {
  .calendar.magistracy .calendar__data {
    max-width: 320px;
  }
}

@media (max-width: 744px) {
  .calendar.magistracy .calendar__col .calendar__item-title {
    font-size: 18px;
    line-height: 27px;
    margin-right: 24px;
  }
  .calendar.magistracy .calendar__col:nth-child(1) {
    height: 66px;
  }
  .calendar.magistracy .calendar__data-title {
    font-size: 18px;
    line-height: 27px;
  }
  .calendar.magistracy .calendar__data-text {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (max-width: 733px) {
  .calendar.magistracy .calendar__data {
    max-width: 300px;
  }
}

@media (max-width: 693px) {
  .calendar.magistracy .calendar__data {
    max-width: 280px;
  }
}

@media (max-width: 653px) {
  .calendar.magistracy .calendar__data {
    max-width: 260px;
  }
}

@media (max-width: 573px) {
  .calendar.magistracy .calendar__data {
    max-width: 543px;
    width: 100%;
    min-height: 136px;
    height: 100%;
    margin-right: 0;
  }
}


@media (max-width: 337px) {
  .calendar.magistracy .calendar__col .calendar__item-title {
    font-size: 16px;
    line-height: 25px;
    margin-right: 24px;
  }
}
