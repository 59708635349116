@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.energy-levels__images-block {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  max-height: 402px;
  height: 100%;
}

.energy-levels .scroll-table {
  margin-bottom: 80px;
}

.energy-levels__info-block-column {
  display: flex;
  flex-direction: column;
}

.energy-levels__info-block,
.energy-levels__info-block-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.energy-levels__image {
  max-width: 387px;
  width: 100%;
}

.energy-levels__image:first-of-type {
  border-radius: 20px 0 0 20px;
}

.energy-levels__image:last-of-type {
  border-radius: 0 20px 20px 0;
}

.energy-levels__info-block-column {
  width: 30%;
  margin-bottom: 40px;
}

.energy-levels__info-block-column:not(:last-of-type) {
  margin-right: 30px;
}

.energy-levels__info-block-image {
  max-height: 104px;
  height: 100%;
  max-width: 104px;
  width: 100%;
  margin-bottom: 32px;
}

.energy-levels__info-block-text {
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.energy-levels__info-block-text.one {
  display: block;
  margin-bottom: 24px;
}

@media (max-width: 1195px) {
  .energy-levels__image {
    max-width: 315px;
    width: 100%;
  }
}

@media (max-width: 969px) {
  .energy-levels__info-block-text {
    font-size: 14px;
    line-height: 22px;
  }
  .energy-levels__info-block-image {
    max-height: 85px;
    max-width: 85px;
  }
  .energy-levels__info-block-column {
    width: 45%;
  }
  .energy-levels__info-block .energy-levels__info-block-column:nth-child(1),
  .energy-levels__info-block .energy-levels__info-block-column:nth-child(3),
  .energy-levels__info-block .energy-levels__info-block-column:nth-child(5) {
    margin-right: 20px;
  }
}

@media (max-width: 669px) {
  .energy-levels__info-block-column {
    width: 40%;
  }
}

@media (max-width: 511px) {
  .energy-levels__info-block-column {
    width: 100%;
  }
}
