.footer__top {
  margin-bottom: 30px;
}

.footer__br {
  display: none;
}

.footer__top .logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  margin-bottom: 24px;
}

.footer__top .logo__link {
  margin-right: 65px;
}

.footer__top .logo__text {
  width: 200px;
}

.logo__string {
  height: 1px;
  width: 100%;
  background-color: #ededed;
}

.footer__main,
.footer__main-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__main-two {
  display: none;
}

.footer__text-column {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.footer__link-block {
  display: flex;
  flex-direction: row;
}

.footer__title {
  font-size: 12px;
  line-height: 17px;
  color: #282828;
  padding-bottom: 8px;

  letter-spacing: 0.02em;
}

.footer__text,
.footer__text-link {
  font-size: 10px;
  line-height: 14px;
  color: #282828;

  letter-spacing: 0.02em;
}

.footer__text-link {
  text-decoration: underline;
}

.footer__link {
  background: #ededed;
  border-radius: 8px;
  margin: 0px 16px;
  padding: 6px;
  height: 30px;
  display: inline-block;
}

.footer__link-img {
  transition: all 0.3s ease;
}

.footer__link:hover img {
  opacity: 0;
}

.footer__link.one:hover {
  background: url(../../assets/images/WhattsAppHover.svg) no-repeat;
  background-color: #2195ff;
  background-position: center;
}

.footer__link.two:hover {
  background: url(../../assets/images/MailHover.svg) no-repeat;
  background-color: #2195ff;
  background-position: center;
}

.footer__link.three:hover {
  background: url(../../assets/images/VkHover.svg) no-repeat;
  background-color: #2195ff;
  background-position: center;
}

.footer__link.four:hover {
  background: url(../../assets/images/TelegramHover.svg) no-repeat;
  background-color: #2195ff;
  background-position: center;
}

.footer__link.five:hover {
  background: url(../../assets/images/bot12.svg) no-repeat;
  background-color: #2195ff;
  background-position: center;
}
 
@media (max-width: 1075px) {
  .footer__title {
    font-size: 10px;
    line-height: 14px;
  }
  .footer__link-img {
    height: 12px;
    width: 12px;
  }
  .footer__link {
    margin: 0 15px 0 0;
    padding: 5px;
    height: 22px;
    display: flex;
  }
}

@media (max-width: 833px) {
  .footer__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 760px;
    width: 100%;
  }
  .footer__link-block {
    margin-top: 20px;
  }
}

@media (max-width: 684px) {
  .footer__main {
    display: none;
  }
  .footer__main-two {
    display: flex;
    flex-direction: column;
  }
  .footer__text-column {
    margin-bottom: 15px;
  }
  .footer__main-column:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .footer__main-column.down .footer__text-column,
  .footer__main-column.down .footer__link-block {
    margin: 0;
  }
} 
