@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.calendar__content-row,
.calendar__data-row {
  display: flex;
  flex-direction: row;
}

.calendar__content-row {
  margin-bottom: 30px;
}

.calendar__content-row .calendar__title {
  display: block;
  margin-right: 58px;
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
  max-width: 198px;
  width: 100%;
}

.calendar__title.two {
  opacity: 0.5;
}

.calendar__title.two:hover {
  opacity: 1;
}

.calendar__content-col,
.calendar__data {
  display: flex;
  flex-direction: column;
}

.calendar__data-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calendar__col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.calendar__col:nth-child(1) {
  height: 176px;
  display: flex;
  justify-content: flex-start;
}

.calendar__content-col .calendar__item {
  cursor: pointer;
}

.calendar__col .calendar__item-title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #1282e9;
  opacity: 0.5;
  margin-right: 58px;
}

.calendar__item--active .calendar__item-title {
  opacity: 1;
}

.calendar__col .calendar__item-title:hover {
  color: #1282e9;
  opacity: 1;
}

.calendar__col .calendar__content-tab {
  display: none;
  flex-direction: column;
}

.calendar__col .calendar__content-tab--active {
  display: flex;
  width: 100%;
}

.calendar__data {
  background: rgb(237, 237, 237, 0.4);
  border-radius: 20px;
  max-width: 340px;
  width: 100%;
  height: 310px;
  margin: 0 30px 30px 0;
}

.calendar__data-title {
  display: block;
  background: #ededed;
  border-radius: 20px;
  padding: 16px 32px;
  margin-bottom: 24px;

  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #1282e9;
}

.calendar__data-text {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
  padding: 0 32px 32px 32px;
}

@media (max-width: 1190px) {
  .calendar__data {
    max-width: 330px;
  }
}

@media (max-width: 1160px) {
  .calendar__data {
    max-width: 325px;
  }
}
/* 
@media (max-width: 1144px) {
  .calendar__data {
    max-width: 310px;
  }
} */

@media (max-width: 1104px) {
  .calendar__data {
    max-width: 310px;
  }
}

@media (max-width: 1095px) {
  .calendar__data {
    max-width: 300px;
  }
}

@media (max-width: 1059px) {
  .calendar__data {
    max-width: 280px;
  }
}

@media (max-width: 999px) {
  .calendar__data {
    max-width: 390px;
    height: 310px;
  }
  .calendar__data-block {
    justify-content: space-between;
  }
}

@media (max-width: 910px) {
  .calendar__data {
    max-width: 350px;
  }
}

/* @media (max-width: 899px) {
  .calendar__data {
    max-width: 320px;
  }
} */

@media (max-width: 878px) {
  .calendar__content-row .calendar__title {
    display: none;
  }
}

@media (max-width: 839px) {
  .calendar__data {
    max-width: 336px;
  }
  .calendar__data {
    margin: 0 24px 24px 0;
  }
  .calendar__data:nth-child(2),
  .calendar__data:nth-child(4),
  .calendar__data:nth-child(6),
  .calendar__data:nth-child(8) {
    margin-right: 0;
  }
}

@media (max-width: 801px) {
  .calendar__data {
    height: 283px;
    height: 320px;
  }
}

@media (max-width: 765px) {
  .calendar__data {
    max-width: 320px;
  }
}

@media (max-width: 744px) {
  .calendar__col .calendar__item-title {
    font-size: 18px;
    line-height: 27px;
    margin-right: 24px;
  }
  .calendar__col:nth-child(1) {
    height: 105px;
  }
  .individual-achivments__title {
    padding-bottom: 32px;
  }
  .calendar__data-title {
    font-size: 18px;
    line-height: 27px;
  }
  .calendar__data-text {
    font-size: 14px;
    line-height: 21px;
  }
}

@media (max-width: 733px) {
  .calendar__data {
    max-width: 300px;
  }
}

@media (max-width: 693px) {
  .calendar__data {
    max-width: 280px;
  }
  .calendar__col:nth-child(1) {
    height: 140px;
  }
}


@media (max-width: 653px) {
  .calendar__data {
    max-width: 260px;
  }
}

@media (max-width: 573px) {
  .calendar__data {
    max-width: 543px;
    width: 100%;
    min-height: 136px;
    height: 100%;
    margin-right: 0;
  }
}

@media (max-width: 538px) {
  .step-prospective-student__top:last-child {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
  }
  .step-prospective-student__top:last-child .etap-postupleniya__title.one {
    padding-bottom: 4px;
    padding-right: 0;
  }
}

@media (max-width: 473px) {
  .calendar__col:nth-child(1) {
    height: 170px;
  }
}

@media (max-width: 383px) {
  .etap-postupleniya__col .progress__item-title {
    padding: 15px 15px 13px;
  }
}

@media (max-width: 375px) {
  .step-prospective-student__top:first-of-type .etap-postupleniya__title {
    font-size: 30px;
    line-height: 33px;
  }
  .etap-postupleniya__title {
    font-size: 18px;
    line-height: 27px;
  }
}

@media (max-width: 337px) {
  .calendar__col .calendar__item-title {
    font-size: 16px;
    line-height: 25px;
    margin-right: 24px;
  }
}
