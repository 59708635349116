.categories-pf-applicants .etap-postupleniya__col:nth-child(1) {
  height: 128px;
}

@media (max-width: 823px) {
  .categories-pf-applicants .etap-postupleniya__col:nth-child(1) {
    height: 178px;
  }
}

@media (max-width: 744px) {
  .categories-pf-applicants
    .step-prospective-student__top:first-of-type
    .etap-postupleniya__title {
    font-size: 34px;
    line-height: 33px;
  }
}

@media (max-width: 621px) {
  .categories-pf-applicants .etap-postupleniya__col:nth-child(1) {
    height: 230px;
  }
}

@media (max-width: 616px) {
  .categories-pf-applicants .etap-postupleniya__col:nth-child(1) {
    height: 178px;
  }
}

@media (max-width: 581px) {
  .categories-pf-applicants .etap-postupleniya__col:nth-child(1) {
    height: 230px;
  }
}

@media (max-width: 513px) {
  .categories-pf-applicants .etap-postupleniya__col:nth-child(1) {
    height: 280px;
  }
}

@media (max-width: 375px) {
    .categories-pf-applicants
      .step-prospective-student__top:first-of-type
      .etap-postupleniya__title {
      font-size: 24px;
      line-height: 23px;
    }
}
