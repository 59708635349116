@font-face {
  font-family: "Gotham Pro Medium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.top-bar {
  max-height: 84px;
  height: 100%;
  padding: 14px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  background-color: #fff;
}

.top-bar .container.null {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.logo {
  margin-right: 15px;
}

.logo__link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}

.logo__img {
  max-width: 56px;
  width: 100%;
  max-height: 56px;
  height: 100%;
  margin-right: 10px;
}

.logo__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo__text-title {
  font-family: "Gotham Pro Medium";
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 5px;
}

.logo__text-subtitle {
  font-size: 8px;
  line-height: 10px;
}

.top-bar__navbar .top-bar__topmenu {
  display: flex;
  flex-direction: row;
  list-style: none;
  width: 100%;
  justify-content: center;
}

.top-bar__submenu {
  display: flex;
  flex-direction: column;
}

.top-bar__topmenu > .top-bar__menu {
  position: relative;
}

.top-bar__navbar .top-bar__submenu .top-bar__submenu-link {
  padding-bottom: 10px;
  list-style: none;
}

.top-bar__navbar .top-bar__submenu .top-bar__submenu-link:last-child {
  padding-bottom: 0;
}

.top-bar__menu {
  margin-right: 40px;
}

.top-bar__menu:last-child {
  margin-right: 0;
}

.top-bar__menu a,
.top-bar__submenu a {
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  letter-spacing: 0.02em;
}

.top-bar__menu a:hover,
.top-bar__menu .blue-span:hover {
  color: #2195FF;
}

.top-bar__menu a:active,
.top-bar__menu .blue-span:active {
  color: #1277D4;
}

.top-bar__menu .blue-span {
  color: #1282e9;
}

.top-bar__submenu {
  text-decoration: none;
  display: block;
  transition: 0.4s ease-in-out;
  background: #ededed;
  border-radius: 10px;
  padding: 16px;
  width: 260px;
  margin-top: 10px;
  position: absolute;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.8);
}

.top-bar__submenu.one {
  width: 220px;
}

.top-bar__submenu a {
  color: #3e3e3e;
}

.top-bar__menu .top-bar__submenu a:hover {
  color: #1282e9;
}

.top-bar__topmenu > .top-bar__menu:after,
.top-bar__topmenu > .top-bar__submenu-link:after {
  position: absolute;
  transform: rotate(30deg);
}

.top-bar__topmenu > .top-bar__menu:hover .top-bar__submenu {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}



.top-bar__topmenu .top-bar__menu .lk {
  border: 1px solid #1282e9;
  border-radius: 10px;
  padding: 5px 10px;
  color: #1282e9;
}

.top-bar__topmenu .top-bar__menu .lk:hover {
  background-color: #1282e9;
  color: #fff;
}

.top-bar__topmenu .top-bar__menu .lk:active {
  background-color: #1277D4;
  color: #fff;
}


@media (max-width: 1095px) {
  .top-bar__menu {
    margin-right: 10px;
  }
}

@media (max-width: 1005px) {
  nav.top-bar__navbar {
    margin-right: 0;
    margin-left: auto;
  }
  .burger {
    display: flex;
  }
  .top-bar__navbar .top-bar__topmenu {
    display: none;
  }
  .top-bar {
    max-height: 116px;
  }
}

@media (max-width: 640px) {
  /* .top-bar {
    max-width: 640px;
    width: 100%;
  } */
  .logo__img {
    max-width: 40px;
    width: 100%;
    max-height: 40px;
    height: 100%;
  }
  .logo__text-title {
    font-size: 10px;
    line-height: 9px;
  }
  .logo__text-subtitle {
    font-size: 5px;
    line-height: 6px;
  }
  nav.top-bar__navbar {
    margin-left: 0;
  }
  /* .container {
    max-width: 625px;
    margin: 0;
  } */
}

@media (max-width: 375px) {
}
