@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);

  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.category-abiturientov {
  background: url(../../../assets/images/MainPage/CategoryAbiturientov.png)
    no-repeat;
  background-size: cover;
  height: 320px;
  margin: 0 0 70px 0;
  padding: 56px 64px;
  border-radius: 30px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.category-abiturientov.two {
  display: none;
}

.category-abiturientov__img {
  display: flex;
  padding-top: 3px;
  position: absolute;
  right: 30px;
  top: 7%;
}

.category-abiturientov__title {
  font-family: "Gotham ProMedium";
  position: absolute;
  font-weight: 700;
  font-size: 38px;
  line-height: 36px;
  color: #fcfcfc;

  letter-spacing: 0.02em;
}

.category-abiturientov__text-block {
  position: absolute;
  padding-top: 70px;
  margin-left: -26px;
  display: flex;
  flex-direction: row;
}

.category-abiturientov__text-column {
  display: flex;
  flex-direction: column;
}

.category-abiturientov__text-column:nth-child(1) {
  margin-right: 40px;
}

.category-abiturientov__text {
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
  padding: 11px 26px;

  letter-spacing: 0.02em;
}

.category-abiturientov__text:last-child {
  margin-bottom: 0;
}

/* ------------------ */

.category-abiturientov__text {
  transition: all 0.5s;
  position: relative;
}
.category-abiturientov__text:hover::before {
  opacity: 0;
  transform: scale(0.3, 0.3);
}
.category-abiturientov__text::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid #fff;
  transform: scale(0.8, 0.7);

  border-radius: 30px;
}
.category-abiturientov__text.one::after {
  max-width: 280px;
  width: 100%;
}
.category-abiturientov__text.two::after {
  max-width: 230px;
  width: 100%;
}
.category-abiturientov__text.three::after {
  max-width: 300px;
  width: 100%;
}
.category-abiturientov__text:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

/* ------------------ */

@media (max-width: 1155px) {
  .category-abiturientov__img img {
    height: 230px;
    width: 100%;
  }
  .category-abiturientov__img {
    top: 17%;
  }
}
@media (max-width: 1095px) {
  .category-abiturientov__text-column:nth-child(1) {
    margin-right: 0;
  }
  .category-abiturientov {
    padding: 46px;
  }
}

@media (max-width: 1040px) {
  .category-abiturientov.one {
    display: none;
  }
  .category-abiturientov.two {
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }
}

@media (max-width: 1005px) {
  .category-abiturientov__img {
    display: none;
  }
  .category-abiturientov__text-column:nth-child(1) {
    margin-right: 80px;
  }
}

@media (max-width: 783px) {
  .category-abiturientov__text-column:nth-child(1) {
    margin-right: 20px;
  }
}

@media (max-width: 744px) {
  .category-abiturientov__title {
    font-size: 34px;
    line-height: 32px;
  }
  .category-abiturientov__text {
    font-size: 14px;
    line-height: 13px;
    padding: 16px 32px;
    border: 1px solid #ffffff;
    border-radius: 30px;
    margin-right: 40px;
  }
}

@media (max-width: 703px) {
  .category-abiturientov__text {
    margin-right: 20px;
  }
}

@media (max-width: 680px) {
  .category-abiturientov {
    margin: 0 0 80px 0;
  }
}

@media (max-width: 662px) {
  .category-abiturientov__text {
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .category-abiturientov__text-block {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    width: 100%;
  }
  .category-abiturientov {
    height: 505px;
    text-align: center;
    align-items: center;
  }
  .category-abiturientov__text-column:nth-child(1) {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .category-abiturientov__text {
    margin-right: 0;
  }
  .category-abiturientov__text::after {
    content: none;
  }
  .category-abiturientov__text {
    max-width: 100%;
    margin: 0 10px 15px 15px;
  }
  .category-abiturientov__text-column:nth-child(2) {
    margin-right: 0;
  }
}

@media (max-width: 500px) {
  .category-abiturientov__title {
    font-size: 24px;
    line-height: 23px;
  }
}

@media (max-width: 360px) {
  .category-abiturientov__title {
    max-width: 200px;
    width: 100%;
  }
}
