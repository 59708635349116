@font-face {
  font-family: "Gotham Pro Medium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.news-main-page {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.news-main-page.two {
  display: none;
}

.news-main-page__top-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 48px;
}

.news-main-page__title {
  font-family: "Gotham Pro Medium";
  font-weight: 700;
  font-size: 38px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #282828;

  letter-spacing: 0.02em;
}

.news-main-page__main-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.news-main-page__news-block {
  max-width: 350px;
  width: 100%;
}

.news-main-page__news-img {
  border-radius: 30px;
  width: 350px;
  height: 250px;
  position: relative;
  margin-bottom: 24px;
  text-decoration: none;
}

.news-main-page__news-img img {
  width: 350px;
  height: 250px;
}

/* ----------------- */

.news-main-page__news-img img:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}

/* ---------------------- */

.news-main-page__news-subtitle {
  position: absolute;
  left: 32px;
  bottom: 25px;
  padding: 7px 14px;
  background: #1282e9;
  border-radius: 30px;

  color: #fff;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.02em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.news-main-page__news-block-text {
  display: flex;
  flex-direction: column;
  max-width: 340px;
  width: 100%;
}

.news-main-page__news-data {
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 13px;

  letter-spacing: 0.02em;
}

.news-main-page__news-title {
  font-family: "Gotham Pro Medium";
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 12px;

  letter-spacing: 0.02em;
}

.news-main-page__news-text {
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.02em;
}

/* ---------- */

.news-main-page__news-block-text {
  /* display: inline-block; */
  position: relative;
  line-height: 0;
}
.news-main-page__news-block-text:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 80px;
  height: 40px;
  left: 0;
  background: transparent;
  background-image: linear-gradient(to top, #fff, transparent);
  opacity: 1;
}

/* --------------- */

.news-main-page__button.two {
  display: none;
}

a.news-main-page__button.level-learning__link.one {
  width: 153px;
}

.news-main-page .slick-slide.slick-active {
  display: flex;
  justify-content: center;
}

.news-main-page button.slick-arrow.slick-next {
  margin: -316px 0 0 0;
}

.news-main-page button.slick-arrow.slick-prev {
  margin: -316px 0 0 92%;
}

.news-main-page ul.slick-dots,
.news-main-page li.slick-active {
  display: none;
}

@media (max-width: 1100px) {
  .news-main-page__news-img,
  .news-main-page__news-img img,
  .news-main-page__news-block-text,
  .news-main-page__news-block {
    width: 312px;
  }
  .news-main-page__news-block-text:after {
    height: 58px;
  }
  .news-main-page__news-img img {
    height: 230px;
  }
}

@media (max-width: 1040px) {
  .news-main-page.one {
    display: none;
  }
  .news-main-page.two {
    display: flex;
  }
  .news-main-page button.slick-arrow.slick-next {
    display: flex !important;
  }
  .news-main-page button.slick-arrow.slick-prev {
    display: flex !important;
  }
}

@media (max-width: 1025px) {
  .news-main-page .news-main-page__news-img,
  .news-main-page .news-main-page__news-img img,
  .news-main-page .news-main-page__news-block-text,
  .news-main-page .news-main-page__news-block {
    width: 290px;
  }
  .news-main-page__news-img img,
  .news-main-page__news-img {
    height: 213px;
  }
  .news-main-page .slick-slide.slick-active,
  .news-main-page .slick-slider,
  .news-main-page .slick-track,
  .news-main-page .slick-initialized .slick-slide {
    height: 370px;
  }
  .news-main-page button.slick-arrow.slick-next {
    margin: -262px 0 0 0;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -262px 0 0 92%;
  }
}

@media (max-width: 1015px) {
  .news-main-page__news-img,
  .news-main-page__news-img img,
  .news-main-page__news-block-text,
  .news-main-page__news-block {
    width: 300px;
  }
  .news-main-page__news-title {
    font-size: 19px;
    line-height: 25px;
  }
}

@media (max-width: 950px) {
  .news-main-page button.slick-arrow.slick-prev {
    margin: -262px 0 0 90%;
  }
}

@media (max-width: 895px) {
  .news-main-page__news-img img,
  .news-main-page__news-img {
    height: 250px;
  }
}

@media (max-width: 850px) {
  .news-main-page .slick-slide.slick-active,
  .news-main-page .slick-slider,
  .news-main-page .slick-track,
  .news-main-page .slick-initialized .slick-slide,
  .news-main-page .slick-list {
    height: 400px;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -262px 0 0 87%;
  }
}

@media (max-width: 744px) {
  .news-main-page__title {
    font-size: 34px;
    line-height: 32px;
  }
  .news-main-page__news-img,
  .news-main-page__news-img img,
  .news-main-page__news-block-text,
  .news-main-page__news-block {
    width: 290px !important;
  }
  .news-main-page__news-img img,
  .news-main-page__news-img {
    height: 215px;
  }
}

@media (max-width: 680px) {
  .news-main-page__news-img,
  .news-main-page__news-img img,
  .news-main-page__news-block-text,
  .news-main-page__news-block {
    width: 287px !important;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -262px 0 0 83%;
  }
}

@media (max-width: 655px) {
  .news-main-page__news-block:nth-child(2) {
    display: none;
  }
  .news-main-page__main-block {
    justify-content: center;
  }
  .news-main-page__news-img,
  .news-main-page__news-img img,
  .news-main-page__news-block-text,
  .news-main-page__news-block {
    width: 240px !important;
  }
  .news-main-page__news-img img,
  .news-main-page__news-img {
    height: 178px;
  }
  .news-main-page .slick-slide.slick-active,
  .news-main-page .slick-slider,
  .news-main-page .slick-track,
  .news-main-page .slick-initialized .slick-slide,
  .news-main-page .slick-list {
    height: 350px;
  }

  .news-main-page button.slick-arrow.slick-next {
    margin: -250px 0 0 0;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -250px 0 0 83%;
  }
  .news-main-page__news-block-text:after {
    height: 70px;
  }
}

@media (max-width: 515px) {
  .news-main-page__news-img,
  .news-main-page__news-img img,
  .news-main-page__news-block-text,
  .news-main-page__news-block {
    width: 270px !important;
  }
  .news-main-page__news-img img,
  .news-main-page__news-img {
    height: 200px;
  }
  .news-main-page .slick-slide.slick-active,
  .news-main-page .slick-slider,
  .news-main-page .slick-track,
  .news-main-page .slick-initialized .slick-slide,
  .news-main-page .slick-list {
    height: 360px;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -250px 0 0 80%;
  }
}

@media (max-width: 500px) {
  .news-main-page__top-block {
    justify-content: center;
    margin-bottom: 75px;
  }
  .news-main-page button.slick-arrow.slick-next {
    margin: -230px 37% 0 0;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -230px 0 0 37%;
  }
}

@media (max-width: 430px) {
  .news-main-page button.slick-arrow.slick-next {
    margin: -230px 35% 0 0;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -230px 0 0 35%;
  }
}

@media (max-width: 350px) {
  .news-main-page button.slick-arrow.slick-next {
    margin: -230px 30% 0 0;
  }
  .news-main-page button.slick-arrow.slick-prev {
    margin: -230px 0 0 30%;
  }
}
