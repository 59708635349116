@media (max-width: 1146px) {
  .step-prospective-student__top.undergraduate-ICTE-page.abiturient {
    flex-wrap: wrap;
  }
  .step-prospective-student__top.undergraduate-ICTE-page.abiturient
    .etap-postupleniya__title {
    padding-right: 15px;
  }
}

@media (max-width: 1095px) {
  .step-prospective-student__top.undergraduate-ICTE-page.abiturient {
    display: flex;
    flex-direction: column;
  }
  .step-prospective-student__top.undergraduate-ICTE-page.abiturient
    .etap-postupleniya__title {
    padding-right: 0;
    padding-bottom: 15px;
  }
  .step-prospective-student__top.undergraduate-ICTE-page.abiturient {
    margin-top: 0;
  }
}

@media (max-width: 793px) {
  .step-prospective-student__top.abiturient:first-of-type
    .etap-postupleniya__title {
    font-size: 34px;
    line-height: 44px;
  }
}
