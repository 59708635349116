@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}
.special-admission-conditions  .etap-postupleniya__col:nth-child(1) .progress__item.last.raspisanie {
  display: none;
}

.special-admission-conditions .etap-postupleniya__col:nth-child(1) {
  height: 80px;
}
.special-admission-conditions .step-prospective-student__top:last-child {
  padding-top: 170px;
}

@media (max-width: 1005px) {
  .special-admission-conditions .step-prospective-student__top:last-child {
    padding-top: 117px;
  }
}

@media (max-width: 975px) {
  .special-admission-conditions .etap-postupleniya__col:nth-child(1) {
    height: 120px;
  }
}

@media (max-width: 885px) {
  .special-admission-conditions
    .step-prospective-student__top:first-of-type
    .etap-postupleniya__title {
    font-size: 34px;
    line-height: 33px;
  }
}

@media (max-width: 659px) {
  .special-admission-conditions
    .step-prospective-student__top:first-of-type
    .etap-postupleniya__title {
    font-size: 24px;
    line-height: 24px;
  }
}

@media (max-width: 636px) {
  .special-admission-conditions .etap-postupleniya__col:nth-child(1) {
    height: 170px;
  }
}

@media (max-width: 616px) {
  .special-admission-conditions .etap-postupleniya__col:nth-child(1) {
    height: 120px;
  }
}

@media (max-width: 596px) {
  .special-admission-conditions .etap-postupleniya__col:nth-child(1) {
    height: 170px;
  }
}
