
.magistracy.teacher.prse .magpage_about_frame{
    background-image: url(../../../../assets/images/New/MagPRCETeacher/bg_image.svg);
    background-position: 45px 18px;
    padding: 0 14px;
}
.magistracy.teacher.prse .info-block-frame2{
    background-image: url(../../../../assets/images/New/MagPRCETeacher/info_bg_image.svg);
    background-position: 0px 20px;
    background-repeat: no-repeat;
    
}   
.magistracy.teacher.prse .info-block-frame3 .info_block-text-row{
    margin-bottom: 0px;
}
