.educational-program.magistracy
  .educational-program__main-block.mened-magistr
  .individual-achivments__text-block:nth-child(2),
.educational-program.magistracy
  .educational-program__main-block.mened-magistr
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__subtitle {
  max-width: 280px;
  width: 100%;
}

.educational-program.magistracy
  .educational-program__main-block.mened-magistr
  .individual-achivments__text-block:nth-child(3),
.educational-program.magistracy
  .educational-program__main-block.mened-magistr
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 420px;
  width: 100%;
}
