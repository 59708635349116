.educational-program__main-block.elect
  .individual-achivments__text-block:nth-child(3),
.educational-program__main-block.elect
  .individual-achivments__text-block:nth-child(2),
.educational-program__main-block.elect
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
.educational-program__main-block.elect
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__subtitle {
  max-width: 355px;
  width: 100%;
}
