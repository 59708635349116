@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.educational-loan .tuition-fees__text.blue {
  font-family: "Gotham ProMedium";
  color: #1282e9;
  padding-left: 5px;
  display: block;
}

.educational-loan .grant-rectora__steps .grant-rectora__steps-row:last-child {
  display: flex;
  align-items: flex-start;
}

.educational-loan .grant-rectora__steps .grant-rectora__text.border {
  border: 0;
  background: #1282e9;
  border-radius: 40px;
  color: #fff;
  font-family: "Gotham ProMedium";
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.educational-loan .question-content,
.educational-loan
  .grant-rectora__steps
  .grant-rectora__steps-row
  .grant-rectora__text {
  max-width: 100%;
}

.educational-loan
  .grant-rectora__steps
  .grant-rectora__steps-row
  .grant-rectora__text {
  margin-bottom: 20px;
}

.educational-loan
  .question-content
  .question-content__menu:last-child
  .question-content__item-text
  .question-content__item-text {
  padding: 10px 0 0 0;
}

.educational-loan .tuition-fees__text-block-title.img {
  /* background: #1282e9;
  border-radius: 20px; */
  padding: 20px;
}

.educational-loan
  .tuition-fees__text-block-title
  .question-content__item-text.one {
  max-width: 145px;
  width: 100%;
}

.div-row{
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

@media (max-width: 819px) {
  .educational-loan .tuition-fees__text-block-title.img {
    display: none;
  }
  .educational-loan .question-content .tuition-fees__title {
    max-width: 600px;
    width: 100%;
  }
}

@media (max-width: 750px) {
  .educational-loan .question-content .tuition-fees__title {
    max-width: 500px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .educational-loan .question-content .tuition-fees__title {
    max-width: 300px;
    width: 100%;
  }
  .tuition-fees__title {
    font-size: 18px;
    line-height: 30px;
  }
  .educational-loan .grant-rectora__steps .grant-rectora__text.border {
    margin-right: 24px;
  }
}

@media (max-width: 440px) {
  .educational-loan .grant-rectora__steps .grant-rectora__text.border {
    display: none;
  }
}
