
.educational-program__main-block.nanon
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
.educational-program__main-block.nanon
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__text,
.educational-program__main-block.nanon
  .individual-achivments__text-block:nth-child(3) {
  max-width: 415px;
  width: 100%;
}
.educational-program__main-block.nanon
  .individual-achivments__text-block:nth-child(1) {
  max-width: 145px;
  width: 100%;
}
.educational-program__main-block,
.educational-program__main-block .individual-achivments__text-block:last-child,
.educational-program__main-block
  .individual-achivments__text-block:nth-child(3) {
  margin-right: 0;
}
