.educational-program.magistracy
  .educational-program__main-block.elect-magistr
  .individual-achivments__text-block:nth-child(2),
.educational-program.magistracy
  .educational-program__main-block.elect-magistr
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__subtitle {
  max-width: 160px !important;
  width: 100%;
}
.educational-program.magistracy
  .educational-program__main-block.elect-magistr
  .individual-achivments__text-block:nth-child(3),
.educational-program.magistracy
  .educational-program__main-block.elect-magistr
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 460px !important;
  width: 100%;
}