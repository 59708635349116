.olimpiad-first-steps.tinchury .olimpiad-first-steps__img-block {
  border-radius: 30px;
  height: 480px;
  background: url(../../../assets/images/Olimpiads/tinchur.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 80px;
}

.olimpiad-first-steps.tinchury
  .olimpiad-first-steps__main-block-column
  .question-content__item-link-block.one
  .question-content__link-button:nth-child(3) {
  width: 155px;
}
