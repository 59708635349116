@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.special-admission-conditional__title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
  display: block;
  margin-bottom: 24px;
}

.special-admission-conditional__teext {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  display: block;
  margin-bottom: 16px;
}

.special-admission-conditional__text-block {
  background: #ededed;
  border-radius: 20px;
  padding: 40px 32px;
  max-width: 920px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.special-admission-conditional__text {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  display: block;
  margin-bottom: 10px;
}

.special-admission-conditional__text.last,
.special-admission-conditional__teext.last {
  display: block;
  margin-bottom: 32px;
}

.special-admission-conditional__link {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  color: #1282e9;
  text-decoration: none;
}
