.educational-program__main-block.techfiz .individual-achivments__text-block:nth-child(3),
.educational-program__main-block.techfiz .individual-achivments__text-block:nth-child(3)
.individual-achivments__subtitle {
	max-width: 355px !important;
	width: 355px !important;
}

.educational-program__main-block.techfiz .individual-achivments__text-block:nth-child(1) {
	max-width: 55px !important;
	width: 100%;
}