.educational-program__main-block.vie .individual-achivments__text-block:nth-child(1) {
    max-width: 30% !important;
    width: 100%;
}
.educational-program__main-block.vie .individual-achivments__text-block:nth-child(2) {
    max-width: 30% !important;
    width: 100%;
}
.educational-program__main-block.vie .individual-achivments__text-block:nth-child(3){
    max-width: 225px !important;
    width: 100%;
}
.educational-program__main-block.vie .individual-achivments__text-block:nth-child(3)
.individual-achivments__subtitle {
    max-width: 225px !important;
    width: 100%;
}