@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.inostranie-studenti {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
}

.inostranie-studenti .inostranie-studenti__row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
}

.inostranie-studenti .inostranie-studenti__cell.border {
  border-right: 1px dashed #b5b5b5;
  border-right-style: dotted;
}

.inostranie-studenti .inostranie-studenti__cell.border-bottom {
  border-right: 1px dashed #b5b5b5;
  border-right-style: dotted;
  border-bottom: 1px dashed #b5b5b5;
  border-bottom-style: dotted;
}

.inostranie-studenti .inostranie-studenti__cell.three.one {
  grid-column-start: 3;
  grid-column-end: 5;
}

.inostranie-studenti .inostranie-studenti__cell.border-bottom:last-child,
.inostranie-studenti .inostranie-studenti__cell.border:last-child {
  border-right: none;
}

.inostranie-studenti .inostranie-studenti__cell.three {
  grid-column-start: 3;
  grid-column-end: 4;
}

.inostranie-studenti .inostranie-studenti__cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.inostranci-content {
  margin-top: 80px;
}

.inostranci-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

@media (max-width: 1200px) {
  .inostranie-studenti .inostranie-studenti__cell.three.one {
    grid-column-start: 3;
    grid-column-end: 6;
  }

  .inostranie-studenti .inostranie-studenti__cell.three {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  .inostranie-studenti .inostranie-studenti__cell.three,
  .inostranie-studenti .inostranie-studenti__cell.border,
  .inostranie-studenti .inostranie-studenti__cell.border-bottom:last-child,
  .inostranie-studenti .inostranie-studenti__cell.border:last-child,
  .inostranie-studenti .inostranie-studenti__cell.border-bottom,
  .inostranie-studenti .inostranie-studenti__cell {
    font-size: 11px;
    line-height: 14px;
  }
}

@media (max-width: 910px) {
  .inostranie-studenti .inostranie-studenti__row .cel__img {
    width: 45px;
  }
  .inostranie-studenti .inostranie-studenti__row .strelka {
    height: 45px;
  }
  .inostranie-studenti .inostranie-studenti__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .inostranie-studenti .inostranie-studenti__cell.three {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}

@media (max-width: 755px) {
  .inostranie-studenti .inostranie-studenti__row .cel__img {
    width: 35px;
  }
  .inostranie-studenti .inostranie-studenti__row .strelka {
    height: 35px;
  }
}

@media (max-width: 440px) {
  .inostranie-studenti .inostranie-studenti__row .cel__img {
    display: none;
  }
  .inostranie-studenti .inostranie-studenti__row .strelka {
    height: 25px;
  }
  .inostranie-studenti .inostranie-studenti__row {
    display: none;
  }
  .inostranie-studenti br {
    display: flex;
  }
  .inostranci-text {
    font-size: 14px;
    line-height: 16px;
  }
}
