.educational-program.magistracy
  .educational-program__main-block.pi-magistr
  .individual-achivments__text-block:nth-child(3),
.educational-program.magistracy
  .educational-program__main-block.pi-magistr
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 420px;
  width: 100%;
}
