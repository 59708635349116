.educational-program.teacher.mech .teacher__info-block-frame-large .wrap img:first-child {
  margin-top: -35px;
  margin-left: -25px;
}
.educational-program.teacher.mech .teacher__info-block-frame-large .wrap img:last-child {
  margin-right: -25px;
  margin-top: 5px;
  right: 0;
}

.educational-program.teacher.mech .teacher_exams {
  background-image: url(../../../../assets/images/New/MechTeacher/bg_image.svg);
  background-position: 62px 35px;
}