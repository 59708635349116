.educational-program__main-block.ivt
  .individual-achivments__text-block:nth-child(3),
.educational-program__main-block.ivt
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 350px;
  width: 100%;
}
.educational-program__main-block.ivt
  .individual-achivments__text-block:nth-child(2),
.individual-achivments__text-block:nth-child(2)
  .individual-achivments__subtitle {
  max-width: 210px;
  width: 100%;
}
