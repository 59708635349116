@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.undegraduate-ICTE-info .undegraduate-ICTE-info__title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
  display: block;
  margin-bottom: 40px;
}

.undegraduate-ICTE-info .individual-achivments__top-block-subjects {
  display: flex;
  flex-direction: column;
}

.undegraduate-ICTE-info .individual-achivments__top-block-subjects-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block {
  margin-right: 20px;
  height: 20px;
  width: 20px;
  color: transparent;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.math {
  background: #1282e9;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.rus {
  background: #e912ba;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.fiz {
  background: #8d20fb;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.inf {
  background: #13c54f;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.obsh {
  background: #0b4295;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.ist {
  background: #e91212;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.inost {
  background: #12e9cf;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.biol {
  background: #fbbe20;
  border-radius: 30px;
}

.undegraduate-ICTE-info .individual-achivments__top-subjects-block.him {
  background: #e95f12;
  border-radius: 30px;
}

.undegraduate-ICTE-info .undegraduate-ICTE-info {
  background: #ededed;
  border-radius: 20px;
  padding: 20px 32px;
  display: flex;
  justify-content: space-between;
}

.undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .acceptance-checksum__table-title-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
}

.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block.undegraduate-ICTE-info-one {
  background: transparent;
  border-radius: 20px;
}

.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block.undegraduate-ICTE-info-two {
  background: rgb(237, 237, 237, 0.4);
  border-radius: 20px;
}

.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:not(:first-child)
  .acceptance-checksum__table-title
  .acceptance-checksum__subjects {
  font-size: 12px;
  line-height: 11px;
  padding: 7px 15px;
}

.undegraduate-ICTE-info .acceptance-checksum__table-title:not(:last-of-type) {
  margin-right: 16px;
}

.undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .entrance-tests__column:first-of-type {
  max-width: 385px;
  width: 100%;
  margin-right: 12px;
  align-items: center;
}

.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:first-of-type,
.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:first-of-type
  .acceptance-checksum__table-title {
  max-width: 385px;
  width: 100%;
  margin-right: 12px;
}

.undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .entrance-tests__column:not(:first-child),
.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:not(:first-child),
.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block.undegraduate-ICTE-info-one
  .entrance-tests__column:not(:first-child) {
  text-align: center;
  justify-content: center;
  vertical-align: middle;
}

/* 
.entrance-tests magistratura.undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .entrance-tests__column:nth-child(2),
.entrance-tests magistratura.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:nth-child(2) {
  max-width: 109px;
  width: 100%;
  margin-right: 24px;
}

.entrance-tests magistratura.undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .entrance-tests__column:nth-child(3),
.entrance-tests magistratura.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:nth-child(3) {
  max-width: 126px;
  width: 100%;
  margin-right: 24px;
}

.entrance-tests magistratura.undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .entrance-tests__column:nth-child(4),
.entrance-tests magistratura.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:nth-child(4)
  .undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .entrance-tests__column:nth-child(5) {
  max-width: 115px;
  width: 100%;
  margin-right: 24px;
}

.entrance-tests magistratura.undegraduate-ICTE-info
  .undegraduate-ICTE-info
  .entrance-tests__column:last-child,
.entrance-tests magistratura.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column:last-child {
  max-width: 257px;
  width: 100%;
} */

.undegraduate-ICTE-info
  .acceptance-checksum__table-title-block
  .entrance-tests__column {
  display: flex;
  align-items: center;
}

.undegraduate-ICTE-info .entrance-tests {
  margin-bottom: 40px;
}

.undegraduate-ICTE-info__subtext {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;

  display: block;
  margin-bottom: 24px;
  max-width: 480px;
  width: 100%;
}

/* ------------------------------------------------- */

@media (max-width: 1095px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 190px;
  }
}
@media (max-width: 785px) {
  .etap-postupleniya__col .progress__item-title {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 715px) {
  .etap-postupleniya__col .progress__item-title {
    font-size: 13px;
    line-height: 17px;
  }
}
