@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.contacts .step-prospective-student__top {
  padding-top: 170px;
  margin-bottom: 40px;
}

.contacts .preparatory-courses__info-block {
  justify-content: space-between;
}

.contacts .preparatory-courses__info-block .preparatory-courses__text-block {
  background: transparent;
  padding: 0;
}

.contacts
  .preparatory-courses__text-block:first-of-type
  .preparatory-courses__text-block-subtitle {
  max-width: 100%;
}

.contacts .preparatory-courses__text-block:first-of-type .tuition-fees__title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
}

.contacts .preparatory-courses__text-block:first-of-type .tuition-fees__title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #282828;
  max-width: 580px;
  width: 100%;
}

.contacts
  .question-content
  .question-content__menu:nth-child(1)
  .question-content__item--active
  .question-content__item-text {
  padding-top: 25px;
}

.contacts
  .question-content
  .question-content__menu
  .question-content__item--active
  .question-content__item-text {
  padding-top: 15px;
}

.preparatory-courses__text-block-subtitle.sub {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 22px;
}

.preparatory-courses__text-block-subtitle.subsub {
  font-family: Gotham Pro;
  margin: 5px 0;
  font-size: 16px;
  line-height: 18px;
}

.preparatory-courses__info-block .preparatory-courses__text-block-text.sub {
  margin-bottom: 5px !important;
}

@media (max-width: 1042px) {
  .contacts
    .preparatory-courses__info-block
    .preparatory-courses__text-block:nth-child(2) {
    display: flex;
  }
  .contacts .preparatory-courses__info-block {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }
  .contacts .preparatory-courses__text-block-subtitle {
    max-width: 100%;
  }
  .contacts .question-content {
    max-width: 100%;
  }
}

@media (max-width: 744px) {
  .contacts
    .step-prospective-student__top:first-of-type
    .etap-postupleniya__title {
    font-size: 34px;
    line-height: 34px;
  }
}

@media (max-width: 690px) {
  .contacts
    .preparatory-courses__text-block:first-of-type
    .tuition-fees__title {
    max-width: 400px;
    width: 100%;
  }
}

@media (max-width: 523px) {
  .contacts
    .preparatory-courses__text-block:first-of-type
    .tuition-fees__title {
    max-width: 300px;
    width: 100%;
  }
}

@media (max-width: 413px) {
  .contacts
    .preparatory-courses__text-block:first-of-type
    .tuition-fees__title {
    max-width: 250px;
    width: 100%;
  }
}

@media (max-width: 375px) {
  .contacts
    .step-prospective-student__top:first-of-type
    .etap-postupleniya__title {
    font-size: 24px;
    line-height: 24px;
  }
}
