
@media (max-width: 738px) {
  .undergraduate-ICTE-page__main.abiturient.magistracy
    .etap-postupleniya__col:nth-child(1) {
    height: 127px;
  }
}

@media (max-width: 436px) {
  .undergraduate-ICTE-page__main.abiturient.magistracy
    .etap-postupleniya__col:nth-child(1) {
    height: 177px;
  }
}
