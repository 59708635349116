@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

.scholarship .tuition-fees__text-block.two {
  background: #f6f6f6;
  border-radius: 20px;
}

.scholarship .tuition-fees__text-block.one.last {
  margin-bottom: 40px;
}

.scholarship .tuition-fees__title-block.first span {
  width: 265px;
}

@media (max-width: 819px) {
  .scholarship .tuition-fees__text-block,
  .scholarship .tuition-fees__title-block {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .scholarship .tuition-fees__text-block-summa {
    padding-bottom: 0;
  }
}

@media (max-width: 676px) {
  .scholarship .tuition-fees__text-block-summa {
    font-size: 30px;
    line-height: 34px;
  }
  .scholarship .tuition-fees__title {
    font-size: 20px;
    line-height: 24px;
  }
  .scholarship .tuition-fees__text-block,
  .scholarship .tuition-fees__title-block {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .scholarship .tuition-fees__text-block-summa {
    padding-bottom: 20px;
  }
}
