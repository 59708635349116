.educational-program.teacher.pt
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.pt
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.pt .teacher_exams {
  background-image: url(../../../../assets/images/New/PTTeacher/bg_image.svg);
  background-position: 71px 43px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.pt .teacher__info-block-frame-large .wrap {
    height: 0;
  }
  .educational-program.teacher.pt .teacher__info-block-frame-large {
    height: 327px;
  }
}


@media (max-width: 1005px){
  .educational-program.teacher.pt .teacher_exams {
    background-image: none;
  }
}


@media (max-width: 855px) {
  .educational-program.teacher.pt .teacher__info-block-frame-large {
    height: 380px;
  }
  .educational-program.teacher.pt 
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 45px;
  }
}


@media (max-width: 845px) {
  .educational-program.teacher.pt .teacher_exams {
    background-image: url(../../../../assets/images/New/PTTeacher/bg_image.svg);
    background-position: 100% 50%;
  }
  .educational-program.teacher.pt .teacher__main-block-img {
    margin-right: 56px;
  }
}

@media (max-width: 835px) {
  .educational-program.teacher.pt .teacher__main-block-img {
    margin-right: 5px;
  }
}

@media (max-width: 553px) {
  .educational-program.teacher.pt .teacher__info-block-frame-large {
    height: 420px;
  }
  .educational-program.teacher.pt 
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 85px;
  }
}

@media (max-width: 492px) {
  .educational-program.teacher.pt .teacher__info-block-frame-large {
    height: 470px;
  }
  .educational-program.teacher.pt 
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 135px;
  }
}

@media (max-width: 456px) {
  .educational-program.teacher.pt .teacher__info-block-frame-large {
    height: 530px;
  }
  .educational-program.teacher.pt 
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 195px;
  }
}

@media (max-width: 340px) {
  .educational-program.teacher.pt .teacher__info-block-frame-large {
    height: 570px;
  }
  .educational-program.teacher.pt 
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 235px;
  }
}