.educational-program__main-block.teplo
  .individual-achivments__text-block:nth-child(3),
.educational-program__main-block.teplo
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 408px;
  width: 100%;
}

.educational-program__main-block.teplo
  .individual-achivments__text-block:nth-child(2),
.educational-program__main-block.teplo
  .individual-achivments__text-block:nth-child(2)
  .individual-achivments__subtitle {
  max-width: 308px;
  width: 100%;
}

.educational-program__main-block.teplo
  .individual-achivments__text-block:nth-child(4),
.educational-program__main-block.teplo
  .individual-achivments__text-block:nth-child(5) {
  margin-top: 0;
}
