.educational-program.teacher.ebt
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.ebt
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -30px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.ebt .teacher_exams {
  background-image: url(../../../../assets/images/New/EBTTeacher/bg_image.svg);
  background-position: 42px 15px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large .wrap {
    height: 0;
  }
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 327px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.ebt .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 855px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 380px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 20px;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.ebt .teacher_exams {
    background-image: url(../../../../assets/images/New/ESTeacher/bg_image.svg);
    background-position: 100% 50%;
  }
  .educational-program.teacher.ebt .teacher__main-block-img {
    margin-right: 56px;
  }
}

@media (max-width: 835px) {
  .educational-program.teacher.ebt .teacher__main-block-img {
    margin-right: 5px;
  }
}

@media (max-width: 580px) {
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 420px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 60px;
  }
}

@media (max-width: 518px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 460px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 100px;
  }
}

@media (max-width: 494px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 500px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 140px;
  }
}

@media (max-width: 487px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 540px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 180px;
  }
}

@media (max-width: 411px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 500px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 140px;
  }
}

@media (max-width: 363px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 530px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 170px;
  }
}

@media (max-width: 358px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 570px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 210px;
  }
}

@media (max-width: 332px) {
  .educational-program.teacher.ebt .teacher__info-block-frame-large {
    height: 600px;
  }
  .educational-program.teacher.ebt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 240px;
  }
}
