@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.cdo__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.cdo__info-block {
  display: flex;
  flex-direction: column;
  background: #ededed;
  border-radius: 20px;
  padding: 40px;
  width: 50%;
  margin-right: 30px;
  justify-content: space-between;
}

.cdo__info-block-title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;

  display: block;
  margin-bottom: 24px;
}

.cdo__info-block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;

  display: block;
  margin-bottom: 16px;
}

.cdo__info-block-button {
  background: #1282e9;
  border-radius: 30px;
  padding: 15px 24px;
  max-width: 180px;
  width: 100%;
  margin-top: 40px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
}

.cdo__column{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-right: 30px;
}

.cdo__column .cdo__info-block{
    width: 100%;
}

.cdo__column .cdo__info-block:first-of-type{
    margin-bottom: 30px;
}