.undergraduate-ICTE-page__main.magistracy
  .etap-postupleniya__content-col
  .etap-postupleniya__col:nth-child(1) {
  height: 145px;
}
@media (max-width: 1052px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 205px;
  }
}

@media (max-width: 812px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 255px;
  }
}

@media (max-width: 785px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 190px;
  }
}

@media (max-width: 744px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 245px;
  }
}

@media (max-width: 738px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 245px !important;
  }
}

@media (max-width: 715px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 190px !important;
  }
}

@media (max-width: 715px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 245px !important;
  }
}

@media (max-width: 630px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 195px !important;
  }
}

@media (max-width: 457px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 275px !important;
  }
  .progress__item--active {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 383px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 315px !important;
  }
}

@media (max-width: 383px) {
  .undergraduate-ICTE-page__main.magistracy
    .etap-postupleniya__content-col
    .etap-postupleniya__col:nth-child(1) {
    height: 315px !important;
  }
}
