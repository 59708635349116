@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.receptio-of-foreign-citizens__block,
.receptio-of-foreign-citizens__block-text {
  display: flex;
  flex-direction: row;
}

.receptio-of-foreign-citizens__block {
  justify-content: space-between;
}

.receptio-of-foreign-citizens__block-column {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.receptio-of-foreign-citizens__block-column:nth-child(1) {
  max-width: 635px;
  width: 100%;
}

.receptio-of-foreign-citizens__block-text {
  background: #ededed;
  border-radius: 20px;
  padding: 20px 32px;
  align-items: center;
}

.receptio-of-foreign-citizens__block-text.two {
  background: transparent;
}

.receptio-of-foreign-citizens__number {
  background: #1282e9;
  border-radius: 30px;
  padding: 8px 16px;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  display: block;
  margin-right: 32px;
  height: 40px;
}

.receptio-of-foreign-citizens__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #282828;

  max-width: 458px;
  width: 100%;
}

.receptio-of-foreign-citizens__block-column:nth-child(2) {
  background: #ededed;
  border-radius: 20px;
  padding: 32px;
}

.receptio-of-foreign-citizens__block-column:nth-child(2)
  .receptio-of-foreign-citizens__text.last {
  display: block;
  margin-bottom: 0;
}

.receptio-of-foreign-citizens__block-column-title {
  display: block;
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
  margin-bottom: 24px;
}

.receptio-of-foreign-citizens__block-column:nth-child(2)
  .receptio-of-foreign-citizens__text {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  margin-bottom: 16px;
}

.receptio-of-foreign-citizens__block-column:nth-child(2)
  .receptio-of-foreign-citizens__text.blue {
  color: #1282e9;
  font-family: "Gotham ProMedium";
  display: block;
  margin-bottom: 0;
}
