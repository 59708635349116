.info-block-stack{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    max-width: 379px;
    width: 100%;
}

/* блок О программе */
.magpage_about_frame{
    height: 428px;
    max-width: 284px;
    width: 100%;

    border: 3px solid #1282e9;
    border-radius: 20px;

    padding: 0px 25px;

    background-image: url(../../../../assets/images/New/MagStroitTeacher/bg_image.svg);
    background-position: 71px 43px;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    text-align: center;

    justify-content: center;
    align-items: center;
}

.magpage_about_programm_title{
    color: #1282e9;
    font-size: 22px;
    margin-bottom: 15px;
    font-family: "Gotham Pro";
    font-weight: 600;
}

.magpage_about_programm_text{
    font-size: 16px;
    font-family: "Gotham Pro";
    font-weight: 500;
    line-height: 1.205;
}

/* блок Учебные дисциплины */
.magistracy .info-block-frame{
    max-width: 665px;
    width: 100%;  
    height: 700px;
    padding: 39px 36px;
    background-image: url(../../../../assets/images/New/mag_info_block_bg.svg);
}

.magistracy .info-block-frame .info_block-text-row{
    margin-bottom: 20px;
}

.magistracy .info-block-frame .info_block-text-row .text-line{
    max-width: 540px;
}

.magistracy .info_block-title{
    margin-bottom: 0px;
}

.magistracy .info_block-title2 {
    margin-bottom: 20px;
    margin-top: 35px;
    text-align: start;
    font-family: "Gotham Pro";
    font-weight: bold;
    font-size: 22px;
    color: white;
}

.magistracy .number{
    font-size: 18px;
}



/* блок Кем я смогу работать */

.magistracy .info-block-frame2{
    height: 436px;
    max-width: 379px;
    width: 100%;

    border: 3px solid #1282e9;
    border-radius: 20px;

    padding: 0px 33px 77px 33px;

    background-image: url(../../../../assets/images/New/MagStroitTeacher/info_bg_image.svg);
    background-position: 0px 54px;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    text-align: center;

    justify-content: center;
    align-items: center;
}
.magistracy .info-block-frame2 .info_block-title{
    color: black;
    margin-top: 86px;
    margin-bottom: 37px;
}
.magistracy .info-block-frame2 .info_block-text-row{
    margin-bottom: 25px;
}

.magistracy .info-block-frame2 .text-line{
    color: black;
    font-weight: 600px;
    text-align: start;
}

/* Трудоустройство */

.magistracy .info-block-frame3{
    height: 225px;
    max-width: 379px;
    width: 100%;
    padding: 0 29px;

    border: 3px solid #1282e9;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    text-align: center;

    justify-content: center;
    align-items: center;
}

.magistracy .info-block-frame3 .info_block-title{
    color: black;

    margin-bottom: 15px;
}
.magistracy .info-block-frame3 .info_block-text-row{
    width: 100%;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
}