.educational-program.teacher.aec
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.aec
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.aec .teacher_exams {
  background-image: url(../../../../assets/images/New/AECTeacher/bg_image.svg);
  background-position: 71px 43px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 35px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.aec .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.aec .teacher_exams {
    background-image: url(../../../../assets/images/New/AECTeacher/bg_image.svg);
    background-position: 100% 50%;
  }
  .educational-program.teacher.aec .teacher__main-block-img {
    margin-right: 56px;
  }
}

@media (max-width: 835px) {
  .educational-program.teacher.aec .teacher__main-block-img {
    margin-right: 5px;
  }
}

@media (max-width: 750px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 90px;
  }
}

@media (max-width: 570px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 120px;
  }
}

@media (max-width: 456px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 180px;
  }
}

@media (max-width: 411px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 130px;
  }
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
}

@media (max-width: 388px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 150px;
  }
}

@media (max-width: 361px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 180px;
  }
  .educational-program.teacher.aec
  .teacher__info-block-frame-large {
    height: 510px;
  }
}

@media (max-width: 338px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 230px;
  }
  .educational-program.teacher.aec
  .teacher__info-block-frame-large {
    height: 560px;
  }
}

@media (max-width: 325px) {
  .educational-program.teacher.aec
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 280px;
  }
  .educational-program.teacher.aec
  .teacher__info-block-frame-large {
    height: 610px;
  }
}
