.level-learning {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 46px 0 30px 0;
  align-items: center;
}

.level-learning__link {
  text-decoration: none;
}

.level-learning__text.one {
  color: #1282e9;
}

.level-learning__link.konkurs {
  border: 1px solid #1282e9;
  border-radius: 10px;
  padding: 5px 10px;
}

.level-learning__link.konkurs:hover {
  background: #1282e9;
}
.level-learning__text.one:hover {
  color: #fff;
}

/*----------------------------*/

.level-learning__link:hover span {
  color: #2195ff;
}
.level-learning__link:active span {
  color: #1277d4;
}

/*----------------------------*/

.level-learning__text {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.level-learning__link:last-child {
  margin-right: 0;
}

@media (max-width: 1040px) {
  .level-learning__link {
    margin-right: 15px;
  }
}

@media (max-width: 955px) {
  .level-learning__link {
    display: flex;
    align-items: center;
  }
  /* .level-learning {
    justify-content: start;
  } */
}

@media (max-width: 770px) {
  .level-learning__link {
    width: 150px;
    padding: 5px 5px 15px 0;
    margin-right: 30px;
    justify-content: center;
  }
  .level-learning__link:last-child {
    margin-bottom: 0;
    max-width: 70%;
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 670px) {
  .level-learning__link:last-child {
    max-width: 65%;
  }
}
@media (max-width: 640px) {
  .level-learning__title {
    text-align: center;
  }
}

@media (max-width: 570px) {
  .level-learning__link {
    width: 150px;
    margin-right: 15px;
  }
}

@media (max-width: 525px) {
  .level-learning__link {
    width: 130px;
    margin-right: 15px;
  }
}

@media (max-width: 500px) {
  .level-learning__title {
    font-size: 18px;
    line-height: 17px;
  }
}

@media (max-width: 465px) {
  .level-learning__link {
    width: 120px;
    margin-right: 15px;
  }
}

@media (max-width: 435px) {
  .level-learning__link:last-child {
    max-width: 100%;
  }
}

@media (max-width: 360px) {
  .level-learning__link {
    width: 140px;
  }
}

@media (max-width: 340px) {
  .level-learning__link {
    width: 120px;
  }
}
