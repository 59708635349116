@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.step-prospective-student__top.undergraduate-ICTE-page
  .etap-postupleniya__title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1282e9;
  opacity: 0.5;
}

.step-prospective-student__top.undergraduate-ICTE-page
  .etap-postupleniya__title.one {
  opacity: 1;
}

.progress__item.last.progress__item--active {
  color: #fff;
  background: #1282e9;
  padding: 10px 25px;
  border-radius: 30px;
  margin: 0px 16px 10px 0;
}

.undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
  height: 265px;
}
@media (max-width: 1113px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 305px;
  }
}
@media (max-width: 919px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 355px;
  }
}
@media (max-width: 884px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 375px;
  }
}
@media (max-width: 874px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 395px;
  }
}
@media (max-width: 676px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 425px;
  }
}
@media (max-width: 400px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 525px;
  }
}
@media (max-width: 371px) {
  .undergraduate-ICTE-page__main .etap-postupleniya__col:nth-child(1) {
    height: 575px;
  }
}

@media (max-width: 345px) {
  .undergraduate-ICTE-page__main.abiturient .etap-postupleniya__col
    .progress__item:last-child
    .progress__item-title {
    padding: 0;
    margin: 0;
    background: transparent;
    border-radius: 0;
    text-decoration: none;
  }
  .undergraduate-ICTE-page__main.abiturient .etap-postupleniya__col
    .progress__item:last-child {
    padding: 10px 25px;
    background: #ededed;
    border-radius: 30px;
    margin: 0;
  }
}