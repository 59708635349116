.teacher__main-block-img.sidorov {
  margin-right: 65px;
}
.teacher__main-block-img.sidorov img {
  margin-right: 0;
  width: 285px;
}
.magistracy.teacher.tiop .magpage_about_frame{
  background-image: url(../../../../assets/images/New/MagTIOPTeacher/bg_image.svg);
  background-position: 45px 18px;
  padding: 0px 14px;
}
.magistracy.teacher.tiop .info-block-frame2{
  background-image: url(../../../../assets/images/New/MagTIOPTeacher/info_bg_image.svg);
  background-position: 0px 34px;
  background-repeat: no-repeat;
}   
.magistracy.teacher.tiop .info-block-frame3 .info_block-text-row{
  margin-bottom: 0px;
}
