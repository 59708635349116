.educational-program.magistracy .educational-program__main-block {
  max-width: 1107px;
  width: 100%;
}

.educational-program.magistracy
  .educational-program__main-block.elect
  .individual-achivments__text-block:nth-child(3),
.educational-program.magistracy
  .educational-program__main-block.elect
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle {
  max-width: 415px;
  width: 100%;
}

.educational-program.magistracy
  .educational-program__main-block.elect
  .individual-achivments__text-block:nth-child(4) {
  margin-top: 0;
}
