.educational-program.teacher.web
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -27px;
  margin-top: 15px;
  right: 0;
}
.educational-program.teacher.web .teacher_exams {
  background-image: url(../../../../assets/images/New/WEBTeacher/bg_image.svg);
  background-position: 62px 20px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 57px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.web .teacher_exams {
    background-image: none;
  }
}
@media (max-width: 845px) {
  .educational-program.teacher.web .teacher_exams {
    background-image: url(../../../../assets/images/New/WEBTeacher/bg_image.svg);
    background-position: right;
  }
}

@media (max-width: 750px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 112px;
  }
}

@media (max-width: 570px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 85px;
  }
  .educational-program.teacher.web
  .teacher__info-block-frame-large {
    height: 400px;
}
}

@media (max-width: 507px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 125px;
  }
  .educational-program.teacher.web
  .teacher__info-block-frame-large {
    height: 440px;
}
}

@media (max-width: 456px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 165px;
  }
  .educational-program.teacher.web
  .teacher__info-block-frame-large {
    height: 480px;
}
}

@media (max-width: 411px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 115px;
  }
  .educational-program.teacher.web
  .teacher__info-block-frame-large {
    height: 430px;
}
}

@media (max-width: 388px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 155px;
  }
  .educational-program.teacher.web
  .teacher__info-block-frame-large {
    height: 470px;
}
}

@media (max-width: 335px) {
  .educational-program.teacher.web
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 205px;
  }
  .educational-program.teacher.web
  .teacher__info-block-frame-large {
    height: 520px;
}
}
