.step-prospective-student-menu.question
  .question-content
  .question-content__menu
  .question-content__item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  background: #ededed;
  border-radius: 20px;
  padding: 22px 32px;
  background-image: url(../../../../assets/images/ProspectiveStudent/btn.png);
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.step-prospective-student-menu.question
  .question-content
  .question-content__menu
  .question-content__item--active {
  background-image: url(../../../../assets/images/ProspectiveStudent/btn-top.png);
  background-repeat: no-repeat;
  background-position: 95% 26px;
}

.step-prospective-student-menu{
    margin-bottom: 40px;
}