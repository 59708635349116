.educational-program__main-block.materialoved
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
.educational-program__main-block.materialoved
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__text,
.educational-program__main-block.materialoved
  .individual-achivments__text-block:nth-child(4) {
  max-width: 435px;
  width: 100%;
}

.educational-program__main-block.materialoved
  .individual-achivments__text-block:nth-child(4) {
  margin-top: 0;
}
.educational-program__main-block.materialoved
  .individual-achivments__text-block:nth-child(1) {
  max-width: 50%;
  width: 100%;
}

.educational-program__main-block.materialoved
  .individual-achivments__text-block:nth-child(2) {
  max-width: 185px;
  width: 100%;
}

.educational-program__main-block.materialoved.two
  .individual-achivments__text-block:nth-child(1)
  .individual-achivments__subtitle,
.educational-program__main-block.materialoved.two
  .individual-achivments__text-block:nth-child(1)
  .individual-achivments__text,
.educational-program__main-block.materialoved.two
  .individual-achivments__text-block:nth-child(1) {
  max-width: 155px;
  width: 100%;
}