.educational-program.teacher.ivt
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-top: -38px;
  z-index: -1;
}
.educational-program.teacher.ivt
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -25px;
  margin-top: -5px;
  right: 0;
}

.educational-program.teacher.ivt .teacher_exams {
  background-image: url(../../../../assets/images/New/IVTTeacher/bg_image.svg);
  background-position: 97px 23px;
}

.info_block-text-row:last-child {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-bottom: 18px;
}

@media (max-width: 1175px) {
  .educational-program.teacher.ivt .frame-large-text-row .info_block-text-row {
    max-width: 320px;
    width: 100%;
  }
  .educational-program.teacher.ivt
    .frame-large-text-row
    .info_block-text-row:last-child {
    max-width: 700px;
    width: 100%;
  }
}

@media (max-width: 1145px) {
  .educational-program.teacher.ivt
    .frame-large-text-row
    .info_block-text-row:last-child {
    max-width: 600px;
    width: 100%;
  }
}

@media (max-width: 1095px) {
  .educational-program.teacher.ivt .frame-large-text-row .info_block-text-row {
    max-width: 300px;
  }
}

@media (max-width: 1055px) {
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 38px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.ivt .teacher_exams {
    background-image: none;
  }
}
@media (max-width: 845px) {
  .educational-program.teacher.ivt .teacher_exams {
    background-image: url(../../../../assets/images/New/IVTTeacher/bg_image.svg);
    background-position: 100% 0px;
  }
}

@media (max-width: 787px) {
  .educational-program.teacher.ivt .teacher__info-block-frame-large {
    height: 430px;
  }
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 98px;
  }
}

@media (max-width: 550px) {
  .educational-program.teacher.ivt .teacher__info-block-frame-large {
    height: 435px;
  }
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 105px;
  }
}

@media (max-width: 503px) {
  .educational-program.teacher.ivt .teacher__info-block-frame-large {
    height: 495px;
  }
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 165px;
  }
}

@media (max-width: 427px) {
  .educational-program.teacher.ivt .teacher__info-block-frame-large {
    height: 535px;
  }
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 205px;
  }
}

@media (max-width: 411px) {
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
  .educational-program.teacher.ivt .teacher__info-block-frame-large {
    height: 485px;
  }
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 155px;
  }
}

@media (max-width: 353px) {
  .educational-program.teacher.ivt .teacher__info-block-frame-large {
    height: 555px;
  }
  .educational-program.teacher.ivt
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 225px;
  }
}
