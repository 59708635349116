.educational-program.teacher.econom
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -25px;
  margin-top: -35px;
}

.educational-program.teacher.econom
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: 5px;
  right: 0;
}

.educational-program.teacher.econom .teacher_exams {
  background-image: url(../../../../assets/images/New/EconomTeacher/bg_image.svg);
  background-position: 95px 40px;
}
