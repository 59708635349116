@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.payment-methods .question-content {
  max-width: 855px;
  width: 100%;
}

.payment-methods__subtitle {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
  display: block;
  margin-bottom: 40px;
}

.payment-methods
  .question-content__item--active:first-of-type
  .question-content__item-text.block {
  background: #1282e9;
  border-radius: 20px;
  padding: 16px 32px;
  margin-top: 32px;
}

.payment-methods
  .question-content__item:first-of-type
  .question-content__item-text:last-child
  .question-content__item-text:first-of-type {
  padding-top: 0;
}

.payment-methods
  .question-content__item:first-of-type
  .question-content__item-text:last-child
  .question-content__item-text:nth-child(2) {
  padding: 8px 0 0 0;
  margin-top: 0;
}

.payment-methods .question-content__item-text.title {
  font-family: "Gotham Pro";
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.payment-methods
  .question-content__item--active:first-of-type
  .question-content__item-text.block {
  margin-bottom: 26px;
}

.payment-methods
  .question-content__item--active:first-of-type
  .question-content__item-text.block
  .question-content__item-text.one {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.payment-methods
  .question-content__item--active:first-of-type
  .question-content__item-text.block
  .question-content__item-text.two {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #ffffff;
}

.payment-methods .question-content__item-text-img {
  max-width: 344px;
  width: 100%;
  height: 100%;
}

@media (max-width: 825px) {
  .payment-methods
    .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title {
    max-width: 500px;
  }
}

@media (max-width: 720px) {
  .payment-methods
    .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title {
    max-width: 400px;
  }
}

@media (max-width: 625px) {
  .payment-methods
    .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title {
    max-width: 350px;
  }
}

@media (max-width: 520px) {
  .payment-methods
    .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title {
    max-width: 300px;
  }
}

@media (max-width: 445px) {
  .payment-methods
    .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title {
    max-width: 230px;
  }
}

@media (max-width: 410px) {
  .payment-methods
    .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title {
    max-width: 210px;
  }
  .payment-methods
    .question-content__menu:nth-child(3)
    .question-content__link-button {
    height: 52px;
  }
  .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title-number {
    margin-right: 10px;
  }
}

@media (max-width: 395px) {
  .question-content .question-content__menu .question-content__item {
    padding: 16px;
  }
}

@media (max-width: 363px) {
  .payment-methods
  .question-content__menu:nth-child(2)
  .question-content__link-button:nth-child(1) {
  height: 52px;
  }
  .payment-methods
    .question-content
    .question-content__menu
    .question-content__item
    .question-content__item-title {
    max-width: 200px;
  }
}

