@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.tuition-fees,
.tuition-fees__text-block-title {
  display: flex;
  flex-direction: column;
}

.tuition-fees__text-block {
  display: flex;
  flex-direction: row;
  padding: 20px 32px;
  align-items: center;
  justify-content: space-between;
}

.tuition-fees__title-block {
  background: #ededed;
  border-radius: 20px;
  padding: 20px 32px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tuition-fees__text-block.sub {
  border-bottom: 1px solid #ededed;
}

.tuition-fees__text-block.one {
  margin-bottom: 20px;
}

.tuition-fees .individual-achivments__title.two {
  padding-top: 32px;
}

.tuition-fees__title {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
}

.tuition-fees__text {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.tuition-fees__text:not(:last-of-type) {
  display: flex;
  padding-bottom: 16px;
}

.tuition-fees__text-block-summa {
  font-family: "Gotham ProMedium";
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #1282e9;
  width: 270px;
}

@media (max-width: 1028px) {
  .tuition-fees__text-block-summa {
    font-size: 34px;
    line-height: 38px;
  }
  .tuition-fees__text-block-title,
  .tuition-fees__title-block:last-child .tuition-fees__title {
    margin-right: 20px;
    max-width: 500px;
  }
}

@media (max-width: 918px) {
  .tuition-fees__text-block-title,
  .tuition-fees__title-block:last-child .tuition-fees__title {
    max-width: 400px;
  }
}

@media (max-width: 819px) {
  .tuition-fees__text-block,
  .tuition-fees__title-block {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .tuition-fees__text-block-summa {
    padding-bottom: 20px;
  }
  .tuition-fees__text-block-title,
  .tuition-fees__title-block:last-child .tuition-fees__title {
    max-width: 100%;
  }
}
