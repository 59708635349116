@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}
@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.olimpiad-main {
  padding-top: 170px;
}

.olimpiad-main__topmenu {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
}

.olimpiad-main__topmenu-text {
  font-family: "Gotham Pro";
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #7d7d7d;
}

.olimpiad-main__topmenu-text:not(:last-child) {
  padding-right: 8px;
}

.olimpiad-main__title {
  font-family: "Gotham ProMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  letter-spacing: 0.02em;
  color: #282828;
  display: block;
  margin-bottom: 40px;
}

.olimpiad-main__info-block {
  margin-bottom: 40px;
  background: #ededed;
  border-radius: 20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.olimpiad-main__info-block:nth-child(1) {
  background-image: url(../../../assets/images/Olimpiads/menWork.png);
  background-repeat: no-repeat;
  background-position: 95% 60%;
}

.olimpiad-main__info-block:nth-child(2),
.olimpiad-main__info-block:nth-child(4),
.olimpiad-main__info-block:nth-child(6),
.olimpiad-main__info-block:nth-child(8) {
  margin-bottom: 0;
  background: transparent;
  padding: 40px 32px;
}

.olimpiad-main__info-block .olimpiad-main__info {
  max-width: 760px;
  width: 100%;
  padding-bottom: 24px;
}


.olimpiad-main__info-title {
  font-family: "Gotham ProMedium";
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #1282e9;
  display: block;
  padding-bottom: 24px;
}

.olimpiad-main__info-top-text {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
  display: block;
  padding-bottom: 16px;
}

.olimpiad-main__info-top-text:not(:first-of-type) {
  padding-top: 24px;
}

.olimpiad-main__info-text {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.olimpiad-main__info-link {
  width: 185px;
  height: 48px;
  background: #1282e9;
  border-radius: 30px;
  padding: 15px 24px;
  text-decoration: none;
}

.olimpiad-main__info-block .olimpiad-main__info-link{
  width: 200px;
  text-align: center;
}

.olimpiad-main__info-button-text {
  font-family: "Gotham Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.olimpiad-main__info-subjects {
  display: flex;
  flex-direction: row;
}

.olimpiad-main__info-subjects-column {
  display: flex;
  flex-direction: column;
  line-height: 30px;
  margin-right: 40px;
}

@media (max-width: 1092px) {
  .olimpiad-main__info-block:nth-child(1) {
    background-image: none;
  }
  .olimpiad-main__info-block .olimpiad-main__info {
    max-width: 100%;
  }
}

@media (max-width: 744px) {
  .olimpiad-main__title,
  .olimpiad-main__info-title {
    font-size: 34px;
    line-height: 33px;
  }
}

@media (max-width: 375px) {
  .olimpiad-main__title,
  .olimpiad-main__info-title {
    font-size: 24px;
    line-height: 23px;
  }
}
