.magistracy.teacher.iii .magpage_about_frame{
    background-image: url(../../../../assets/images/New/MagIIITeacher/bg_image.svg);
    background-position: 66px 12px;
}
.magistracy.teacher.iii .info-block-frame2{
    background-image: url(../../../../assets/images/New/MagIIITeacher/info_bg_image.svg);
    background-position: 0px 16px;
    background-repeat: no-repeat;
}   

.magistracy.teacher.iii .info-block-frame3 .info_block-text-row{
    margin-bottom: 0px;
}
