.magistracy.teacher.ae .magpage_about_frame{
    background-image: url(../../../../assets/images/New/MagAETeacher/bg_image.svg);
    background-position: 71px 44px;
    padding: 0px 14px;
  }
  .magistracy.teacher.ae .info-block-frame2{
    background-image: url(../../../../assets/images/New/MagAETeacher/info_bg_image.svg);
    background-position: 0px 46px;
    background-repeat: no-repeat;
  }   
  .magistracy.teacher.ae .info-block-frame3 .info_block-text-row{
    margin-bottom: 0px;
  }
