.educational-program.teacher.met
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.met
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.met .teacher_exams {
  background-image: url(../../../../assets/images/New/METTeacher/bg_image.svg);
  background-position: 71px 43px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 37px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.met .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 845px) {
  .teacher_exams-column {
    margin-right: 0;
  }
  .educational-program.teacher.met .teacher_exams {
    background-image: url(../../../../assets/images/New/METTeacher/bg_image.svg);
    background-position: 100% 0px;
  }
}

@media (max-width: 750px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 90px;
  }
}

@media (max-width: 570px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 120px;
  }
}

@media (max-width: 558px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 120px;
  }
}

@media (max-width: 456px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 180px;
  }
}

@media (max-width: 446px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 230px;
  }
  .educational-program.teacher.met .teacher__info-block-frame-large {
    height: 560px;
  }
}

@media (max-width: 411px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 150px;
  }
  .educational-program.teacher.met .teacher__info-block-frame-large {
    height: 480px;
  }
}

@media (max-width: 389px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 190px;
  }
  .educational-program.teacher.met .teacher__info-block-frame-large {
    height: 520px;
  }
  .educational-program.teacher.met .teacher_exams {
    background-position: 100% 50%;
  }
}

@media (max-width: 357px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 210px;
  }
  .educational-program.teacher.met .teacher__info-block-frame-large {
    height: 540px;
  }
}

@media (max-width: 357px) {
  .educational-program.teacher.met
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 250px;
  }
  .educational-program.teacher.met .teacher__info-block-frame-large {
    height: 580px;
  }
}
