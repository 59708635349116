.step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
  height: 240px;
}

@media (max-width:1094px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 280px;
      }
}

@media (max-width:948px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 340px;
      }
}

@media (max-width:677px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 370px;
      }
}

@media (max-width:578px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 390px;
      }
}

@media (max-width:542px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 440px;
      }
}

@media (max-width:535px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 360px;
      }
}

@media (max-width:462px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 380px;
      }
}

@media (max-width:457px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 480px;
      }
      .etap-postupleniya__content-col .progress__item {
        text-align: center;
        width: 100%;
      }
}

@media (max-width:383px){
    .step-prospective-student.magistratura .etap-postupleniya__col:nth-child(1) {
        height: 540px;
      }
}
