@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.preparatory-courses .step-prospective-student__top {
  margin-bottom: 40px;
}

.preparatory-courses__info-block {
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
}

.preparatory-courses__main {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
}

.preparatory-courses__main:last-child {
  margin-bottom: 80px;
}

.preparatory-courses__text-block,
.preparatory-courses__main-block {
  display: flex;
  flex-direction: column;
}

.preparatory-courses__info-block
  .preparatory-courses__text-block:first-of-type {
  background: #ededed;
  border-radius: 20px;
  padding: 40px;
  margin-right: 65px;
  max-width: 665px;
  width: 100%;
}

.preparatory-courses__text-block-title {
  display: block;
  font-family: "Gotham ProMedium";
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #1282e9;
  margin-bottom: 24px;
}

.preparatory-courses__text-block-subtitle {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #282828;
  display: block;
  margin-bottom: 24px;
  max-width: 261px;
  width: 100%;
}

.preparatory-courses__info-block
  .preparatory-courses__text-block:last-of-type
  .preparatory-courses__text-block-text {
  display: block;
  margin-bottom: 40px;
}

.preparatory-courses__text-block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.preparatory-courses__text-block-text-blue {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1282e9;
}

.preparatory-courses__main-block-title {
  background: #ededed;
  border-radius: 20px;
  padding: 32px;

  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.01em;
  color: #1282e9;
}

.preparatory-courses__main-block {
  background: rgb(237, 237, 237, 0.4);
  border-radius: 20px;
  width: 100%;
  margin-right: 30px;
}

.preparatory-courses__main-blocktext {
  padding: 32px;
}

.preparatory-courses__main-blocktext .preparatory-courses__main-block-text {
  display: block;
  margin-bottom: 15px;
}

.preparatory-courses__main-block-subtitle {
  font-family: "Gotham ProMedium";
  font-weight: 700;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #1282e9;

  display: block;
  margin-bottom: 8px;
}

.preparatory-courses__main-blocktext
  .preparatory-courses__main-block-text:nth-child(3) {
  max-width: 255px;
  width: 100%;
  display: block;
  margin-bottom: 40px;
}

.preparatory-courses__main-blocktext .preparatory-courses__text-block-subtitle {
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
  margin-bottom: 8px;
}

.preparatory-courses__main-block-button {
  margin: 0 0 48px 32px;
  display: block;
  width: 194px;
  height: 48px;
  background: #1282e9;
  border-radius: 30px;
  padding: 15px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 1042px) {
  .preparatory-courses__info-block {
    flex-direction: column;
    margin-bottom: 40px;
  }
  .preparatory-courses__info-block
    .preparatory-courses__text-block:first-of-type {
    margin-right: 0;
    max-width: 100%;
  }
  .preparatory-courses__info-block
    .preparatory-courses__text-block:nth-child(2) {
    display: none;
  }
  .preparatory-courses__main .preparatory-courses__main-block:last-child {
    margin-right: 0;
  }
}

@media (max-width: 875px) {
  .preparatory-courses__main {
    flex-direction: column;
  }
  .preparatory-courses__main-block {
    margin-right: 0px;
    margin-bottom: 30px;
  }
}

@media (max-width: 744px) {
  .preparatory-courses__info-block
    .step-prospective-student__top:first-of-type
    .etap-postupleniya__title,
  .preparatory-courses__text-block-title {
    font-size: 34px;
    line-height: 34px;
  }
}

@media (max-width: 400px) {
  .preparatory-courses__info-block
    .step-prospective-student__top:first-of-type
    .etap-postupleniya__title,
  .preparatory-courses__text-block-title {
    font-size: 24px;
    line-height: 24px;
  }
}
