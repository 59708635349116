.educational-program.teacher.iis
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -25px;
  margin-top: -35px;
}

.educational-program.teacher.iis
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: 5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.iis .teacher_exams {
  background-image: url(../../../../assets/images/New/IISTeacher/bg_image.svg);
  background-position: 80px 50px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 45px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.iis .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 855px) {
  .frame-large-text-row .info_block-text-row {
    width: 100%;
  }
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 405px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 80px;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.iis .teacher_exams {
    background-image: url(../../../../assets/images/New/IISTeacher/bg_image.svg);
    background-position: 100% 50%;
  }
  .educational-program.teacher.iis .teacher__main-block-img {
    margin-right: 56px;
  }
}

@media (max-width: 835px) {
  .educational-program.teacher.iis .teacher__main-block-img {
    margin-right: 5px;
  }
}

@media (max-width: 633px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 445px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 120px;
  }
}

@media (max-width: 562px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 485px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 160px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
      opacity: 0;
  }
}

@media (max-width: 479px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 525px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 200px;
  }
}

@media (max-width: 456px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 585px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 260px;
  }
}

@media (max-width: 437px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 645px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 320px;
  }
}

@media (max-width: 411px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 545px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 220px;
  }
}

@media (max-width: 383px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 585px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 260px;
  }
}

@media (max-width: 349px) {
  .educational-program.teacher.iis .teacher__info-block-frame-large {
    height: 645px;
  }
  .educational-program.teacher.iis
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 320px;
  }
}
