@font-face {
  font-family: "Gotham Pro";
  src: url(/src/assets/fonts/GothamPro/gothampro.ttf);
}

@font-face {
  font-family: "Gotham ProMedium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}

.excursion-page .step-prospective-student__top {
  margin-bottom: 56px;
}

.excursion-page__info-block:last-child {
  margin: 0 0 56px 0;
}

.excursion-page__info-block {
  display: flex;
  flex-direction: row;
  height: 168px;
  justify-content: space-between;
}

.excursion-page__info-block.one {
  background: rgb(237, 237, 237, 0.4);
  border-radius: 20px;
}

.excursion-page__info-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  max-width: 400px;
  width: 100%;
}

.excursion-page__info-text {
  font-family: "Gotham ProMedium";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #3e3e3e;
}

.excursion-page__info-link {
  background: #1282e9;
  border-radius: 30px;
  display: block;
  padding: 11px 26px;
  font-family: "Gotham Pro";
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.02em;
  color: #ffffff;

  text-decoration: none;
  max-width: 123px;
  width: 100%;
}
