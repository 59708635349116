.educational-program__main-block.economy
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__subtitle,
  
.educational-program__main-block.economy
  .individual-achivments__text-block:nth-child(3)
  .individual-achivments__text,

.educational-program__main-block.economy
  .individual-achivments__text-block:nth-child(3) {
  max-width: 425px;
  width: 100%;
}

.educational-program__main-block.economy
  .individual-achivments__text-block:nth-child(1)
  .individual-achivments__subtitle,
  
.educational-program__main-block.economy
  .individual-achivments__text-block:nth-child(1)
  .individual-achivments__text,

.educational-program__main-block.economy
  .individual-achivments__text-block:nth-child(1) {
  max-width: 260px;
  width: 100%;
  }