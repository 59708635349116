.educational-program.teacher.rza
  .teacher__info-block-frame-large
  .wrap
  img:first-child {
  margin-left: -28px;
  margin-top: -37px;
  border-radius: 20px 0 0 0;
}

.educational-program.teacher.rza
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -28px;
  margin-top: -5px;
  right: 0;
  border-radius: 0 0 20px 0;
}

.educational-program.teacher.rza .teacher_exams {
  background-image: url(../../../../assets/images/New/RZATeacher/bg_image.svg);
  background-position: 71px 44px;
}

@media (max-width: 1055px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large .wrap {
    height: 0;
  }
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 327px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.rza .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.rza .teacher_exams {
    background-image: url(../../../../assets/images/New/ESTeacher/bg_image.svg);
    background-position: 100% 50%;
  }
  .educational-program.teacher.rza .teacher__main-block-img {
    margin-right: 56px;
  }
}

@media (max-width: 835px) {
  .educational-program.teacher.rza .teacher__main-block-img {
    margin-right: 5px;
  }
}

@media (max-width: 775px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 380px;
  }
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 45px;
  }
}

@media (max-width: 580px) {
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
}

@media (max-width: 472px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 420px;
  }
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 85px;
  }
}

@media (max-width: 456px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 470px;
  }
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 135px;
  }
}

@media (max-width: 438px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 520px;
  }
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 185px;
  }
}

@media (max-width: 411px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 420px;
  }
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 85px;
  }
}


@media (max-width: 391px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 450px;
  }
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 115px;
  }
}

@media (max-width: 357px) {
  .educational-program.teacher.rza .teacher__info-block-frame-large {
    height: 470px;
  }
  .educational-program.teacher.rza
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 135px;
  }
}