@font-face {
  font-family: "Gotham Pro Medium";
  src: url(/src/assets/fonts/GothamPro/gothampro_medium.ttf);
}
.container.last-change {
  padding: 0;
}

.slider-news-wrapper {
  border-radius: 30px;
  padding: 20px 30px;
  height: 366px;
}

.slider-news-wrapper.one {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

.slider-news-wrapper__title {
  font-family: "Gotham Pro Medium";
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #282828;

  letter-spacing: 0.02em;
}

.slider-news-wrapper.one .slick-prev {
  margin: -53% 0 0 40%;
  transform: rotate(225deg);
  border-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.slider-news-wrapper.one .slick-next {
  margin: 45% 40% 0 0;
  transform: rotate(45deg);
  border-color: rgba(0, 0, 0, 0.6);
  z-index: 13;
}

.slider-news-wrapper .slick-prev:before,
.slider-news-wrapper .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #7d7d7d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  letter-spacing: 0.02em;
}

.slider-news-wrapper .slick-dots li.slick-active button:before {
  color: #7d7d7d;
}

.slider-news-wrapper .slick-slider,
.slider-news-wrapper .slick-list,
.slider-news-wrapper .slick-track {
  height: 298px !important;
}

.slider-news-wrapper .slick-initialized .slick-slide {
  height: 140px;
  /* margin-bottom: 20px; */
}

.slider-news-wrapper.one .slick-dots {
  display: none !important;
}

.slider-news-wrapper .slick-slider {
  margin-top: 50px;
}

.slider-news-wrapper .slick-slider .slider-news-wrapper .slick-track,
.slider-news-wrapper .slick-initialized .slick-slide,
.slider-news-wrapper .slick-slide img {
  display: flex;
  justify-content: center;
}

.slider-news-wrapper.one .slick-slide.slick-active.slick-current {
  margin-bottom: 20px;
}

.slider-news-wrapper__block-text {
  display: flex !important;
  flex-direction: column;
  height: 140px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  padding: 20px 30px;
  width: 340px !important;
  justify-content: space-between;
}

.slider-news-wrapper__block-subtitle {
  font-size: 12px;
  line-height: 12px;
  padding-bottom: 5px;

  letter-spacing: 0.02em;
}

.slider-news-wrapper__block-title {
  font-size: 18px;
  line-height: 18px;
  padding-bottom: 5px;
  font-family: "Gotham Pro Medium";

  letter-spacing: 0.02em;
}

.slider-news-wrapper__block-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  width: 130px;
  height: 30px;
  padding: 10px;
  background: #1282e9;
  border-radius: 10px;

  align-items: center;
  justify-content: center;
}

.slider-news-wrapper .slick-slide img {
  border-radius: 0;
}

img.slider-news-wrapper__link-img {
  height: 13px;
  width: 10px;
  margin-right: 12px;
}

.slider-news-wrapper__text {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;

  letter-spacing: 0.02em;
}

@media (max-width: 1095px) {
  .slider-news-wrapper {
    padding: 48px;
  }
}

@media (max-width: 1040px) {
  .slider-news-wrapper {
    padding: 48px 24px;
    border-radius: 0;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-prev {
    margin: -95px 0 0 20%;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-next {
    margin: -95px 20% 0 0;
  }
  /* .slider-news-wrapper.one {
    display: none;
  } */
  .slider-news-wrapper__title {
    margin-bottom: 0;
    text-align: center;
  }
}

@media (max-width: 1025px) {
  .news-main-page__news-img,
  .news-main-page__news-img img,
  .news-main-page__news-block-text,
  .news-main-page__news-block {
    width: 386px;
  }
  .news-main-page__news-block {
    max-width: 386px;
    width: 100%;
  }
  .news-main-page__news-block:last-child {
    display: none;
  }
}

@media (max-width: 925px) {
  .slider-news-wrapper__block-text {
    width: 375px !important;
  }
}

@media (max-width: 895px) {
  .slider-news-wrapper__block-text {
    width: 336px !important;
  }
  .slider-news-wrapper__block-title {
    font-size: 16px;
    line-height: 24px;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-prev {
    margin: -95px 0 0 10%;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-next {
    margin: -95px 10% 0 0;
  }
}

@media (max-width: 744px) {
  .slider-news-wrapper__title {
    font-size: 34px;
    line-height: 32px;
  }
  .slider-news-wrapper__block-text {
    width: 305px !important;
  }
  .slider-news-wrapper__block-title {
    font-size: 14px;
    line-height: 20px;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-prev {
    margin: -90px 0 0 10%;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-next {
    margin: -90px 10% 0 0;
  }
}

@media (max-width: 680px) {
  .slider-news-wrapper__block-text {
    width: 443px !important;
    justify-content: space-between;
  }
}

@media (max-width: 630px) {
  .slider-news-wrapper__title {
    max-width: 300px;
    width: 100%;
    margin: 0 0 0 20%;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-prev {
    margin: -110px 0 0 10%;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-next {
    margin: -110px 10% 0 0;
  }
}

@media (max-width: 500px) {
  .slider-news-wrapper__block-text {
    width: 100% !important;
  }
  .slider-news-wrapper__title {
    font-size: 24px;
    line-height: 23px;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-prev {
    margin: -100px 0 0 10%;
  }
  .slider-news-wrapper.two button.slick-arrow.slick-next {
    margin: -100px 10% 0 0;
  }
  .slider-news-wrapper__title {
    margin: 0;
    max-width: 160px;
  }
  .slider-news-wrapper.two {
    align-items: center;
  }
}
