.educational-program.teacher.pi
  .teacher__info-block-frame-large
  .wrap
  img:last-child {
  margin-right: -25px;
  margin-top: 10px;
  right: 0;
}

.educational-program.teacher.pi .teacher_exams {
  background-image: url(../../../../assets/images/New/PITeacher/bg_image.svg);
  background-position: 80px 20px;
}

@media (max-width: 1175px) {
  .educational-program.teacher.pi .frame-large-text-row .info_block-text-row {
    max-width: 320px;
  }
  .educational-program.teacher.pi
    .frame-large-text-row
    .info_block-text-row.last {
    max-width: 600px;
  }
}

@media (max-width: 1095px) {
  .educational-program.teacher.pi .frame-large-text-row .info_block-text-row {
    max-width: 300px;
  }
}

@media (max-width: 1055px) {
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 47px;
  }
}

@media (max-width: 1005px) {
  .educational-program.teacher.pi .teacher_exams {
    background-image: none;
  }
}

@media (max-width: 845px) {
  .educational-program.teacher.pi .teacher_exams {
    background-image: url(../../../../assets/images/New/PITeacher/bg_image.svg);
    background-position: right;
  }
}

@media (max-width: 750px) {
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 101px;
  }
}

@media (max-width: 716px) {
  .educational-program.teacher.pi .teacher__info-block-frame-large {
    height: 480px;
  }
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 157px;
  }
}

@media (max-width: 570px) {
  .educational-program.teacher.pi .teacher__info-block-frame-large {
    height: 450px;
  }
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 125px;
  }
}

@media (max-width: 475px) {
  .educational-program.teacher.pi .teacher__info-block-frame-large {
    height: 500px;
  }
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 175px;
  }
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:first-child {
    opacity: 0;
  }
}

@media (max-width: 456px) {
  .educational-program.teacher.pi .teacher__info-block-frame-large {
    height: 540px;
  }
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 215px;
  }
}

@media (max-width: 343px) {
  .educational-program.teacher.pi .teacher__info-block-frame-large {
    height: 600px;
  }
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 275px;
  }
}

@media (max-width: 330px) {
  .educational-program.teacher.pi .teacher__info-block-frame-large {
    height: 620px;
  }
  .educational-program.teacher.pi
    .teacher__info-block-frame-large
    .wrap
    img:last-child {
    margin-top: 295px;
  }
}