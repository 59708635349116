.magistracy.teacher.intellec .magpage_about_frame{
    background-image: url(../../../../assets/images/New/MagIntellecTeacher/bg_image.svg);
    background-position: 71px 44px;
    padding: 70px 14px 75px 14px;
  }
.magistracy.teacher.intellec .info-block-frame2{
    background-image: url(../../../../assets/images/New/MagIntellecTeacher/info_bg_image.svg);
    background-position: 0px 42px;
    background-repeat: no-repeat;
}   
.magistracy.teacher.intellec .info-block-frame3 .info_block-text-row{
    margin-bottom: 0px;
}
